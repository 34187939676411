import { Box, Button, Container, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getDepartmentList } from "shared/network/department.api";
import { Department } from "shared/types";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const RelDepartmentEmployeeForm = ({ setOpen }: Props) => {
  const { t } = useTranslation();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const departmentListQuery = useQuery(
    ["tenantDepartmentList", tenant.id],
    async () => {
      const { data } = await getDepartmentList(0, 1000000, tenant.id);
      return data.page.content;
    }
  );

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="department"
            defaultValue={null}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => field.onChange(value)}
                options={departmentListQuery.data || []}
                getOptionLabel={(option: Department) => option.name}
                getOptionSelected={(option) => option.id === field.value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("department.relDepartmentEmployee.department")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        <Button color="primary" variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary">
          {t("common:button.save")}
        </Button>
      </Box>
    </Container>
  );
};

export default RelDepartmentEmployeeForm;
