import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { IncomingInvoice } from "shared/types";

export type IncomingInvoiceRequest = {
  id?: string;
  tenantId: number;
  paymentType: string;
  isPaid: boolean; //+
  completionDate: string; //+
  paymentDeadline: string;
  serialNumber: string; //+
  supplierCompanyName: string;
  supplierCompanyZip: string;
  supplierCompanyCity: string;
  supplierCompanyAddress: string;
  supplierTaxNumber: string;
  customerId: number | string;
  customerName: string;
  customerZip: string;
  customerCity: string;
  customerAddress: string;
  customerTaxNumber: string;
  supplierAccountNumber: string;
  creationDate: string; //+
  comment: string; //+
  customerEuTaxNumber: string;
  supplierEuTaxNumber: string;
  paidDate: string; //+
  paySource: string; //+
  netPrice: string | number;
  vatPrice: string | number;
  grossPrice: string | number;
  currencyId: string | number;
};

const ENDPOINT = "/incoming-invoice";

export const listIncomingInvoice = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<IncomingInvoice>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //INCOMING_INVOICE_ADMIN

export const createIncomingInvoice = (
  tenantId: number,
  param: IncomingInvoiceRequest,
) => axios.post(`${ENDPOINT}/create`, { tenantId, param }); //INCOMING_INVOICE_ADMIN

export const modifyIncomingInvoice = (
  tenantId: number,
  param: IncomingInvoiceRequest,
) => axios.put(`${ENDPOINT}/modify`, { tenantId, param }); //INCOMING_INVOICE_ADMIN

export const getIncomingInvoiceById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<IncomingInvoice>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //INCOMING_INVOICE_ADMIN
