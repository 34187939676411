import axios from "config/axios";
import { GenericListResponse } from "shared/common";

const ENDPOINT = "/permission";

export const permissionList = (tenantId: number) =>
  axios.post<GenericListResponse<any>>(`${ENDPOINT}/list`, {
    tenantId,
  }); //PERMISSION_ADMIN

export const permissionListByGroup = (param: number, tenantId: number) =>
  axios.post<GenericListResponse<any>>(`${ENDPOINT}/list-by-group`, {
    param,
    tenantId,
  }); //PERMISSION_ADMIN
