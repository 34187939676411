import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Check, Close, Edit } from "@material-ui/icons";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  createPackaging,
  listPackaging,
  modifyPackaging,
} from "shared/network/packaging.api";
import { Packaging } from "shared/types";

const useStyles = makeStyles({
  row: {},
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const PackagingList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openId, setOpenId] = useState<number | null>(null);
  const [selectedPackaging, setSelectedPackaging] = useState<Packaging | null>(
    null,
  );

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const packagingListQuery = useQuery("packagingQueryForRel", async () => {
    const { data } = await listPackaging(0, 1000000, tenant.id);
    return data;
  });

  const form = useForm<Packaging>();

  useEffect(() => {
    form.setValue("name", selectedPackaging?.name || "");
  }, [form, selectedPackaging?.name]);

  const onSubmitCreate = async (values: Packaging) => {
    try {
      await createPackaging(values, tenant.id);
      packagingListQuery.refetch();
      setOpenId(null);
      form.setValue("name", "");
      enqueueSnackbar(t("common:notification.packaging.success"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("common:notification.packaging.failure"), {
        variant: "error",
      });
    }
  };

  const onSubmitModify = async (values: Packaging) => {
    try {
      await modifyPackaging(values, tenant.id);
      packagingListQuery.refetch();
      setSelectedPackaging(null);
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("packaging.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("packaging.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Card variant="outlined">
        <CardContent>
          <Collapse in={openId !== 0}>
            <Box display="flex" justifyContent="center" width="100%" pb={2}>
              <Button
                startIcon={<Add />}
                onClick={() =>
                  setOpenId(prevState => (prevState === null ? 0 : null))
                }
              >
                {t("packaging.create")}
              </Button>
            </Box>
          </Collapse>
          <Collapse in={openId === 0}>
            <form onSubmit={form.handleSubmit(onSubmitCreate)}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ padding: "0 0 16px 0", width: "100%" }}
              >
                <Grid item xs={6}>
                  <Controller
                    control={form.control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("packaging.name")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box display="flex">
                                <Tooltip
                                  title={t("common:button.create").toString()}
                                >
                                  <IconButton
                                    type="submit"
                                    size="small"
                                    style={{ margin: "0px 4px" }}
                                  >
                                    <Check />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={t("common:button.cancel").toString()}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setOpenId(null);
                                      form.setValue("name", "");
                                    }}
                                    style={{ margin: "0px 4px" }}
                                  >
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </Collapse>
          <Divider />
          {packagingListQuery.data?.page.content.map(packaging => (
            <form
              key={packaging.id}
              onSubmit={form.handleSubmit(onSubmitModify)}
            >
              <Grid
                container
                className={classes.row}
                style={{ alignItems: "center" }}
              >
                <Grid item xs={6} className={classes.cell}>
                  {selectedPackaging?.id !== packaging?.id ? (
                    <Typography className={classes.text}>
                      {packaging.name}
                    </Typography>
                  ) : (
                    <>
                      <input
                        type="hidden"
                        value={packaging.id}
                        {...form.register("id")}
                      />
                      <Controller
                        control={form.control}
                        name="name"
                        defaultValue=""
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Név"
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="flex-end"
                  className={classes.cell}
                >
                  {selectedPackaging?.id !== packaging?.id ? (
                    <Tooltip
                      title={t("permissionGroup.buttons.modify").toString()}
                    >
                      <IconButton
                        size="small"
                        color="primary"
                        style={{ margin: "0 8px" }}
                        onClick={() => {
                          form.setValue("id", packaging.id);
                          setSelectedPackaging(packaging);
                        }}
                      >
                        <Edit color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip
                        title={t("permissionGroup.buttons.modify").toString()}
                      >
                        <IconButton
                          onClick={form.handleSubmit(onSubmitModify)}
                          size="small"
                          color="primary"
                          style={{ margin: "0 8px" }}
                        >
                          <Check color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={t("permissionGroup.buttons.cancel").toString()}
                      >
                        <IconButton
                          onClick={() => setSelectedPackaging(null)}
                          size="small"
                          color="primary"
                          style={{ margin: "0 8px" }}
                        >
                          <Close color="primary" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </form>
          ))}
        </CardContent>
      </Card>
    </Container>
  );
};

export default PackagingList;
