import { Box, Button, Container } from "@material-ui/core";
import { AddBox, Remove } from "@material-ui/icons";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCargoForVehicle } from "shared/network/cargo.api";
import { ItemPriceHistory } from "shared/types";
import CargoRemove from "./CargoRemove";

const VehicleItems = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();
  const [page, setPage] = useState(0);
  const query = new URLSearchParams(location.search);
  const vehicleId = query.get("id");

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const [open, setOpen] = useState(false);

  const itemsOnVehicleQuery = useQuery(
    ["itemsOnVehicleList", selectedRelTenant.tenant.id],
    async () => {
      if (vehicleId) {
        const { data } = await listCargoForVehicle(
          vehicleId,
          selectedRelTenant.tenant.id,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("item.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name || "-",
    },
    {
      field: "item.productCode",
      headerName: t("item.productCode"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.item.productCode || "-",
    },
    {
      field: "item.type",
      headerName: t("item.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.item.type ? t(`item.types.${row.item.type}`) : "-",
    },
    {
      field: "item.price",
      headerName: t("item.price"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.item.itemPriceHistories.find(
          (item: ItemPriceHistory) => item.isActive === true,
        )?.price +
          " " +
          (row.item &&
            row.item.sellPriceCurrency &&
            row.item.sellPriceCurrency.name) || "-",
    },
    {
      field: "quantity",
      headerName: t("warehouse.formValues.quantity"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.quantity || "-",
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_ADMIN",
        ]) && (
          <Button
            component={Link}
            variant="contained"
            style={{ margin: "auto" }}
            to={`/cargo-create/vehicle/${vehicleId}`}
            startIcon={<AddBox />}
          >
            {t("cargo.create")}
          </Button>
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_ADMIN",
        ]) && (
          <Button
            variant="contained"
            style={{ margin: "auto" }}
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<Remove />}
          >
            {t("cargo.modify")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );

    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={itemsOnVehicleQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
      <CargoRemove
        vehicleId={vehicleId}
        cargo={itemsOnVehicleQuery.data?.items}
        open={open}
        setOpen={setOpen}
        refetch={itemsOnVehicleQuery.refetch}
      />
    </Container>
  );
};

export default VehicleItems;
