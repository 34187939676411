import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getDeliveryRoundList } from "shared/network/delivery-round.api";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const DeliveryRoundList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const history = useHistory();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-delivery-round-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-delivery-round-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const deliveryRoundListQuery = useQuery(
    [
      "deliveryRoundListQuery",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      account?.user?.isSuperAdmin,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await getDeliveryRoundList(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("deliveryRound.name"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
            "DELIVERY_ROUND_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryRound.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/deliverypoint-list?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
            "DELIVERY_ROUND_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryRound.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/deliveryround-modify/${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_ROUND_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/deliveryround-create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("deliveryRound.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/deliverypoint-list?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="delivery-round"
      filterable={["name"]}
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={deliveryRoundListQuery}
      onCellClick={onCellClick}
    />
  );
};

export default DeliveryRoundList;
