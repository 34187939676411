import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { DeliveryRound } from "shared/types";

const ENDPOINT = "/delivery-round";

export const getDeliveryRoundById = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param, tenantId }); //DELIVERY_ROUND_GET

export const createDeliveryRound = (param: DeliveryRound, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_ROUND_ADMIN

export const modifyDeliveryRound = (param: DeliveryRound, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_ROUND_ADMIN*/

export const getDeliveryRoundList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<DeliveryRound>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //DELIVERY_ROUND_GET
