import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { getTenantById } from "shared/network/tenant.api";
import BankAccountListPage from "views/Company/CompanyDetailsTabs/BankAccount/BankAccountListPage";
import TenantDetails from "./TenantDetails";
import TenantSites from "./TenantSites";

export const TENANT_DETAILS_TABS = [
  `/tenant/details`,
  `/tenant/bank-accounts`,
  `/tenant/departments`,
];

const TenantDetailsTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { setHeaderName } = useHeader();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const from = query.get("from");

  const { account } = useSelector((state: RootState) => state.authentication);

  const getTenantByIdQuery = useQuery(["getTenantByIdQuery", id], async () => {
    if (id) {
      const { data } = await getTenantById(Number.parseInt(id));
      return data.item;
    }
    return Promise.reject();
  });

  useEffect(() => {
    if (getTenantByIdQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (getTenantByIdQuery.data?.name) {
      setHeaderName(
        `${getTenantByIdQuery.data.name} ${t("common:details.details")}`
      );
    }
    return () => {
      setHeaderName(null);
    };
  }, [
    getTenantByIdQuery.data?.name,
    getTenantByIdQuery.isFetching,
    setHeaderName,
    t,
  ]);

  return (
    <Container maxWidth="xl">
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("tenant.tabs.details")}
            component={Link}
            to={`${TENANT_DETAILS_TABS[0]}?id=${id}`}
            value={TENANT_DETAILS_TABS[0]}
          />
        )}
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("tenant.tabs.accountNumber")}
            component={Link}
            to={`${TENANT_DETAILS_TABS[1]}?id=${id}&from=${from}`}
            value={TENANT_DETAILS_TABS[1]}
          />
        )}
        {account?.user?.isSuperAdmin && (
          <Tab
            label={t("tenant.tabs.department")}
            component={Link}
            to={`${TENANT_DETAILS_TABS[2]}?id=${id}`}
            value={TENANT_DETAILS_TABS[2]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          {account?.user?.isSuperAdmin && (
            <PrivateRoute
              path={TENANT_DETAILS_TABS[0]}
              component={() => (
                <TenantDetails
                  tenant={getTenantByIdQuery.data || null}
                  loading={getTenantByIdQuery.isFetching}
                  refetch={getTenantByIdQuery.refetch}
                />
              )}
            />
          )}
          {account?.user?.isSuperAdmin && (
            <PrivateRoute
              path={TENANT_DETAILS_TABS[1]}
              component={BankAccountListPage}
            />
          )}
          {account?.user?.isSuperAdmin && (
            <PrivateRoute
              path={TENANT_DETAILS_TABS[2]}
              component={TenantSites}
            />
          )}
        </Switch>
      </Box>
    </Container>
  );
};
export default TenantDetailsTabs;
