import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { Department } from "shared/types";

const ENDPOINT = "/department";

export const modifyDepartment = (param: Department, tenantId: number) =>
  axios.put(ENDPOINT, {
    param,
    tenantId,
  }); //DEPARTRMENT_ADMIN

export const createDepartment = (param: Department, tenantId: number) =>
  axios.post(ENDPOINT, {
    entry: param,
    isWarehouse: param.isWarehouse,
    tenantId,
  }); //DEPARTRMENT_ADMIN

export const deleteDepartment = (param: number, tenantId: number) =>
  axios.delete(ENDPOINT, { data: { param, tenantId } }); //DEPARTRMENT_ADMIN

export const getDepartmentList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.post<GenericPageResponse<Department>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //DEPARTRMENT_GET

export const getDepartmentById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Department>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //DEPARTRMENT_GET

export const listDepartmentByEmployee = (param: number, tenantId: number) =>
  axios.post<GenericListResponse<Department>>(`${ENDPOINT}/list-by-employee`, {
    param,
    tenantId,
  }); //DEPARTRMENT_GET
