import { Box, IconButton, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ALL_DELIVERY_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { updateDeliveryStatus } from "shared/network/delivery.api";
import { Delivery, DeliveryStatus, Tenant } from "shared/types";
import DeliveryStatusDialog from "views/Delivery/DeliveryStatusDialog";

type Props = {
  delivery: Delivery;
  refetch: () => void;
  tenant: Tenant;
};

const AllDeliveryStatusCell = ({ delivery, refetch, tenant }: Props) => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const [status, setStatus] = useState<DeliveryStatus | null>(null);
  const [statusDelivery, setStatusDelivery] = useState<Delivery | null>(null);

  useEffect(() => {
    setStatus(delivery.status);
  }, [delivery.status]);

  useEffect(() => {
    if (!statusDelivery) {
      setStatus(delivery.status);
    }
  }, [statusDelivery, setStatus, delivery.status]);

  return (
    <>
      <DeliveryStatusDialog
        selectedDelivery={statusDelivery}
        setSelectedDelivery={setStatusDelivery}
        refetch={refetch}
        selectedStatus={status || "ACTIVE"}
        fromStatusCell
      />
      <Box display="flex" justifyContent="center" width="100%">
        <Box>
          <Tooltip title={t("delivery.progress.previous").toString()}>
            <Box>
              <IconButton
                size="small"
                color="primary"
                disabled={
                  !hasAuthority(
                    account.user,
                    account.permissions,
                    selectedRelTenant,
                    ["TENANT_ADMIN"]
                  )
                    ? [
                        "DELIVERED",
                        "BILLED",
                        "ACTIVE",
                        "WITHDRAWN",
                        "SUSPENDED",
                      ].includes(
                        status ? status.toString() : delivery.status?.toString()
                      ) ||
                      status ===
                        ALL_DELIVERY_STATUS[
                          ALL_DELIVERY_STATUS.indexOf(
                            delivery?.status as DeliveryStatus
                          ) - 1
                        ]
                    : ["ACTIVE", "WITHDRAWN", "SUSPENDED"].includes(
                        status ? status.toString() : delivery.status?.toString()
                      )
                }
                onClick={() => {
                  if (
                    delivery?.status &&
                    status &&
                    status !== ALL_DELIVERY_STATUS[0]
                  ) {
                    setStatus(
                      ALL_DELIVERY_STATUS[
                        ALL_DELIVERY_STATUS.indexOf(status) - 1
                      ]
                    );
                  }
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
        <Box
          style={{ width: 100, minWidth: 100, maxWidth: 100, padding: 0 }}
          justifyContent="center"
          textAlign="center"
        >
          {t(`delivery.status.${status}`)}
        </Box>
        <Box>
          <Tooltip title={t("delivery.progress.next").toString()}>
            <IconButton
              size="small"
              color="primary"
              disabled={
                !hasAuthority(
                  account.user,
                  account.permissions,
                  selectedRelTenant,
                  ["TENANT_ADMIN"]
                )
                  ? ["DELIVERED", "BILLED", "WITHDRAWN", "SUSPENDED"].includes(
                      status ? status.toString() : delivery.status?.toString()
                    ) ||
                    status ===
                      ALL_DELIVERY_STATUS[
                        ALL_DELIVERY_STATUS.indexOf(
                          delivery?.status as DeliveryStatus
                        ) + 1
                      ]
                  : ["BILLED", "WITHDRAWN", "SUSPENDED"].includes(
                      status ? status.toString() : delivery.status?.toString()
                    )
              }
              onClick={() => {
                if (
                  delivery?.status &&
                  delivery?.status !==
                    ALL_DELIVERY_STATUS[ALL_DELIVERY_STATUS.length - 1] &&
                  status
                ) {
                  setStatus(
                    ALL_DELIVERY_STATUS[ALL_DELIVERY_STATUS.indexOf(status) + 1]
                  );
                }
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box style={{ marginLeft: 10, width: 30 }}>
          {status !== delivery?.status && (
            <Tooltip title={t("delivery.progress.accept").toString()}>
              <IconButton
                size="small"
                style={{
                  color: COLORS.green,
                  backgroundColor: COLORS.mintGreenLight,
                  borderRadius: 8,
                }}
                onClick={async () => {
                  if (status) {
                    try {
                      if (status === "DELIVERED") {
                        setStatusDelivery(delivery);
                      } else if (status === "IN_CARGO") {
                        setStatusDelivery(delivery);
                      } else {
                        await updateDeliveryStatus(
                          status,
                          delivery?.id.toString(),
                          tenant.id.toString()
                        );
                      }
                      refetch();
                    } catch {}
                  }
                }}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  );
};
export default AllDeliveryStatusCell;
