import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import ItemCategories from "./ItemCategories/ItemCategories";
import ItemList from "./ItemList/ItemList";
import SerialItemList from "./SerialItems/SerialItemList";

export const ITEM_TABS = [
  "/items/list",
  "/items/categories",
  `/items/serial-item-list`,
];

const ItemsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Container maxWidth="xl">
      {location.pathname === "/items" && <Redirect to="/items/list" />}
      {(selectedRelTenant?.isTenantAdmin ||
        hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "CATEGORY_ADMIN",
        ])) && (
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          value={location.pathname}
          indicatorColor="primary"
          textColor="primary"
        >
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ITEM_GET",
            "ITEM_ADMIN",
          ]) && (
            <Tab
              label={t("item.title")}
              component={Link}
              to={ITEM_TABS[0]}
              value={ITEM_TABS[0]}
            />
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "CATEGORY_ADMIN",
          ]) && (
            <Tab
              label={t("item.categories")}
              component={Link}
              to={ITEM_TABS[1]}
              value={ITEM_TABS[1]}
            />
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ITEM_GET",
            "ITEM_ADMIN",
          ]) && (
            <Tab
              label={t("drawer.serial-item-list")}
              component={Link}
              to={`${ITEM_TABS[2]}`}
              value={ITEM_TABS[2]}
            />
          )}
        </Tabs>
      )}
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={ITEM_TABS[0]}
            component={ItemList}
            permissions={["ITEM_GET", "ITEM_ADMIN"]}
          />
          <PrivateRoute
            path={ITEM_TABS[1]}
            component={ItemCategories}
            permissions={["CATEGORY_ADMIN"]}
          />
          <PrivateRoute
            path={ITEM_TABS[2]}
            component={SerialItemList}
            permissions={["ITEM_GET", "ITEM_ADMIN"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default ItemsTabs;
