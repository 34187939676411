import { Grid, Typography, makeStyles } from "@material-ui/core";
import { AuditLog } from "shared/types";
import { COLORS } from "config/theme";

type Props = {
  log: AuditLog;
  array: AuditLog[];
  index: number;
};

const useStyles = makeStyles({
  userListTitle: {
    color: "secondary",
    fontSize: "16px",
    fontWeight: "bold",
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const AuditLogRow = ({ log, array, index }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.cell}>
          <Typography className={classes.text}>{log.userId}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.cell}>
          <Typography
            style={{
              wordBreak: "break-word",
            }}
            className={classes.text}
          >
            {log.message}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.cell}>
          <Typography
            style={{
              wordBreak: "break-word",
            }}
            className={classes.text}
          >
            {log.type}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.cell}>
          <Typography
            style={{
              wordBreak: "break-word",
            }}
            className={classes.text}
          >
            {log.status}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AuditLogRow;
