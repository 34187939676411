import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCompanyPageable } from "shared/network/company.api";
import displayAddress from "shared/util/displayAddress";

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const CompanyList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isCustomer = query.get("isCustomer");
  const isSupplier = query.get("isSupplier");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-company-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-company-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const companyListQuery = useQuery(
    [
      "companyList",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      account?.user?.isSuperAdmin,
      filterValue,
      sortState,
      isCustomer,
      isSupplier,
    ],
    async () => {
      const { data } = await listCompanyPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (isCustomer ? "isCustomer:true" : "") +
          (isSupplier ? "isSupplier:true" : "") +
          filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("company.formValues.name"),
      flex: 1,
    },
    {
      field: "notificationEmail",
      headerName: t("company.formValues.email"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "phone",
      headerName: t("company.formValues.phone"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "address.country",
      headerName: t("company.formValues.address.country"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.address.country,
    },
    {
      field: "address.city",
      headerName: t("company.formValues.address.city"),
      flex: 1,
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) => row.address.city,
    },
    {
      field: "address.areaName",
      headerName: t("company.formValues.address.areaName"),
      flex: 1,
      hide: true,
      type: "areaNameAndType",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.address.areaName + " " + row.address.areaType,
    },
    {
      field: "address",
      headerName: t("company.addressTitle"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.address),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_GET",
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("company.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/company/details?id=${row.id}&from=company`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_GET",
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("company.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/company-modify/${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/company-create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("company.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/company/details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="company"
      filterable={[
        "name",
        "notificationEmail",
        "phone",
        "address.country",
        "address.city",
        "address.areaName",
      ]}
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={companyListQuery}
      onCellClick={onCellClick}
    />
  );
};

export default CompanyList;
