import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanyById } from "shared/network/company.api";
import RelItemCompanyList from "views/Company/relItemCompany/RelItemCompanyList";
import DeliveryNoteList from "views/Delivery/DeliveryNote/DeliveryNoteList";
import IncomingInvoiceList from "views/Invoice/IncomingInvoice/IncomingInvoiceList";
import InvoiceList from "views/Invoice/InvoiceList";
import OfferList from "views/Offer/OfferList";
import CompanyDelivery from "../CompanyDelivery";
import CompanyDetails from "./CompanyDetails/CompanyDetails";

export const COMPANY_DETAILS_TABS = [
  `/company/details`,
  `/company/products`,
  `/company/invoices`,
  `/company/incoming-invoices`,
  `/company/delivery-notes`,
  `/company/delivery`,
  "/company/offers",
];

const CompanyDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderName } = useHeader();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  //const from = query.get("from");

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const companyQuery = useQuery(["companyByIdQuery", id], async () => {
    if (id) {
      const { data } = await getCompanyById(
        Number.parseInt(id),
        selectedRelTenant.tenant.id,
      );
      return data.item;
    }
    return Promise.reject();
  });
  const companyIsSupplier = companyQuery.data?.isSupplier;
  const companyIsConsumer = companyQuery.data?.isCustomer;

  useEffect(() => {
    if (companyQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (companyQuery.data?.name) {
      setHeaderName(`${companyQuery.data.name} ${t("common:details.details")}`);
    }
    return () => {
      setHeaderName(null);
    };
  }, [companyQuery.data?.name, companyQuery.isFetching, setHeaderName, t]);

  return (
    <Container maxWidth="xl">
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("company.tabs.details")}
            component={Link}
            to={`${COMPANY_DETAILS_TABS[0]}?id=${id}`}
            value={COMPANY_DETAILS_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) && (
          <Tab
            label={t("relItemCompany.title")}
            component={Link}
            to={`${COMPANY_DETAILS_TABS[1]}?id=${id}`}
            value={COMPANY_DETAILS_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) &&
          companyIsConsumer && (
            <Tab
              label={t("invoice.tab")}
              component={Link}
              to={`${COMPANY_DETAILS_TABS[2]}?id=${id}`}
              value={COMPANY_DETAILS_TABS[2]}
            />
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) &&
          companyIsSupplier && (
            <Tab
              label={t("incomingInvoice.tab")}
              component={Link}
              to={`${COMPANY_DETAILS_TABS[3]}?id=${id}`}
              value={COMPANY_DETAILS_TABS[3]}
            />
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) && (
          <Tab
            label={t("deliveryNote.deliveryNoteTitle")}
            component={Link}
            to={`${COMPANY_DETAILS_TABS[4]}?id=${id}`}
            value={COMPANY_DETAILS_TABS[4]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) && (
          <Tab
            label={t("vehicle.tabs.delivery")}
            component={Link}
            to={`${COMPANY_DETAILS_TABS[5]}?id=${id}`}
            value={COMPANY_DETAILS_TABS[5]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
          "OFFER_GET",
        ]) && (
          <Tab
            label={t("project.tabs.offer")}
            component={Link}
            to={`${COMPANY_DETAILS_TABS[6]}?id=${id}`}
            value={COMPANY_DETAILS_TABS[6]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[0]}
            component={() => (
              <CompanyDetails
                company={companyQuery.data || null}
                loading={companyQuery.isFetching}
                refetch={companyQuery.refetch}
              />
            )}
            permissions={["COMPANY_GET"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[1]}
            component={RelItemCompanyList}
            permissions={["REl_ITEM_COMPANY_ADMIN"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[2]}
            component={InvoiceList}
            permissions={["INVOICE_ADMIN"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[3]}
            component={IncomingInvoiceList}
            permissions={["INCOMING_INVOICE_ADMIN"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[4]}
            component={DeliveryNoteList}
            permissions={["COMPANY_ADMIN"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[5]}
            component={CompanyDelivery}
            permissions={["COMPANY_ADMIN"]}
          />
          <PrivateRoute
            path={COMPANY_DETAILS_TABS[6]}
            component={OfferList}
            permissions={["OFFER_GET"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default CompanyDetailsTabs;
