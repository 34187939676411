import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";

export type CustomField = {
  id: number;
  tenantId: number;
  name: string;
  type: string;
  fieldValues: string;
  htmlAttributes: string;
};

const ENDPOINT = "/custom-fields";

export const createCustomField = (
  param: Partial<CustomField>,
  tenantId: number
) => axios.post(`${ENDPOINT}/create`, { param, tenantId }); //CUSTOM_FIELDS_ADMIN

export const modifyCustomField = (
  param: Partial<CustomField>,
  tenantId: number
) => axios.put(`${ENDPOINT}/modify`, { param, tenantId }); //CUSTOM_FIELDS_ADMIN

export const deleteCustomField = (param: string | number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete`, { data: { param, tenantId } }); //LOGIN_REQUIRED

export const getCustomFieldById = (param: string | number, tenantId: number) =>
  axios.post<GenericResponse<CustomField>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED

export const getCustomFields = (tenantId: number) =>
  axios.get<GenericListResponse<CustomField>>(
    `${ENDPOINT}/all?tenantId=${tenantId}`
  ); //LOGIN_REQUIRED
