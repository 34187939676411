import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listDepartmentByEmployee } from "shared/network/department.api";
import {
  createRelDepartmentEmployee,
  deleteRelDepartmentEmployee,
} from "shared/network/rel-department-employee";
import { Department } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import RelDepartmentEmployeeForm from "./RelDepartmentEmployeeForm";

const RelDepartmentEmployeeList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const form = useForm<{ department: Department }>();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();
  const [open, setOpen] = useState(false);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const relDepartmentEmployeeQuery = useQuery(
    ["relDepartmentEmployeeQuery", id],
    async () => {
      if (id) {
        const { data } = await listDepartmentByEmployee(
          Number.parseInt(id),
          tenant.id,
        );

        return data;
      }
      return Promise.reject();
    },
  );

  async function onDelete(departmentId?: number) {
    try {
      if (id && departmentId) {
        await deleteRelDepartmentEmployee(id, departmentId, tenant.id);
        relDepartmentEmployeeQuery.refetch();
        enqueueSnackbar(
          t("common:notification.delete.success", {
            subject: t("item.category.subject"),
          }),
          {
            variant: "success",
          },
        );
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("department.relDepartmentEmployee.department"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.name || "-",
    },
    {
      field: "address",
      headerName: t("department.formValues.address.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.address),
      sortable: false,
    },
    {
      field: "description",
      headerName: t("department.relDepartmentEmployee.department"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("department.delete").toString()}>
                <IconButton
                  onClick={() => {
                    if (row.id) {
                      onDelete(row.id);
                    }
                  }}
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                >
                  <Delete color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  const onSubmitCreate = async (values: { department: Department }) => {
    try {
      if (id && values.department.id) {
        await createRelDepartmentEmployee(
          {
            departmentId: values.department.id,
            tenantId: tenant.id,
            employeeId: id,
          },
          tenant.id,
        );
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("department.subject"),
          }),
          {
            variant: "success",
          },
        );
        setOpen(false);
        relDepartmentEmployeeQuery.refetch();
      }
    } catch (e) {
      const error = e as any;
      const status = error?.data?.status;
      if (status === "DUPLICATED") {
        enqueueSnackbar(t("common:notification.duplicate.department"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikertelen törlés", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DEPARTMENT_ADMIN",
        ]) && (
          <Button onClick={() => setOpen(true)} startIcon={<AddBox />}>
            {t("department.relDepartmentEmployee.add")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container maxWidth="lg">
      <Dialog open={open}>
        <DialogTitle>{t("department.relDepartmentEmployee.add")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <RelDepartmentEmployeeForm setOpen={setOpen} />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
      <SingleQueryTable
        query={relDepartmentEmployeeQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};
export default RelDepartmentEmployeeList;
