import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit, GetApp } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { INVOICE_TYPE } from "config/constants";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getInvoiceFile } from "shared/network/files.api";
import { listInvoicePageable } from "shared/network/invoice.api";
import { Invoice, InvoiceItem } from "shared/types";
import { invoiceDownload } from "shared/util/fileDownload";
import { TableState } from "views/Company/CompanyList/CompanyList";
import InvoiceStatusModifyDialog from "./InvoiceStatusModifyDialog";

const InvoiceList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-invoice-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-invoice-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const invoiceListQuery = useQuery(
    [
      "listInvoicePageable",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await listInvoicePageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue ? `customerId:${id};` + filterValue : `customerId:${id}`,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: t("invoice.formValues.customer.customerName"),
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.creationDate
          ? format(new Date(row.creationDate), "P", { locale: hu })
          : "",
      type: "date",
    },
    {
      field: "invoiceType",
      headerName: t("invoice.formValues.invoiceType.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`invoice.formValues.invoiceType.${row.invoiceType}`),
      type: "select",
    },
    {
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        let temp = 0;
        row.invoiceItems.forEach(
          (invoiceItem: InvoiceItem) =>
            (temp += Number.parseInt(invoiceItem.grossPrice.toString())),
        );
        return t("common:number", { num: temp }) + " Ft";
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) &&
            row.pdfPath && (
              <Tooltip title={t("invoice.downloadPdf").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getInvoiceFile(
                          row.id,
                          selectedRelTenant.tenant.id,
                        );
                        invoiceDownload(data, t("invoice.downloadFile"));
                        enqueueSnackbar(
                          t("common:notification.download.success", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "success",
                          },
                        );
                      } catch {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_GET",
          ]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                component={Link}
                to={`/invoice-details/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modify").toString()}>
              <IconButton
                component={Link}
                to={`/invoice-modify/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modifyStatus").toString()}>
              <IconButton
                size="small"
                style={{ margin: "0 8px" }}
                onClick={() => setSelectedInvoice(row as Invoice)}
                color="primary"
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/invoice-details/${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Button component={Link} to="/invoice-create" startIcon={<AddBox />}>
            {t("invoice.create")}
          </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GetApp />}
          style={{ margin: "auto" }}
        >
          {t("common:button.export")}
        </Button>
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  return (
    <>
      <InvoiceStatusModifyDialog
        selectedInvoice={selectedInvoice}
        setSelectedInvoice={setSelectedInvoice}
        refetch={invoiceListQuery.refetch}
      />
      <PageableTable
        filterOptions={[
          {
            columnName: "invoiceType",
            options: INVOICE_TYPE.map(value => {
              return {
                translated: t(`invoice.formValues.invoiceType.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={["customerName", "grossPrice", "creationDate"]} //ha backenden jó, akkor ezt ki kell szedni
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="invoice"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={invoiceListQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};
export default InvoiceList;
