import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import {
  Company,
  IssueTemplate,
  Item,
  Project,
  Tool,
  User,
} from "shared/types";

export type IssueTemplateRequest = {
  id?: number | string;
  tenantId?: number;
  project: Project | null;
  assignedUser: User | null;
  name: string;
  description: string;
  priority: string;
  deadlineDays: string;
  periodicCycleType: string | null;
  periodicCycleAmount: number;
  estimatedTime: string;
  company: Company | null;
  issueTypeId: string | number;
  isActive: boolean;
};

const ENDPOINT = "/issue-template";

export const createIssueTemplate = (
  issueTemplateEntry: IssueTemplateRequest,
  itemList: Item[],
  toolList: Tool[],
  userList: User[],
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/create`, {
    issueTemplateEntry,
    itemList,
    toolList,
    userList,
    tenantId,
  }); //ISSUE_TEMPLATE_ADMIN

export const modifyIssueTemplate = (
  issueTemplateEntry: IssueTemplate,
  itemList: Item[],
  toolList: Tool[],
  userList: User[],
  tenantId: number,
) =>
  axios.put<GenericResponse<IssueTemplate>>(`${ENDPOINT}/modify`, {
    issueTemplateEntry,
    itemList,
    toolList,
    userList,
    tenantId,
  }); //ISSUE_TEMPLATE_ADMIN

export const listIssueTemplate = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<IssueTemplate>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //ISSUE_TEMPLATE_GET

export const getIssueTemplateById = (id: number, tenantId: number) =>
  axios.post<GenericResponse<IssueTemplate>>(`${ENDPOINT}/get-by-id`, {
    param: id,
    tenantId: tenantId,
  }); //ISSUE_TEMPLATE_GET
