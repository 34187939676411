import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import {
  hasAnyAuthority,
  hasNoPermissionButHasCompany,
} from "shared/authorization";
import IssueTemplateList from "views/Issues/IssueTemplate/IssueTemplateList";
import IssueList from "./IssueList";
import IssueTypeList from "./IssueType/IssueTypeList";
import PersonalIssueList from "./PersonalIssueList";
import CustomFields from "views/Issues/CustomFields/CustomFields";

export const ISSUES_TABS = [
  "/issues/all",
  "/issues/personal",
  "/issues/templates",
  "/issues/issue-type",
  `/issues/custom-fields`,
];

const IssuesTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Container maxWidth="xl">
      {location.pathname === "/issues" && <Redirect to="/issues/all" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_GET",
        ]) && (
          <Tab
            label={t("issues.allIssues")}
            component={Link}
            to={ISSUES_TABS[0]}
            value={ISSUES_TABS[0]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_GET",
        ]) && (
          <Tab
            label={t("issues.personalIssues")}
            component={Link}
            to={ISSUES_TABS[1]}
            value={ISSUES_TABS[1]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_TEMPLATE_GET",
        ]) && (
          <Tab
            label={t("issueTemplate.title")}
            component={Link}
            to={ISSUES_TABS[2]}
            value={ISSUES_TABS[2]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_TYPE_GET",
        ]) && (
          <Tab
            label={t("issueType.title")}
            component={Link}
            to={ISSUES_TABS[3]}
            value={ISSUES_TABS[3]}
          />
        )}
        <Tab
          label="Egyedi mezők"
          component={Link}
          to={ISSUES_TABS[4]}
          value={ISSUES_TABS[4]}
        />
        {hasNoPermissionButHasCompany(account) && (
          <Tab
            label={t("issues.allIssues")}
            component={Link}
            to={ISSUES_TABS[0]}
            value={ISSUES_TABS[0]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={ISSUES_TABS[0]}
            component={IssueList}
            permissions={["ISSUE_GET"]}
          />
          <PrivateRoute
            path={ISSUES_TABS[1]}
            component={PersonalIssueList}
            permissions={["ISSUE_GET"]}
          />
          <PrivateRoute
            path={ISSUES_TABS[2]}
            component={IssueTemplateList}
            permissions={["ISSUE_TEMPLATE_GET"]}
          />
          <PrivateRoute
            path={ISSUES_TABS[3]}
            component={IssueTypeList}
            permissions={["ISSUE_TYPE_GET"]}
          />
          <PrivateRoute path={ISSUES_TABS[4]} component={CustomFields} />
          {/*csak azoknak a felhasználóknak, akiknek semmi jogosultságuk sincs, de céghez tartoznak*/}
          {hasNoPermissionButHasCompany(account) && (
            <PrivateRoute path={ISSUES_TABS[0]} component={IssueList} />
          )}
        </Switch>
      </Box>
    </Container>
  );
};

export default IssuesTabs;
