import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { listCompanyCategoryByTenant } from "shared/network/company-category.api";
import CompanyCategoryRow from "./CompanyCategoryRow";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { Pagination } from "@material-ui/lab";
import Loading from "components/Loading";

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginTop: 8,
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const CompanyCategoryList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [page, setPage] = useState(
    parseInt(
      window.sessionStorage.getItem("CompanyCategoryPageNumber") ||
        JSON.stringify(0),
    ),
  );

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );
  const { user } = useSelector(
    (state: RootState) => state.authentication.account,
  );

  const companyCategoryListQuery = useQuery(
    ["listCompanyCategory", page],
    async () => {
      const { data } = await listCompanyCategoryByTenant(
        page,
        10,
        user?.isSuperAdmin ? 0 : tenant.id,
        "",
      );
      return data;
    },
  );

  return (
    <>
      <Loading open={companyCategoryListQuery.isFetching} />
      {companyCategoryListQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={Link}
              to="/company-category-create"
              startIcon={<AddIcon />}
            >
              {t("companyCategory.create")}
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {companyCategoryListQuery.data?.page.numberOfElements === 0 ? (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:noData")}
              </Typography>
              <Button
                component={Link}
                to="/company-category-create"
                startIcon={<AddIcon />}
              >
                {t("companyCategory.create")}
              </Button>
            </Box>
          ) : (
            <Container maxWidth="sm">
              <Grid container className={classes.header}>
                <Grid item xs={6} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("companyCategory.formValues.name")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="flex-end"
                  className={classes.cell}
                >
                  <Button
                    component={Link}
                    to="/company-category-create"
                    startIcon={<AddIcon />}
                  >
                    {t("companyCategory.create")}
                  </Button>
                </Grid>
              </Grid>
              {companyCategoryListQuery.data?.page.numberOfElements &&
                companyCategoryListQuery.data.page.content.map(
                  companyCategory => (
                    <CompanyCategoryRow
                      key={companyCategory.id}
                      companyCategory={companyCategory}
                      refetch={companyCategoryListQuery.refetch}
                    />
                  ),
                )}
              {companyCategoryListQuery.data &&
                companyCategoryListQuery?.data?.page.totalPages > 1 && (
                  <Pagination
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "24px 0",
                    }}
                    count={companyCategoryListQuery?.data?.page.totalPages}
                    color="primary"
                    page={companyCategoryListQuery.data.page.number + 1}
                    onChange={(e, page) => {
                      sessionStorage.setItem(
                        "CompanyCategoryPageNumber",
                        JSON.stringify(page - 1),
                      );
                      setPage(page - 1);
                    }}
                  />
                )}
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default CompanyCategoryList;
