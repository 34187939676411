import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Block, CheckCircleOutline, Edit } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { Link as RouterLink } from "components/Router";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { hasAuthority } from "shared/authorization";
import { listSerialItems } from "shared/network/serial-item.api";
import { TableState } from "views/Company/CompanyList/CompanyList";

const SerialItemList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-serialitem-list-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-serialitem-list-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const listSerialItemsQuery = useQuery(
    ["listSerialItemsQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listSerialItems(
        page,
        pageSize,
        tenant.id,
        id ? `item.id:${id}` : "" + filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("serialItems.item"),
      flex: 1,
      hide: !!id,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name,
    },
    {
      field: "factoryNumber",
      headerName: t("serialItems.factoryNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "serialNumber",
      headerName: t("serialItems.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "isBasedOnMeasurement",
      headerName: t("serialItems.isBasedOnMeasurement"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {row.isBasedOnMeasurement ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : (
            <Block style={{ color: COLORS.red }} />
          )}
        </>
      ),
    },
    {
      field: "unit",
      headerName: t("serialItems.unit"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isBasedOnMeasurement ? row.unit : "-",
    },
    {
      field: "unitType",
      headerName: t("serialItems.unitType"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isBasedOnMeasurement
          ? t("serialItems.unitTypes." + row.unitType)
          : "-",
    },
    {
      field: "totalUsed",
      headerName: t("serialItems.totalUsed"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isBasedOnMeasurement ? row.totalUsed : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ITEM_ADMIN",
          ]) && (
            <Tooltip title={t(`item.itemModify`).toString()}>
              <IconButton
                size="small"
                color="primary"
                component={RouterLink}
                to={`/serial-item-modify?id=${row.id}`}
                style={{ margin: "0 8px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="felx" alignItems="center" gridGap={8}>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
  }, []); //eslint-disable-line

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="serialitem-list"
      filterable={[]} //ha a backend jó, akkor ezt át kell majd írni
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listSerialItemsQuery}
    />
  );
};

export default SerialItemList;
