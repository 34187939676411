import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  ArrowBackIosOutlined,
  CheckCircle,
  Home,
  MoreVert,
  Search,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { Autocomplete } from "@material-ui/lab";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { useSnackbar } from "notistack";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  FunctionSearchEntry,
  functionSearchList,
} from "shared/functions/functionSearchList";
import { setHomePage } from "shared/network/user.api";
import { fetchAccount } from "shared/reducers/authentication";
import { getPageName } from "shared/util/getPageName";
import { useHeader } from "./HeaderContext";
import SideBar from "./SideBar/SideBar";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      position: "relative",
    },
    pageWrapper: {
      flex: 1,
      display: "flex",
      backgroundColor: COLORS.white,
      height: "100vh",
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      marginLeft: 200,
      width: "calc(100% - 200px)",
    },
  },
  {
    name: "Layout",
  },
);

const Layout = ({ children }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { headerButtons, headerName, headerMenuItems } = useHeader();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(matchesMd ? 80 : 200);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedFunction, setSelectedFunction] = useState("");

  const [permissionList, setPermissionList] = useState<string[]>([]);

  const [availableFunctionList, setAvailableFunctionList] =
    useState<FunctionSearchEntry[]>(functionSearchList);

  const { isAuthenticated, selectedRelTenant, account } = useSelector(
    (state: RootState) => state.authentication,
  );

  const page = pathname.match(/^\/([^/]*)[^/]?/)?.[1] || "home";
  const title = t([`drawer.${page}`, "drawer.notFound"]);

  useEffect(() => {
    if (selectedFunction !== "") {
      history.push(selectedFunction);
      setSelectedFunction("");
    }
  }, [selectedFunction]); //eslint-disable-line

  useEffect(() => {
    if (account && selectedRelTenant && account.permissions) {
      setPermissionList(
        account.permissions.find(permission => {
          return permission.id === selectedRelTenant.tenant.id;
        })?.permissions || [],
      );
    }
  }, [selectedRelTenant, account]);

  useEffect(() => {
    if (account.user) {
      setAvailableFunctionList(
        functionSearchList.filter(functionEntry =>
          isFunctionAvaible(functionEntry),
        ),
      );
    }
  }, [account, permissionList]); //eslint-disable-line

  function isFunctionAvaible(functionSearchEntry: FunctionSearchEntry) {
    if (account.user.isSuperAdmin) {
      return true;
    } else if (
      !functionSearchEntry.needSuperAdmin &&
      selectedRelTenant.isTenantAdmin
    ) {
      return true;
    } else if (
      !functionSearchEntry.needSuperAdmin &&
      !functionSearchEntry.needTenantAdmin &&
      userPermissionCheck(functionSearchEntry)
    ) {
      return true;
    }
    return false;
  }

  function userPermissionCheck(functionSearchEntry: FunctionSearchEntry) {
    var hasPermissions = false;
    if (functionSearchEntry.permissions.length === 0) {
      return true;
    }
    functionSearchEntry.permissions.forEach(permission => {
      if (permissionList.includes(permission)) {
        hasPermissions = true;
      }
    });
    return hasPermissions;
  }

  const onHomePageChange = async (pathname: string) => {
    setLoading(true);
    if (selectedRelTenant?.tenant?.id) {
      try {
        await setHomePage(pathname, selectedRelTenant.tenant.id);
        setAnchorEl(null);
        dispatch(fetchAccount());
        enqueueSnackbar(t("homePage.setSuccess"), {
          variant: "success",
        });
      } catch {
        enqueueSnackbar("homePage.setError", {
          variant: "error",
        });
      }
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return <>{children}</>;
  } else {
    return (
      <Box className={classes.pageWrapper}>
        <SideBar
          open={open}
          setOpen={setOpen}
          width={width}
          setWidth={setWidth}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="100%"
        >
          {!matchesSm && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              style={{
                background: GRADIENT,
              }}
            >
              <Tooltip title={t("layout.open").toString()}>
                <IconButton
                  onClick={() => {
                    setOpen(!open);
                    setWidth(200);
                  }}
                >
                  <MenuIcon style={{ color: COLORS.white }} />
                </IconButton>
              </Tooltip>
              <Button
                variant="text"
                component={Link}
                to="/"
                style={{
                  color: COLORS.white,
                  fontSize: 28,
                  borderRadius: 0,
                }}
              >
                {getPageName(window.location.hostname)}
              </Button>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            width="100%"
            padding={
              matchesMd
                ? "24px 24px 24px calc(200px + 24px)"
                : matchesSm
                ? "24px 24px 24px calc(80px + 24px)"
                : "24px"
            }
          >
            <Box display="flex" alignItems="center" gridGap={14}>
              {pathname !== "/" && (
                <Tooltip title={t("common:button.back").toString()}>
                  <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIosOutlined />
                  </IconButton>
                </Tooltip>
              )}
              <Box display="flex" alignItems="center">
                <Typography variant="h1">{headerName || title}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gridGap={8}>
              <Box>{headerButtons}</Box>
              <Box width="200px">
                <Autocomplete
                  key={selectedFunction}
                  fullWidth
                  defaultValue={availableFunctionList[0]}
                  onChange={(event, value) =>
                    value && setSelectedFunction(value.target)
                  }
                  options={availableFunctionList || []}
                  getOptionLabel={(option: FunctionSearchEntry) =>
                    t(`functionSearch.${option.target}`)
                  }
                  //getOptionSelected={option => option.id === inputv.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ margin: "0 0 0 8px" }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              {/*Értesítés felület*/}
              {/*<Notifications />*/}
              <Tooltip title={t("layout.settings").toString()}>
                <IconButton
                  onClick={(event: MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  disabled={account.user.homePage === window.location.pathname}
                  onClick={() =>
                    onHomePageChange(
                      window.location.pathname + window.location.search,
                    )
                  }
                >
                  <ListItemIcon style={{ minWidth: 48 }}>
                    {loading ? (
                      <CircularProgress style={{ width: 20, height: 20 }} />
                    ) : account.user.homePage === window.location.pathname ? (
                      <CheckCircle />
                    ) : (
                      <Home />
                    )}
                  </ListItemIcon>
                  <Box>
                    <Typography>{t("homePage.set")}</Typography>
                  </Box>
                </MenuItem>
                {headerMenuItems}
              </Menu>
            </Box>
          </Box>
          <Box
            className={classes.content}
            style={{
              marginLeft: matchesMd ? 200 : matchesSm ? 80 : 0,
              width: matchesMd
                ? `calc(100% - 200px)`
                : matchesSm
                ? `calc(100% - 80px)`
                : "100%",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  }
};

export default Layout;
