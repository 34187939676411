import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { BaseCurrency } from "shared/types";

const ENDPOINT = "/currency";

export const createCurrency = (currency: BaseCurrency, tenantId: number) =>
  axios.post(`${ENDPOINT}`, {
    param: {
      code: currency.code,
      name: currency.name,
      prefix: currency.prefix,
      postfix: currency.postfix,
    },
    tenantId: tenantId,
  }); //ISTENANTADMIN ISSUPERADMIN

export const modifyCurrency = (currency: BaseCurrency, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    param: {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      prefix: currency.prefix,
      postfix: currency.postfix,
    },
    tenantId: tenantId,
  }); //ISTENANTADMIN ISSUPERADMIN

export const listCurrencyByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<BaseCurrency>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //LOGIN_REQUIRED

export const listAvaibleCurrencies = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<BaseCurrency>>(
    `/all-currency/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //LOGIN_REQUIRED

export const getCurrencyById = (param: number | string, tenantId: number) =>
  axios.post<GenericResponse<BaseCurrency>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED
