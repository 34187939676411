import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { modifyProperty } from "shared/network/properties.api";
import { Property } from "shared/types";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

type Props = {
  property: Property;
  onSubmitSuccess?: () => void;
};

const PropertiesRow = ({ property, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const mutation = useMutation<any, any, { [p: string]: any }>(
    async (values) => {
      await modifyProperty({ ...property, ...values }, tenant.id);
    },
    {
      onSuccess: () => {
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("property.subject"),
          }),
          { variant: "success" }
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("property.subject"),
          }),
          { variant: "error" }
        );
      },
    }
  );

  return (
    <form
      onSubmit={form.handleSubmit((values) => mutation.mutate(values))}
      style={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
    >
      <Controller
        control={form.control}
        name="value"
        defaultValue={property.value}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            InputLabelProps={{ shrink: true, required: true }}
            label={t(`property.properties.${property.name}`)}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            InputProps={{
              style: { paddingRight: 6 },
              endAdornment: (
                <InputAdornment position="end" style={{ marginLeft: 6 }}>
                  <Tooltip title={t("common:button.save").toString()}>
                    <IconButton type="submit" color="primary">
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </form>
  );
};

export default PropertiesRow;
