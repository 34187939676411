import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { modifyVehicleCargo } from "shared/network/cargo.api";
import { listWarehousePageable } from "shared/network/warehouse.api";
import { Cargo } from "shared/types";

type Props = {
  vehicleId?: string | null;
  cargo?: Cargo[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  open: boolean;
};

export type CargoRemoveFormValues = {
  vehicleId: string | null;
  removeEverything: boolean;
  cargoItems: CargoHelper[];
  warehouseId: number | string;
};

export type CargoHelper = {
  cargo: Cargo | null;
  quantity: number;
};

const useStyles = makeStyles({
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
});

const CargoRemove = ({ vehicleId, cargo, setOpen, refetch, open }: Props) => {
  const { t } = useTranslation();
  const { control, watch, getValues, setValue, handleSubmit } =
    useForm<CargoRemoveFormValues>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { append, fields } = useFieldArray({
    control,
    name: "cargoItems",
    keyName: "key",
  });

  const removeEverything = watch("removeEverything");

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );

  const getWarehouseList = useQuery(
    ["getWarehouseList", selectedRelTenant],
    async () => {
      const { data } = await listWarehousePageable(
        0,
        100000,
        selectedRelTenant.tenant.id,
        "",
        ""
      );
      return data;
    }
  );

  function setMax(index: number) {
    if (getValues(`cargoItems.${index}.cargo`)) {
      setValue(
        `cargoItems.${index}.quantity`,
        cargo?.find(
          (item) =>
            item.quantity === getValues(`cargoItems.${index}.cargo`)?.quantity
        )?.quantity || 0
      );
    }
  }

  function checkQuantity(index: number) {
    const removeQuantity = getValues(`cargoItems.${index}.quantity`);
    const cargoQuantity =
      cargo?.find(
        (item) =>
          item.quantity === getValues(`cargoItems.${index}.cargo`)?.quantity
      )?.quantity || 0;
    setValue(
      `cargoItems.${index}.quantity`,
      removeQuantity > cargoQuantity
        ? cargoQuantity
        : removeQuantity > 0
        ? removeQuantity
        : 0
    );
  }

  const onSubmitCargoRemove = async (values: CargoRemoveFormValues) => {
    try {
      if (vehicleId) {
        await modifyVehicleCargo(
          { ...values, vehicleId: vehicleId },
          selectedRelTenant.tenant.id
        );

        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("bundle.subject"),
          }),
          {
            variant: "success",
          }
        );
        setOpen(false);
        refetch();
      }
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("bundle.subject"),
        }),
        { variant: "error" }
      );
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("cargo.modify")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmitCargoRemove)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="removeEverything"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("cargo.formValues.removeEverything")}
                    labelPlacement="start"
                    control={
                      <Switch
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="warehouseId"
                defaultValue={""}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("cargo.formValues.targetWarehouse")}
                    InputLabelProps={{ shrink: true, required: true }}
                    select
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem value={""}></MenuItem>
                    {getWarehouseList.data?.page.content.map((warehouse) => (
                      <MenuItem key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={!removeEverything}>
                {fields.map((field, index) => {
                  return (
                    <Grid key={index} container style={{ paddingBottom: 16 }}>
                      <Grid item xs={6} style={{ paddingRight: 8 }}>
                        <Controller
                          control={control}
                          name={`cargoItems.${index}.cargo`}
                          defaultValue={null}
                          rules={{
                            required: t("validation.required").toString(),
                          }}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              {...field}
                              onChange={(_, value) => field.onChange(value)}
                              options={cargo || []}
                              getOptionLabel={(option: Cargo) =>
                                option.item.name
                              }
                              getOptionSelected={(option) =>
                                option.id === field.value?.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                    required: true,
                                  }}
                                  label={t("cargo.formValues.item")}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                      >
                        <Controller
                          control={control}
                          name={`cargoItems.${index}.quantity`}
                          defaultValue={1}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              InputLabelProps={{
                                shrink: true,
                                required: true,
                              }}
                              onChange={(event) => {
                                field.onChange(event.target.value);
                                checkQuantity(index);
                              }}
                              label={t("cargo.formValues.quantity")}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ paddingLeft: 8 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => setMax(index)}
                        >
                          {t("cargo.formValues.max")}
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" gridGap={8}>
                    <Button
                      variant="outlined"
                      onClick={(event) => {
                        event.stopPropagation();
                        append({
                          cargo: null,
                          quantity: 1,
                        });
                      }}
                      startIcon={<AddBox />}
                    >
                      {t("cargo.formValues.addItem")}
                    </Button>
                  </Box>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" m={2} gridGap={8}>
            <Button
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              {t("common:button.cancel")}
            </Button>
            <Button type="submit">{t("common:button.ok")}</Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CargoRemove;
