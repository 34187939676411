import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AddBox, Clear, Done, Edit, ListAlt, Note } from "@material-ui/icons";
import BuildIcon from "@material-ui/icons/Build";
import HomeIcon from "@material-ui/icons/Home";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { PROGRESS_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { format as dateFormat } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority, hasPermissionButHasCompany } from "shared/authorization";
import { listCompanyByTenant } from "shared/network/company.api";
import { getDeliveryNoteFile, getInvoiceFile } from "shared/network/files.api";
import {
  getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder,
  getOrderListByTenantId,
} from "shared/network/order.api";
import { OrderStatusHistoryEntry } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { deliveryNoteOpen } from "shared/util/fileDownload";
import { TableState } from "views/Company/CompanyList/CompanyList";

export interface StyleProps {
  value: number;
}

const useStyles = makeStyles<StyleProps>(theme => ({
  progressPercentage1: {
    "& .MuiLinearProgress-barColorPrimary": {
      background: GRADIENT,
      width: "100%",
      height: 10,
      borderRadius: 5,
    },
    "&.MuiLinearProgress-colorPrimary": {
      background: COLORS.mainGrey,
      width: "100%",
      height: 10,
      borderRadius: 5,
    },
  },
}));

export function getProgressValue(row: GridRowData) {
  var progress,
    correction = 1;
  switch (
    row.orderStatusHistoryList[row.orderStatusHistoryList.length - 1].status
  ) {
    case PROGRESS_STATUS[0]: {
      progress = 5;
      break;
    }
    case PROGRESS_STATUS[1]: {
      progress = 25;
      break;
    }
    case PROGRESS_STATUS[2]: {
      progress = 50;
      break;
    }
    case PROGRESS_STATUS[3]: {
      progress = 75;
      break;
    }
    case PROGRESS_STATUS[4]: {
      progress = 100 - correction;
      break;
    }
    default: {
      progress = 0;
      break;
    }
  }
  return progress + correction;
}

const OrderListByStatus = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const [selectedStatus, setSelectedStatus] = useState<string | null>("NEW");

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-order-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-order-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const companyId = account?.user?.relUserCompany?.[0]?.company?.id;
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId || "");

  const companyListQuery = useQuery(
    ["companyListQuery", selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        1000000,
        selectedRelTenant.tenant.id,
      );

      return data;
    },
  );

  const fullOrderListQuery = useQuery(
    [
      "getOrderListByCompanyIdQuery",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
      selectedStatus,
      companyId,
      selectedCompanyId,
    ],
    async () => {
      const { data } = await getOrderListByTenantId(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (selectedCompanyId ? `company.id:${selectedCompanyId};` : "") +
          (selectedStatus ? `status:${selectedStatus};` : "") +
          filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "deliveryAddress",
      headerName: t("order.deliveryAddress"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.deliveryAddress),
    },
    {
      field: "orderDate",
      headerName: t("order.orderDate"),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.orderStatusHistoryList
          ? dateFormat(
              new Date(row.orderStatusHistoryList[0].createdOn),
              "yyyy. MM. dd. HH:mm",
            )
          : "-",
    },
    {
      field: "progress",
      headerName: t("order.status"),
      flex: 1.5,
      sortable: false,
      renderHeader: () => (
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip
            title={t("common:progress.new").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              style={{
                color:
                  selectedStatus === PROGRESS_STATUS[0]
                    ? COLORS.main
                    : COLORS.greyWater,
              }}
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[0]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[0]);
                }
              }}
            >
              <NewReleasesIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t("common:progress.scheduled").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              style={{
                color:
                  selectedStatus === PROGRESS_STATUS[1]
                    ? COLORS.main
                    : COLORS.greyWater,
              }}
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[1]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[1]);
                }
              }}
            >
              <BuildIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t("common:progress.produced").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              style={{
                color:
                  selectedStatus === PROGRESS_STATUS[2]
                    ? COLORS.main
                    : COLORS.greyWater,
              }}
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[2]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[2]);
                }
              }}
            >
              <ScheduleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t("common:progress.inCargo").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              style={{
                color:
                  selectedStatus === PROGRESS_STATUS[3]
                    ? COLORS.main
                    : COLORS.greyWater,
              }}
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[3]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[3]);
                }
              }}
            >
              <LocalShippingIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t("common:progress.delivered").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              style={{
                color:
                  selectedStatus === PROGRESS_STATUS[4]
                    ? COLORS.main
                    : COLORS.greyWater,
              }}
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[4]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[4]);
                }
              }}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Grid container>
            <Grid container item xs={12}>
              <Tooltip
                arrow
                placement="top"
                title={`${dateFormat(
                  new Date(
                    row.orderStatusHistoryList[
                      row.orderStatusHistoryList.length - 1
                    ].createdOn,
                  ),
                  "yyyy. MM. dd. HH:mm",
                )}`}
              >
                <LinearProgress
                  className={classes.progressPercentage1}
                  value={getProgressValue(row)}
                  variant="determinate"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      field: "isPaid",
      headerName: t("order.isPaid.title"),
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip
            title={
              t("order.isPaid.title") +
              " " +
              (row.paidDate
                ? dateFormat(new Date(row.paidDate), "yyyy. MM. dd. HH:mm")
                : "")
            }
          >
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`order.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "invoiceSent",
      headerName: t("order.invoiceSent"),
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.orderStatusHistoryList ? (
          row.orderStatusHistoryList.find(
            (orderStatus: OrderStatusHistoryEntry) =>
              orderStatus.status === PROGRESS_STATUS[5],
          ) ? (
            <Tooltip
              title={
                t(`order.billed.true`).toString() +
                " " +
                dateFormat(
                  row.orderStatusHistoryList.find(
                    (orderStatus: OrderStatusHistoryEntry) =>
                      orderStatus.status === PROGRESS_STATUS[5],
                  ).createdOn,
                  "yyyy. MM. dd. HH:mm",
                )
              }
            >
              <Done style={{ color: COLORS.green }} />
            </Tooltip>
          ) : (
            <Tooltip title={t(`order.billed.false`).toString()}>
              <Clear style={{ color: COLORS.red }} />
            </Tooltip>
          )
        ) : (
          <Tooltip title={t(`order.billed.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Box display="flex" justifyContent="flex-end" width="100%">
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["DELIVERY_NOTE_ADMIN"],
            ) && (
              <Tooltip title={t("deliveryNote.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } =
                          await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                            row.id,
                            row.tenantId,
                            "invoice",
                          );
                        if (data.item) {
                          const response = await getDeliveryNoteFile(
                            data.item,
                            row.tenantId,
                          );
                          deliveryNoteOpen(response.data);
                        } else {
                          enqueueSnackbar(
                            t("common:notification.deliveryNote.notExist", {
                              subject: t("deliveryNote.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("deliveryNote.subject"),
                          }),
                          { variant: "error" },
                        );
                      }
                    }
                  }}
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["INVOICE_ADMIN"],
            ) && (
              <Tooltip title={t("invoice.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={async event => {
                    event.preventDefault();
                    if (row.id) {
                      try {
                        const { data } =
                          await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                            row.id,
                            row.tenantId,
                            "invoice",
                          );

                        if (data.item) {
                          const response = await getInvoiceFile(
                            data.item,
                            row.tenantId,
                          );
                          deliveryNoteOpen(response.data);
                          enqueueSnackbar(
                            t("common:notification.download.success", {
                              subject: t("invoice.subject"),
                            }),
                            { variant: "success" },
                          );
                        } else {
                          enqueueSnackbar(
                            t("common:notification.invoice.notExist", {
                              subject: t("invoice.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ISSUE_ADMIN"],
            ) && (
              <Tooltip title={t("issues.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } =
                          await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                            row.id,
                            row.tenantId,
                            "invoice",
                          );
                        if (data?.item) {
                          history.push(`/issue-details/${data.item}`);
                        } else {
                          enqueueSnackbar(
                            t("common:notification.issue.notExist", {
                              subject: t("issues.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.issue.notExist", {
                            subject: t("issues.subject"),
                          }),
                          { variant: "error" },
                        );
                      }
                    }
                  }}
                >
                  <Note />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ORDER_GET", "ORDER_ADMIN"],
            ) && (
              <Tooltip title={t("order.details.title").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/order-details/${row.id}`}
                >
                  <ListAlt />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ORDER_GET", "ORDER_ADMIN"],
            ) && (
              <Tooltip title={t("order.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/order-modify/${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/order-details/${params.row.id}`);
    }
  }

  useEffect(
    () => {
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ORDER_ADMIN",
          ]) && hasPermissionButHasCompany(account) ? (
            <Button
              component={Link}
              to={`/order-create`}
              startIcon={<AddBox />}
            >
              {t("order.create")}
            </Button>
          ) : (
            <Button
              component={Link}
              to={`/order-create/${
                !!account.user.relUserCompany &&
                account?.user?.relUserCompany[0]?.id
              }`}
              startIcon={<AddBox />}
            >
              {t("order.create")}
            </Button>
          )}
          {/*Export gomb*/}
          {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
        </Box>,
      );
      return () => {
        setHeaderButtons(null);
      };
    },
    /*eslint-disable*/
    [account.user, account.permissions, selectedRelTenant, filterValue],
  ); /*eslint-enable*/

  //   useEffect(() => {
  //     if (selectedStatus === "NEW") {
  //       fullOrderListQuery.data?.page.content.filter(
  //         (order) => order.status === "NEW"
  //       );
  //     }
  //   }, [selectedStatus, fullOrderListQuery.data?.page.content]);

  return (
    <Container maxWidth="xl">
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        style={{ margin: "8px" }}
      >
        <TextField
          select
          InputLabelProps={{ shrink: true }}
          disabled={!hasPermissionButHasCompany(account)}
          SelectProps={{ displayEmpty: true }}
          label={t("companySite.formValues.company")}
          style={{ width: 300, marginBottom: 12 }}
          value={selectedCompanyId}
          onChange={event => {
            setSelectedCompanyId(event.target.value);
          }}
        >
          <MenuItem value="" disabled>
            {t("common:choose")}
          </MenuItem>
          <MenuItem value="">{"Összes cég"}</MenuItem>
          {companyListQuery.data?.page?.content.map(company => (
            <MenuItem key={company.id} value={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="order"
        filterable={["deliveryAddress"]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={fullOrderListQuery}
        onCellClick={onCellClick}
      />
    </Container>
  );
};

export default OrderListByStatus;
