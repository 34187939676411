import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Bundle } from "shared/types";

const ENDPOINT = "/bundle"; //isSuperAdmin

export const createBundle = (param: Bundle, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId }); //SUPER_ADMIN

export const modifyBundle = (param: Bundle, tenantId: number) =>
  axios.put(ENDPOINT, {
    param,
    tenantId,
  }); //SUPER_ADMIN

export const getBundleList = (tenantId: number) =>
  axios.post<GenericListResponse<Bundle>>(`${ENDPOINT}/all`, { tenantId }); //SUPER_ADMIN

export const getBundleById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Bundle>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //SUPER_ADMIN
