import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Bundle } from "shared/types";

const useStyles = makeStyles({
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
});

type Props = {
  bundle?: Bundle;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const TenantBundleForm = ({ bundle, setOpen }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, control } = useFormContext<Bundle>();

  return (
    <>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          {!!bundle?.id && (
            <input type="hidden" value={bundle?.id} {...register("id")} />
          )}
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="hr"
              control={control}
              defaultValue={bundle?.hr || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.hr")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="depot"
              control={control}
              defaultValue={bundle?.depot || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.depot")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="invoiceManagement"
              control={control}
              defaultValue={bundle?.invoiceManagement || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.invoiceManagement")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="deliveryNote"
              control={control}
              defaultValue={bundle?.deliveryNote || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.deliveryNote")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="manageTasks"
              control={control}
              defaultValue={bundle?.manageTasks || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.manageTasks")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="productionControl"
              control={control}
              defaultValue={bundle?.productionControl || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.productionControl")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Controller
              name="isWorksheetAllowed"
              control={control}
              defaultValue={bundle?.isWorksheetAllowed || false}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControlLabel
                  className={classes.switch}
                  label={t("bundle.formValues.isWorksheetAllowed")}
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e, checked) => {
                        onChange(checked);
                      }}
                      checked={value}
                      inputRef={ref}
                      color="primary"
                    />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" m={2} gridGap={8}>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {bundle ? t("common:button.save") : t("common:button.create")}
          </Button>
        </Box>
      </Container>
    </>
  );
};
export default TenantBundleForm;
