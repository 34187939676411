import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import {
  Company,
  Issue,
  IssueType,
  Item,
  Project,
  TimeEntry,
  Tool,
  User,
} from "shared/types";

export type IssueRequest = {
  id?: number;
  tenantId?: number;
  project: Project | null;
  assignedUserId?: number | null;
  name: string;
  description: string;
  status: string;
  priority: string;
  parentIssueId: number | string | null;
  startDate: string;
  endDate: string;
  estimatedTime: number;
  completionPercentage: number;
  company: Company | null;
  responsibleUser: User | null;
  issueType: IssueType | null;
  items?: {
    item: Item | null;
    quantity?: string;
  }[];
  tools?: {
    tool: Tool | null;
  }[];
  users?: {
    user: User | null;
  }[];
  timeEntries?: TimeEntry[];
  spentTime: number;
  issueTemplateId: number | string | null;
};

const ENDPOINT = "/issue";

export const listIssues = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Issue>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //ISSUE_GET

export const createIssue = (issueEntry: IssueRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    issueEntry: issueEntry,
    relIssueItemEntries: issueEntry.items,
    relIssueToolEntries: issueEntry.tools,
    relIssueUserEntries: issueEntry.users,
    tenantId: tenantId,
  }); //ISSUE_ADMIN

export const getIssueById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Issue>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //ISSUE_GET

export const modifyIssue = (issueEntry: IssueRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, {
    issueEntry: issueEntry,
    relIssueItemEntries: issueEntry.items,
    relIssueToolEntries: issueEntry.tools,
    relIssueUserEntries: issueEntry.users,
    // [
    //   ...(issueEntry.users?.map((user) => {
    //     return {
    //       tenantId: tenantId,
    //       issueId: issueEntry.id,
    //       user: { id: user.user?.id },
    //       userType: "ASSIGNEE",
    //     };
    //   }) || []),
    // ],
    tenantId,
  }); //ISSUE_ADMIN

export const generateWorksheet = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/generate-basic-worksheet`, {
    param,
    tenantId,
  });
