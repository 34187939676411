import axios from "config/axios";
import { PermissionType } from "shared/types";

const ENDPOINT = "/rel-permission-permission-group";

export const relatePermissionPermissionGroup = (
  permissionList: PermissionType[],
  permissionGroupId: number,
  tenantId: number
) =>
  axios.post(ENDPOINT, {
    permissionList,
    permissionGroupId,
    tenantId,
  }); //PERMISSION_ADMIN
