import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { Employee } from "shared/types";
import CommentList from "views/Comment/CommentList";
import EmployeePermissionList from "./EmployeePermissionList";

type Props = {
  employee: Employee | null;
  loading: boolean;
};

const useStyles = makeStyles({
  title: {
    fontWeight: "bold",
  },
  data: {
    marginBottom: 14,
  },
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginBottom: 20,
  },
  dataHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
    width: "100%",
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 12,
    marginBottom: 20,
    width: "100%",
  },
});

const EmployeeData = ({ employee, loading }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.dataHeader}>
            <Typography variant="h2">{t("employee.data")}</Typography>
            <Button
              color="primary"
              variant="text"
              component={Link}
              to={`/employee-modify/${id}`}
              startIcon={<Edit />}
              style={{ height: 24 }}
            >
              {t("common:button.modify")}
            </Button>
          </Box>
          <Box className={classes.dataContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.name")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.expenseIdentifier")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.expenseIdentifier || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.birthName")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.birthName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.email")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.email || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("registration.formValues.phone")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.phone || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.tenant")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.tenant?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.entranceDate")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.entranceDate || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.taxCardNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.taxCardNumber || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.socialSecurityCardNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.socialSecurityCardNumber || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.identityCardNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.identityCardNumber || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.addressCardNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.addressCardNumber || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.birthdate")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.birthDate || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.mothersName")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.mothersName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.holidayCount")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.holidayCount || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.gender")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.gender
                    ? t(`common:gender.${employee?.gender}`)
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("userList.drivingLicenseNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.drivingLicenseNumber || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.dataHeader}>
            <Typography variant="h2">
              {t("employee.permanentAddress")}
            </Typography>
          </Box>
          <Box className={classes.dataContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.country")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.country || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.zipCode")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.zipCode || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.city")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.city || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaName")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.areaName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaType")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.areaType
                    ? t(
                        `common:areaType.${employee?.permanentAddress.areaType}`,
                      )
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.houseNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.permanentAddress.houseNumber || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.dataHeader}>
            <Typography variant="h2">
              {t("employee.temporaryAddress")}
            </Typography>
          </Box>
          <Box className={classes.dataContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.country")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.country || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.zipCode")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.zipCode || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.city")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.city || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaName")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.areaName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaType")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.areaType
                    ? t(
                        `common:areaType.${employee?.temporaryAddress?.areaType}`,
                      )
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.houseNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.temporaryAddress?.houseNumber || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.dataHeader}>
            <Typography variant="h2">{t("employee.postAddress")}</Typography>
          </Box>
          <Box className={classes.dataContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.country")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.country || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.zipCode")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.zipCode || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.city")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.city || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaName")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.areaName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.areaType")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.areaType
                    ? t(`common:areaType.${employee?.postAddress?.areaType}`)
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.title}>
                  {t("employee.address.houseNumber")}
                </Typography>
                <Typography className={classes.data}>
                  {employee?.postAddress?.houseNumber || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {account.user.isSuperAdmin &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "EMPLOYEE_ADMIN",
            ]) && <EmployeePermissionList employeeId={id} />}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMMENT_ADMIN",
          ]) &&
            !!id && <CommentList paramId={id} />}
        </>
      )}
    </Container>
  );
};

export default EmployeeData;
