import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

type Props = {
  open: boolean;
  onAbort?: () => void;
} & WithStyles<typeof styles>;

const styles = ({ zIndex }: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 2000,
    },
  });

const Loading = ({ classes, open, onAbort }: Props) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(open);
  }, [open]);

  return (
    <Backdrop className={classes.backdrop} open={state} invisible>
      <Box
        width="100%"
        height="100%"
        style={{
          backdropFilter: "blur(6px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="primary" size={48} />
      </Box>
    </Backdrop>
  );
};

export default withStyles(styles)(Loading);
