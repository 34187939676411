import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteBankAccount,
  listBankAccountByCompany,
  listBankAccountByTenant,
} from "shared/network/bank-account.api";

type Props = {
  id?: string | null;
  tenantId?: string | null;
};

const BankAccountList = ({ id, tenantId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const listBankAccountQuery = useQuery(
    ["listBankAccountQuery", id],
    async () => {
      if (id) {
        const { data } = await listBankAccountByCompany(id, tenant.id);
        return data;
      } else if (tenantId) {
        const { data } = await listBankAccountByTenant(
          Number.parseInt(tenantId)
        );
        return data;
      } else {
        const { data } = await listBankAccountByTenant(tenant.id);
        return data;
      }
    }
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("bankAccount.formValues.name"),
      flex: 2,
    },
    {
      field: "accountNumber",
      headerName: t("bankAccount.formValues.accountNumber"),
      flex: 2,
    },
    {
      field: "baseCurrency",
      headerName: t("bankAccount.formValues.currency"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.baseCurrency.name,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "BANK_ACCOUNT_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("bankAccount.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={`/bank-account-modify/${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("bankAccount.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => removeBankAccount(row.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeBankAccount(id: number) {
    try {
      await deleteBankAccount(id, selectedRelTenant.tenant.id);
      listBankAccountQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("bankAccount.subject"),
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("bankAccount.subject"),
        }),
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <Box mb={2.5}>
      <SingleQueryTable
        query={listBankAccountQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

export default BankAccountList;
