import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Comment } from "shared/types";

const ENDPOINT = "/comment";

export const createComment = (
  tenantId: number,
  type: string,
  subjectType: string,
  subjectId: string,
  note: string,

  file?: File | null
) => {
  const formData = new FormData();
  formData.set("type", type);
  formData.set("tenantId", tenantId.toString());
  formData.set("subjectId", subjectId);
  formData.set("subjectType", subjectType);
  formData.set("note", note);
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/with-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //COMMENT_ADMIN

export const deleteComment = () => {}; //COMMENT_ADMIN

export const listComment = (
  tenantId: number,
  subjectType: string,
  subjectId: number | string
) =>
  axios.post<GenericListResponse<Comment>>(`${ENDPOINT}/list-by-subject`, {
    tenantId,
    subjectType,
    subjectId,
  }); //COMMENT_GET

export const detailComment = () =>
  axios.post<GenericResponse<Comment>>(`${ENDPOINT}/valami`, {}); //COMMENT_ADMIN
