import axios from "config/axios";
import { Id, GenericResponse, GenericListResponse } from "shared/common";
import { Article } from "shared/types";

const ENDPOINT = "/article";

export const listArticles = (tenantId: number) =>
  axios.get<GenericListResponse<Article>>(`${ENDPOINT}?tenantId=${tenantId}`);

export const getArticleById = (id: Id, tenantId: number) =>
  axios.post<GenericResponse<Article>>(`${ENDPOINT}/get`, {
    param: id,
    tenantId,
  });

// a kikommentezett rész copy paszta
// export const getArticleByTypeAndSubjectId = (type: string, subjectId: Id) =>
//   axios.get<GenericResponse<Article>>(
//     `${ENDPOINT}/typeandsubject?type=${type}&subjectId=${subjectId}`
//   );

// export const getArticleByName = (name: string) =>
//   axios.get<GenericResponse<Article>>(`${ENDPOINT}/name?name=${name}`);

export const getTermsOfService = (tenantId: number) =>
  axios.get<GenericResponse<Article>>(`${ENDPOINT}/tos?tenantId=${tenantId}`);

export const getTermsOfServiceUnauthorized = () =>
  axios.get<GenericResponse<Article>>(`${ENDPOINT}/tos?tenantId=`);

export const getGeneralDataProtectionRegulation = (tenantId: number) =>
  axios.get<GenericResponse<Article>>(`${ENDPOINT}/gdpr?tenantId=${tenantId}`);

export const getGeneralDataProtectionRegulationUnauthorized = () =>
  axios.get<GenericResponse<Article>>(`${ENDPOINT}/gdpr?tenantId=`);

export const createArticle = (param: Partial<Article>, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifyArticle = (param: Partial<Article>, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

// export const getHelpArticles = (page = 0, size = 100) =>
//   axios.get<GenericPageResponse<Article>>(
//     `${ENDPOINT}/list-all-help?page=${page}&size=${size}`
//   );

// export const getHelpLoginRegisterArticles = (page = 0, size = 100) =>
//   axios.get<GenericPageResponse<Article>>(
//     `${ENDPOINT}/list-all-help-register?page=${page}&size=${size}`
//   );

// export const getCompanyTos = (companyId: string | number) =>
//   axios.get(`${ENDPOINT}/typeandsubject?type=TOS&subjectId=${companyId}`);
