import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Business,
  BusinessCenter,
  Description,
  Dvr,
  Equalizer,
  Home,
  HomeWork,
  ListAlt,
  LocalOffer,
  Note,
  SupervisorAccount,
} from "@material-ui/icons";
import { Link as RouterLink } from "components/Router";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { useEffect } from "react";
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  hasAnyAuthority,
  hasNoPermissionButHasCompany,
  hasPermissionButHasCompany,
} from "shared/authorization";
import { getSystemProperties } from "shared/network/properties.api";
import { getPageName } from "shared/util/getPageName";
import { ADMIN_TABS } from "views/Admin/AdminTabs";
import { TENANT_DETAILS_TABS } from "views/Admin/Tenants/TenantDetailsTabs";
import { COMPANY_DETAILS_TABS } from "views/Company/CompanyDetailsTabs/CompanyDetailsTabs";
import { COMPANY_TABS } from "views/Company/CompanyTabs";
import { EMPLOYEE_TABS } from "views/Employee/EmployeeDetailsTabs";
import { ISSUES_TABS } from "views/Issues/IssuesTabs";
import { ITEM_TABS } from "views/Items/ItemsTabs";
import { ORDER_TABS } from "views/Order/OrderTabs";
import { USER_TABS } from "views/User/MyCompanyTabs";
import { VEHICLE_TABS } from "views/Vehicle/VehicleDetailsTabs";
import { WAREHOUSE_TABS } from "views/Warehouse/WarehouseTabs";
import ItemIcon from "./icons/ItemIcon";
import ProfileMenu from "./ProfileMenu";
import SidebarItem from "./SidebarItem";
import { Link } from "react-router-dom";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  width: number;
  setWidth: Dispatch<SetStateAction<number>>;
};

const useStyles = makeStyles({
  drawer: {
    zIndex: 2,
    color: COLORS.white,
    background: GRADIENT,
    backgroundSize: "150%",
    border: "unset",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
});

const SideBar = ({ open, setOpen, width, setWidth }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [originalHome] = useState(true);
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorElProfile, setAnchorElProfile] = useState<HTMLElement | null>(
    null,
  );

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const versionQuery = useQuery("versionQuery", async () => {
    const { data } = await getSystemProperties();
    return data.items?.find(property => property.name === "version")?.value;
  });

  const handleClickProfile = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  useEffect(() => {
    if (!matchesMd && matchesSm) {
      setWidth(80);
    }
  }, [matchesMd, matchesSm, setWidth]);

  return (
    <>
      <ProfileMenu
        anchorElProfile={anchorElProfile}
        setOpen={setOpen}
        setAnchorElProfile={setAnchorElProfile}
      />
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        variant={matchesSm ? "permanent" : "temporary"}
        onMouseOver={() => {
          if (!matchesMd && matchesSm) {
            setWidth(200);
          }
        }}
        onMouseOut={() => {
          if (!matchesMd && matchesSm) {
            setWidth(80);
          }
        }}
        style={{ zIndex: 2 }}
        PaperProps={{
          className: classes.drawer,
          style: {
            width: matchesMd ? 200 : width,
            transition: "width 0.2s ease-in-out",
          },
        }}
      >
        <Box>
          <Button
            variant="text"
            component={RouterLink}
            to="/"
            onClick={() => setOpen(false)}
            style={{
              width: "100%",
              height: 35,
              color: COLORS.white,
              borderRadius: 0,
              marginTop: 16,
              marginBottom: 16,
              fontSize: width === 80 ? "2vw" : 24,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {getPageName(window.location.hostname)}
          </Button>
          <List style={{ padding: 12 }} onClick={() => setOpen(false)}>
            <SidebarItem
              to={`/home?original=${originalHome}`}
              text={t("home.title")}
              icon={<Home />}
              activeMenuItem={["/", "/home"]}
              width={width}
            />
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ITEM_STATISTIC_GET"],
            ) && (
              <SidebarItem
                to="/statistics"
                text={t("statistics.title")}
                icon={<Equalizer />}
                activeMenuItem={["/statistics"]}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              [
                "USER_ADMIN",
                "EMPLOYEE_GET",
                "EMPLOYEE_ADMIN",
                "WAREHOUSE_ADMIN",
                "WAREHOUSE_GET",
              ],
            ) && (
              <SidebarItem
                to="/my-company/users"
                text={t("drawer.my-company")}
                icon={<Business />}
                activeMenuItem={USER_TABS.concat(EMPLOYEE_TABS)
                  .concat(VEHICLE_TABS)
                  .concat([
                    "/user",
                    "/user-create",
                    "/user-modify",
                    "/employee-create",
                    "/employee-modify",
                    "/department-create",
                    "/department-modify",
                    "/absence-create",
                    "/vehicle-create",
                    "/vehicle-modify",
                    "/cargo-create",
                    "/tool-create",
                    "/tool-modify",
                    "/tool-details",
                    "/personal-data-create",
                    "/qualification-create",
                    "/employee-relate/",
                    "/salary-create",
                    "/salary-modify",
                  ])}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ITEM_GET", "ITEM_ADMIN", "CATEGORY_ADMIN"],
            ) && (
              <SidebarItem
                to={
                  hasAnyAuthority(
                    account.user,
                    account.permissions,
                    selectedRelTenant,
                    ["ITEM_GET", "ITEM_ADMIN"],
                  )
                    ? "/items/list"
                    : "/items/categories"
                }
                text={t("drawer.items")}
                icon={<ItemIcon />}
                activeMenuItem={ITEM_TABS.concat([
                  "/serial-item-create",
                  "/serial-item-modify",
                  "/item-create",
                  "/item-modify",
                  "/item/details",
                  "/item/serial-item-list",
                ])}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["COMPANY_GET", "COMPANY_ADMIN"],
            ) &&
              hasPermissionButHasCompany(account) && (
                <SidebarItem
                  to="/companies/list-all"
                  text={t("company.title")}
                  icon={<BusinessCenter />}
                  activeMenuItem={COMPANY_TABS.concat(
                    COMPANY_DETAILS_TABS,
                  ).concat([
                    "/company-create",
                    "/company-modify",
                    "/contact-create",
                    "/contact-modify",
                    "/contact-details",
                    "/companysite-create",
                    "/bank-account-create/",
                    "/bank-account-modify/",
                    "/company-item-create/",
                    "/invoice-create",
                    "/invoice-modify",
                    "/invoice-details",
                    "/incoming-invoice-create",
                    "/incoming-invoice-modify",
                    "/incoming-invoice-details",
                  ])}
                  width={width}
                />
              )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["OFFER_ADMIN", "OFFER_GET"],
            ) && (
              <SidebarItem
                to="/offer-list"
                text={t("offer.title")}
                icon={<LocalOffer />}
                activeMenuItem={[
                  "/offer-list",
                  "/offer-create",
                  "/offer-details",
                ]}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["CONTRACT_GET"],
            ) && (
              <SidebarItem
                to="/contracts"
                text={t("contract.title")}
                icon={<Description />}
                activeMenuItem={["/contracts"]}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["PROJECT_ADMIN", "PROJECT_GET"],
            ) && (
              <SidebarItem
                to="/project"
                text={t("project.title")}
                icon={<Dvr />}
                activeMenuItem={["/project"]}
                width={width}
              />
            )}
            {(hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ORDER_GET"],
            ) ||
              hasNoPermissionButHasCompany(account)) && (
              <SidebarItem
                to="/order"
                text={t("drawer.order")}
                icon={<ListAlt />}
                activeMenuItem={ORDER_TABS.concat([
                  "/order",
                  "/order-create",
                  "/order-modify",
                  "/order-details",
                ])}
                width={width}
              />
            )}
            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["VEHICLE_ADMIN", "VEHICLE_GET"],
            ) && (
              <SidebarItem
                to="/planning"
                text={t("drawer.planning")}
                icon={
                  <FontAwesomeIcon
                    icon={faTruckLoading}
                    style={{ width: 24, height: 24 }}
                  />
                }
                activeMenuItem={["/planning", "/inside-delivery-create"]}
                width={width}
              />
            )}

            {hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["WAREHOUSE_ADMIN", "WAREHOUSE_GET"],
            ) && (
              <SidebarItem
                to="/warehouse/list"
                text={t("drawer.warehouse")}
                icon={<HomeWork />}
                activeMenuItem={WAREHOUSE_TABS.concat([
                  "/warehouse-create",
                  "/warehouse-modify",
                  "/warehouse-scrap-create",
                  "/warehouse-details",
                  "/warehouse-transaction-create",
                ])}
                width={width}
              />
            )}

            {(hasAnyAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["ISSUE_GET", "ISSUE_ADMIN", "ISSUE_TEMPLATE_GET"],
            ) ||
              hasNoPermissionButHasCompany(account)) && (
              <SidebarItem
                to={
                  hasAnyAuthority(
                    account.user,
                    account.permissions,
                    selectedRelTenant,
                    ["ISSUE_GET"] || hasNoPermissionButHasCompany(account),
                  )
                    ? "/issues/all"
                    : "/issues/templates"
                }
                text={t("issues.title")}
                icon={<Note />}
                activeMenuItem={ISSUES_TABS.concat([
                  "/issue-type-create",
                  "/custom-fields-create",
                  "/custom-fields-modify",
                  "/issue-template-create",
                  "/issue-template-modify",
                  "/issue-template-details",
                  "/issue-create",
                  "/issue-modify",
                  "/issue-details",
                  "/time-entry-create",
                  "/time-entry-list",
                ])}
                width={width}
              />
            )}
            {(account?.user?.isSuperAdmin ||
              selectedRelTenant?.isTenantAdmin) && (
              <SidebarItem
                to={
                  account?.user?.isSuperAdmin
                    ? "/admin/bundles"
                    : "/admin/currencies"
                }
                text={t("drawer.admin")}
                icon={<SupervisorAccount />}
                activeMenuItem={ADMIN_TABS.concat(
                  TENANT_DETAILS_TABS.concat([
                    "/bundle-create",
                    "/bundle-modify",
                    "/bundle-details",
                    "/subscription-create",
                    "/tenant-create",
                    "/tenant-modify",
                    "/currency-create",
                    "/currency-modify",
                  ]),
                )}
                width={width}
              />
            )}
          </List>
        </Box>
        <List style={{ padding: 4 }}>
          <SidebarItem
            onClick={handleClickProfile}
            text={account.user.name}
            icon={<Avatar>{account.user.name[0].toUpperCase()}</Avatar>}
            width={width}
          />

          <Typography
            style={{
              textAlign: "center",
              fontWeight: 300,
              color: COLORS.white,
              fontSize: "0.875rem",
              margin: 4,
            }}
          >
            {t("login.version", { version: versionQuery.data })}
          </Typography>
          <Box style={{ textAlign: "center" }}>
            <Typography
              component={Link}
              to="/tos"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 300,
              }}
            >
              {t("article.tos")}
            </Typography>
            {t("comma")}
            {t("space")}
            <Typography
              component={Link}
              to="/gdpr"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 300,
              }}
            >
              {t("article.gdpr")}
            </Typography>
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
