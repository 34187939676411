import { MenuItem, Popover } from "@material-ui/core";
import { RootState } from "config/store";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectTenant } from "shared/reducers/authentication";
import { RelUserTenants } from "shared/types";

type Props = {
  anchorElTenant: HTMLElement | null;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleCloseProfileMenu: () => void;
  handleCloseTenantMenu: () => void;
};

const TenantChooserPopover = ({
  anchorElTenant,
  setOpen,
  handleCloseProfileMenu,
  handleCloseTenantMenu,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const account = useSelector(
    (state: RootState) => state.authentication.account
  );

  const select = (tenant: RelUserTenants) => {
    setOpen(false);
    dispatch(selectTenant(tenant));
    handleCloseTenantMenu();
    handleCloseProfileMenu();

    history.push("/");
  };

  return (
    <Popover
      anchorEl={anchorElTenant}
      keepMounted
      open={!!anchorElTenant}
      onClose={handleCloseTenantMenu}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      {account.user.relUserTenants.map((relTenant) => (
        <MenuItem key={relTenant?.id} onClick={() => select(relTenant)}>
          {relTenant?.tenant?.name}
        </MenuItem>
      ))}
    </Popover>
  );
};

export default TenantChooserPopover;
