import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { HeaderProvider } from "components/Layout/HeaderContext";
import Layout from "components/Layout/Layout";
import LayoutLoading from "components/LayoutLoading";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getSystemProperties } from "shared/network/properties.api";
import {
  fetchAccount,
  finishInitializing,
  logout,
} from "shared/reducers/authentication";
import { Property } from "shared/types";
import { saveToken, tokenExists } from "shared/util/authToken";
import { getPageName } from "shared/util/getPageName";

//const ChatView = lazy(() => import("views/Chat/ChatView"));

const InventoryCreate = lazy(
  () => import("views/Warehouse/Inventory/InventoryCreate"),
);
const InsideDeliveryCreate = lazy(
  () => import("views/Delivery/InsideDeliveryCreate"),
);
const DeliveryPointList = lazy(
  () => import("views/DeliveryPoint/DeliveryPointList"),
);
const DeliveryRoundCreate = lazy(
  () => import("views/DeliveryRound/DeliveryRoundCreate"),
);
const DeliveryRoundModify = lazy(
  () => import("views/DeliveryRound/DeliveryRoundModify"),
);
const MailView = lazy(() => import("views/Mail/MailView"));

const Tos = lazy(() => import("views/Tos"));
const Gdpr = lazy(() => import("views/Gdpr"));
const ListArticle = lazy(() => import("views/Admin/Article/ListArticle"));
const CreateArticle = lazy(
  () => import("views/Admin/Article/Create/CreateArticle"),
);
const ModifyArticle = lazy(
  () => import("views/Admin/Article/Modify/ModifyArticle"),
);
const Admin = lazy(() => import("views/Admin/AdminTabs"));
const BundleCreate = lazy(() => import("views/Admin/Bundles/BundleCreate"));
const BundleDetails = lazy(() => import("views/Admin/Bundles/BundleDetails"));
const BundleModify = lazy(() => import("views/Admin/Bundles/BundleModify"));
const CompanyCategoryCreate = lazy(
  () => import("views/Admin/CompanyCategory/CompanyCategoryCreate"),
);
const IssueDetailsPage = lazy(
  () => import("views/Issues/IssueDetails/IssueDetailsPage"),
);
const CurrencyCreate = lazy(
  () => import("views/Admin/Currency/CurrencyCreate"),
);
const CurrencyModify = lazy(
  () => import("views/Admin/Currency/CurrencyModify"),
);
const TenantCreate = lazy(() => import("views/Admin/Tenants/TenantCreate"));
const TenantDetailsTab = lazy(
  () => import("views/Admin/Tenants/TenantDetailsTabs"),
);
const UploadTenantIcon = lazy(
  () => import("views/Admin/Tenants/TenantIcon/UploadTenantIcon"),
);
const TenantModify = lazy(() => import("views/Admin/Tenants/TenantModify"));
const TenantProfile = lazy(
  () => import("views/Admin/Tenants/TenantProfileTabs"),
);
const CompanyCreate = lazy(() => import("views/Company/CompanyCreate"));
const BankAccountCreate = lazy(
  () =>
    import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountCreate"),
);
const BankAccountModify = lazy(
  () =>
    import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountModify"),
);
const CompanyDetailsTab = lazy(
  () => import("views/Company/CompanyDetailsTabs/CompanyDetailsTabs"),
);
const CompanyModify = lazy(() => import("views/Company/CompanyModify"));
const CompanySite = lazy(() => import("views/Company/CompanySite/CompanySite"));
const CompanySiteCreate = lazy(
  () => import("views/Company/CompanySite/CompanySiteCreate"),
);
const Company = lazy(() => import("views/Company/CompanyTabs"));
const ContactCreate = lazy(() => import("views/Company/Contact/ContactCreate"));
const ContactDetails = lazy(
  () => import("views/Company/Contact/ContactDetails"),
);
const ContactModify = lazy(() => import("views/Company/Contact/ContactModify"));
const RelCompanyItemCreate = lazy(
  () => import("views/Company/relItemCompany/RelCompanyItemCreate"),
);
const DepartmentCreate = lazy(
  () => import("views/Department/DepartmentCreate"),
);
const DepartmentList = lazy(() => import("views/Department/DepartmentList"));
const DepartmentModify = lazy(
  () => import("views/Department/DepartmentModify"),
);
const CreatePersonalData = lazy(
  () => import("views/Employee/CreatePersonalData"),
);
const EmployeeRegistration = lazy(
  () => import("views/Employee/EmployeeCreate"),
);
const EmployeeDetails = lazy(
  () => import("views/Employee/EmployeeDetailsTabs"),
);
const EmployeeModify = lazy(() => import("views/Employee/EmployeeModify"));
const EmployeeToEmployee = lazy(
  () => import("views/Employee/EmployeeToEmployee"),
);
const ForgottenPassword = lazy(
  () => import("views/ForgottenPassword/ForgottenPassword"),
);
const ResetPassword = lazy(
  () => import("views/ForgottenPassword/ResetPassword"),
);
const Home = lazy(() => import("views/Home/Home"));
const IncomingInvoiceCreate = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceCreate"),
);
const IncomingInvoiceDetails = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceDetails"),
);
const IncomingInvoiceModify = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceModify"),
);
const InvoiceCreate = lazy(() => import("views/Invoice/InvoiceCreate"));
const InvoiceDetails = lazy(() => import("views/Invoice/InvoiceDetails"));
const InvoiceModify = lazy(() => import("views/Invoice/InvoiceModify"));
const CustomFieldCreate = lazy(
  () => import("views/Issues/CustomFields/CustomFieldCreate"),
);
const CustomFieldModify = lazy(
  () => import("views/Issues/CustomFields/CustomFieldModify"),
);
const IssueCreate = lazy(() => import("views/Issues/IssueCreate"));
const IssueModify = lazy(() => import("views/Issues/IssueModify"));
const Issues = lazy(() => import("views/Issues/IssuesTabs"));
const IssueTemplateCreate = lazy(
  () => import("views/Issues/IssueTemplate/IssueTemplateCreate"),
);
const IssueTemplateDetails = lazy(
  () => import("views/Issues/IssueTemplate/IssueTemplateDetails"),
);
const IssueTemplateList = lazy(
  () => import("views/Issues/IssueTemplate/IssueTemplateList"),
);
const IssueTemplateModify = lazy(
  () => import("views/Issues/IssueTemplate/IssueTemplateModify"),
);
const IssueTypeCreate = lazy(
  () => import("views/Issues/IssueType/IssueTypeCreate"),
);
const IssueTypeModify = lazy(
  () => import("views/Issues/IssueType/IssueTypeModify"),
);
const ItemCreate = lazy(() => import("views/Items/ItemCreate/ItemCreate"));
const ItemDetailsTabs = lazy(
  () => import("views/Items/ItemDetails/ItemDetailsTabs"),
);
const ItemModify = lazy(() => import("views/Items/ItemModify/ItemModify"));
const Items = lazy(() => import("views/Items/ItemsTabs"));
const SerialItemCreate = lazy(
  () => import("views/Items/SerialItems/SerialItemCreate"),
);
const SerialItemModify = lazy(
  () => import("views/Items/SerialItems/SerialItemModify"),
);
const Login = lazy(() => import("views/Login/Login"));
const Logout = lazy(() => import("views/Logout/Logout"));
const OfferCreate = lazy(() => import("views/Offer/OfferCreate"));
const OfferDetails = lazy(() => import("views/Offer/OfferDetails"));
const OrderCreate = lazy(() => import("views/Order/OrderCreate"));
const OrderDetails = lazy(() => import("views/Order/OrderDetails"));
const OrderListByStatus = lazy(() => import("views/Order/OrderListByStatus"));
const OrderModify = lazy(() => import("views/Order/OrderModify"));
const PageNotFound = lazy(() => import("views/PageNotFound"));
const Profile = lazy(() => import("views/Profile/Profile"));
const ProjectCreate = lazy(() => import("views/Project/ProjectCreate"));
const ProjectDetailsTabs = lazy(
  () => import("views/Project/ProjectDetailsTabs"),
);
const ProjectList = lazy(() => import("views/Project/ProjectList"));
const ProjectModify = lazy(() => import("views/Project/ProjectModify"));
const QualificationCreate = lazy(
  () => import("views/Qualifications/QualificationCreate"),
);
const SalaryCreate = lazy(() => import("views/Salary/SalaryCreate"));
const SalaryList = lazy(() => import("views/Salary/SalaryList"));
const SalaryModify = lazy(() => import("views/Salary/SalaryModify"));
const Statistics = lazy(() => import("views/Statistics/Statistics"));
const SubscripitonCreate = lazy(
  () => import("views/Subscription/SubscriptionCreate"),
);
const TimeEntryCreate = lazy(() => import("views/TimeEntry/TimeEntryCreate"));
const ToolCreate = lazy(() => import("views/Tools/ToolCreate"));
const ToolDetails = lazy(() => import("views/Tools/ToolDetails"));
const ToolModify = lazy(() => import("views/Tools/ToolModify"));
const ToolTabs = lazy(() => import("views/Tools/ToolTabs"));
const AbsenceCreate = lazy(() => import("views/User/Absence/AbsenceCreate"));
const MyCompanyTabs = lazy(() => import("views/User/MyCompanyTabs"));
const SignUpFinish = lazy(() => import("views/User/UserCreate/SignUpFinish"));
const UserRegistration = lazy(() => import("views/User/UserCreate/UserCreate"));
const UserDetails = lazy(() => import("views/User/UserDetails"));
const UserModify = lazy(() => import("views/User/UserModify"));
const CargoCreate = lazy(() => import("views/Vehicle/Cargo/CargoCreate"));
const Planning = lazy(() => import("views/Vehicle/Planning/Planning"));
const VehicleCreate = lazy(() => import("views/Vehicle/VehicleCreate"));
const VehicleDetailsTabs = lazy(
  () => import("views/Vehicle/VehicleDetailsTabs"),
);
const VehicleModify = lazy(() => import("views/Vehicle/VehicleModify"));
const VehicleTabs = lazy(() => import("views/Vehicle/VehicleTabs"));
const WarehouseTransactionCreate = lazy(
  () => import("views/Warehouse/Transaction/WarehouseTransactionCreate"),
);
const WarehouseCreate = lazy(() => import("views/Warehouse/WarehouseCreate"));
const WarehouseDetailsTab = lazy(
  () => import("views/Warehouse/WarehouseDetailsTabs"),
);
const WarehouseModify = lazy(() => import("views/Warehouse/WarehouseModify"));
const WarehouseScrapCreate = lazy(
  () => import("views/Warehouse/WarehouseScrapCreate"),
);
const WarehouseTabs = lazy(() => import("views/Warehouse/WarehouseTabs"));
const OfferList = lazy(() => import("views/Offer/OfferList"));
const ContractList = lazy(() => import("views/Contract/ContractList"));
const ContractCreate = lazy(() => import("views/Contract/ContractCreate"));
const ContractDetails = lazy(() => import("views/Contract/ContractDetails"));
const ContractModify = lazy(() => import("views/Contract/ContractModify"));

function getCookie(name: string) {
  return document.cookie
    ?.split("; ")
    ?.find(row => row.startsWith(name))
    ?.split("=")[1];
}

export default function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { account, selectedRelTenant, initialized } = useSelector(
    (state: RootState) => state.authentication,
  );

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (tokenExists()) {
      dispatch(fetchAccount());
    } else {
      dispatch(finishInitializing());
    }
  }, [dispatch]);

  useEffect(() => {
    const authToken = getCookie("auth_token");

    if (authToken) {
      saveToken(authToken);
      dispatch(fetchAccount());
    }
  }, [dispatch]);

  const versionQueryMainApp = useQuery(
    "versionQueryMainApp",
    async () => {
      const { data } = await getSystemProperties();
      return data.items;
    },
    { onSuccess: checkVersion },
  );
  const version = versionQueryMainApp.data?.find(
    property => property.name === "version",
  )?.value;

  useEffect(() => {
    const interval = setInterval(() => {
      versionQueryMainApp.refetch();
    }, 600000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  function checkVersion(data: Property[]) {
    const currentVersion = data.find(
      property => property.name === "version",
    )?.value;
    const localVersion = localStorage.getItem("version");
    if (localVersion !== currentVersion) {
      setReload(true);
    }
  }

  function reloadSite() {
    setReload(false);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("version", version || "");
    dispatch(logout());
    window.location.reload();
  }

  if (!initialized) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="column"
        >
          {getPageName(window.location.hostname)}
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Dialog open={reload}>
        <DialogTitle>{t("common:versionCheckTitle")}</DialogTitle>
        <DialogContent>{t("common:versionCheckMessage")}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reloadSite();
            }}
          >
            {t("common:button.reload")}
          </Button>
        </DialogActions>
      </Dialog>
      <HeaderProvider>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={supportedLocales[i18n.language]}
        >
          <Suspense fallback={<LayoutLoading />}>
            <Helmet>
              <title>{getPageName(window.location.hostname)}</title>
            </Helmet>
            <Layout>
              <Switch>
                <Route exact path="/reset" component={ForgottenPassword} />
                <Route path="/reset/finish/:token" component={ResetPassword} />
                <Route path="/login" component={Login} />
                <Route path="/user/activate" component={SignUpFinish} />
                <PrivateRoute exact path={"/"} component={Home} />
                <PrivateRoute exact path={"/home"} component={Home} />
                {(account?.user?.isSuperAdmin ||
                  selectedRelTenant?.isTenantAdmin) && (
                  <PrivateRoute
                    path="/currency-create"
                    component={CurrencyCreate}
                  />
                )}
                {(account?.user?.isSuperAdmin ||
                  selectedRelTenant?.isTenantAdmin) && (
                  <PrivateRoute
                    path="/currency-modify/:id"
                    component={CurrencyModify}
                  />
                )}
                <PrivateRoute
                  path="/salary"
                  component={SalaryList}
                  permissions={["SALARY_GET"]}
                />
                <PrivateRoute
                  path="/salary-create/:id"
                  component={SalaryCreate}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                <PrivateRoute
                  path="/salary-modify/:id"
                  component={SalaryModify}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                <PrivateRoute path="/logout" component={Logout} />
                <PrivateRoute
                  path="/department"
                  component={DepartmentList}
                  permissions={["DEPARTRMENT_GET"]}
                />
                <PrivateRoute
                  path="/department-create"
                  component={DepartmentCreate}
                  permissions={["DEPARTRMENT_ADMIN"]}
                />
                <PrivateRoute
                  path="/department-modify/:tenantId/:id"
                  component={DepartmentModify}
                  permissions={["DEPARTRMENT_ADMIN"]}
                />
                <PrivateRoute
                  path="/qualification-create/:id"
                  component={QualificationCreate}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                <PrivateRoute
                  path="/my-company"
                  component={MyCompanyTabs}
                  permissions={["USER_ADMIN", "WAREHOUSE_ADMIN"]}
                />
                <PrivateRoute
                  path="/user/:id"
                  component={UserDetails}
                  permissions={["USER_ADMIN"]}
                />
                <PrivateRoute
                  path="/employee-relate/:createType/:employeeId"
                  component={EmployeeToEmployee}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                <PrivateRoute
                  path="/project-details/:id"
                  component={ProjectDetailsTabs}
                  permissions={["PROJECT_ADMIN", "PROJECT_GET"]}
                />
                <PrivateRoute
                  path="/personal-data-create/:id"
                  component={CreatePersonalData}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                {/* COMPANY */}
                <PrivateRoute
                  path="/companysite"
                  component={CompanySite}
                  permissions={["COMPANY_GET"]}
                />
                <PrivateRoute
                  path="/companysite-create/:id?"
                  component={CompanySiteCreate}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/company"
                  component={CompanyDetailsTab}
                  permissions={["COMPANY_GET"]}
                />
                <PrivateRoute
                  path="/companies"
                  component={Company}
                  permissions={["COMPANY_GET"]}
                />
                <PrivateRoute
                  path="/company-create"
                  component={CompanyCreate}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/company-modify/:id"
                  component={CompanyModify}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/company-category-create"
                  component={CompanyCategoryCreate}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/contact-create"
                  component={ContactCreate}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/contact-modify/:id"
                  component={ContactModify}
                  permissions={["COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/contact-details/:id"
                  component={ContactDetails}
                  permissions={["COMPANY_ADMIN"]}
                />
                {/* USER */}

                <PrivateRoute
                  path="/user-create"
                  component={UserRegistration}
                  permissions={["USER_ADMIN"]}
                />
                <PrivateRoute
                  path="/user-modify/:id"
                  component={UserModify}
                  permissions={["USER_ADMIN"]}
                />
                {/* ABSENCE */}
                <PrivateRoute
                  path="/absence-create"
                  component={AbsenceCreate}
                  permissions={["ABSENCE_ADMIN"]}
                />
                {/* EMPLOYEE */}
                <PrivateRoute
                  path="/employee-create"
                  component={EmployeeRegistration}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                <PrivateRoute
                  path="/employee"
                  component={EmployeeDetails}
                  permissions={["EMPLOYEE_GET"]}
                />
                <PrivateRoute
                  path="/employee-modify/:id"
                  component={EmployeeModify}
                  permissions={["EMPLOYEE_ADMIN"]}
                />
                {/*DELIVERY_ROUND & DELIVERY_POINT*/}
                <PrivateRoute
                  path="/deliveryround-create"
                  component={DeliveryRoundCreate}
                  permissions={["DELIVERY_ROUND_ADMIN"]}
                />
                <PrivateRoute
                  path="/deliveryround-modify/:id"
                  component={DeliveryRoundModify}
                  permissions={["DELIVERY_ROUND_ADMIN"]}
                />
                <PrivateRoute
                  path="/deliverypoint-list"
                  component={DeliveryPointList}
                  permissions={["DELIVERY_ROUND_GET"]}
                />
                {/* WAREHOUSE */}
                <PrivateRoute
                  path="/warehouse"
                  component={WarehouseTabs}
                  permissions={["WAREHOUSE_GET"]}
                />
                <PrivateRoute
                  path="/warehouse-create"
                  component={WarehouseCreate}
                  permissions={["WAREHOUSE_ADMIN"]}
                />
                <PrivateRoute
                  path="/warehouse-modify/:id"
                  component={WarehouseModify}
                  permissions={["WAREHOUSE_ADMIN"]}
                />
                <PrivateRoute
                  path="/warehouse-scrap-create/:id"
                  component={WarehouseScrapCreate}
                  permissions={["WAREHOUSE_ADMIN"]}
                />
                <PrivateRoute
                  path="/warehouse-scrap-create/"
                  component={WarehouseScrapCreate}
                  permissions={["WAREHOUSE_ADMIN"]}
                />
                <PrivateRoute
                  path="/warehouse-details/:id"
                  component={WarehouseDetailsTab}
                  permissions={["WAREHOUSE_GET"]}
                />
                {/* ISSUE*/}
                <PrivateRoute
                  path="/time-entry-create/:id?"
                  component={TimeEntryCreate}
                  permissions={["ISSUE_GET"]}
                />
                <PrivateRoute
                  path="/issue-details/:id"
                  component={IssueDetailsPage}
                  permissions={["ISSUE_GET"]}
                />
                <PrivateRoute
                  path="/issues"
                  component={Issues}
                  permissions={["ISSUE_GET"]}
                />
                <PrivateRoute
                  path="/issue-create"
                  component={IssueCreate}
                  permissions={["ISSUE_ADMIN"]}
                />
                <PrivateRoute
                  path="/issue-modify/:id"
                  component={IssueModify}
                  permissions={["ISSUE_ADMIN"]}
                />
                {/* ISSUE TEMPLATE*/}
                <PrivateRoute
                  path="/issue-template"
                  component={IssueTemplateList}
                  permissions={["ISSUE_TEMPLATE_GET"]}
                />
                <PrivateRoute
                  path="/issue-template-create"
                  component={IssueTemplateCreate}
                  permissions={["ISSUE_TEMPLATE_ADMIN"]}
                />
                <PrivateRoute
                  path="/issue-template-modify/:id"
                  component={IssueTemplateModify}
                  permissions={["ISSUE_TEMPLATE_ADMIN"]}
                />
                <PrivateRoute
                  path="/issue-template-details/:id"
                  component={IssueTemplateDetails}
                  permissions={["ISSUE_TEMPLATE_GET"]}
                />
                {/*PROJECT */}
                <PrivateRoute
                  path="/project"
                  component={ProjectList}
                  permissions={["PROJECT_ADMIN"]}
                />
                <PrivateRoute
                  path="/project-create"
                  component={ProjectCreate}
                  permissions={["PROJECT_ADMIN"]}
                />
                <PrivateRoute
                  path="/project-modify/:id"
                  component={ProjectModify}
                  permissions={["PROJECT_ADMIN"]}
                />
                {/* ADMIN */}
                {(account?.user?.isSuperAdmin ||
                  selectedRelTenant?.isTenantAdmin) && (
                  <PrivateRoute path="/admin" component={Admin} />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/bundle-create"
                    component={BundleCreate}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/bundle-modify/:id"
                    component={BundleModify}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/bundle-details/:id"
                    component={BundleDetails}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/tenant-create"
                    component={TenantCreate}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute path="/tenant" component={TenantDetailsTab} />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/tenant-modify/:id"
                    component={TenantModify}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/tenant-upload-icon/:id"
                    component={UploadTenantIcon}
                  />
                )}
                {account?.user?.isSuperAdmin && (
                  <PrivateRoute
                    path="/subscription-create"
                    component={SubscripitonCreate}
                  />
                )}

                {/* TOOL */}
                <PrivateRoute
                  path="/tools"
                  component={ToolTabs}
                  permissions={["TOOL_GET"]}
                />
                <PrivateRoute
                  path="/tool-create"
                  component={ToolCreate}
                  permissions={["TOOL_ADMIN"]}
                />
                <PrivateRoute
                  path="/tool-modify/:id"
                  component={ToolModify}
                  permissions={["TOOL_ADMIN"]}
                />
                <PrivateRoute
                  path="/tool-details/:id"
                  component={ToolDetails}
                  permissions={["TOOL_GET"]}
                />
                {/* ITEM */}
                <PrivateRoute
                  path="/item-create"
                  component={ItemCreate}
                  permissions={["ITEM_ADMIN"]}
                />
                <PrivateRoute
                  path="/items"
                  component={Items}
                  permissions={["ITEM_GET"]}
                />
                <PrivateRoute
                  path="/item"
                  component={ItemDetailsTabs}
                  permissions={["ITEM_GET"]}
                />
                <PrivateRoute
                  path="/item-modify/:id"
                  component={ItemModify}
                  permissions={["ITEM_ADMIN"]}
                />
                <PrivateRoute
                  path="/serial-item-create"
                  component={SerialItemCreate}
                  permissions={["ITEM_ADMIN"]}
                />
                <PrivateRoute
                  path="/serial-item-modify"
                  component={SerialItemModify}
                  permissions={["ITEM_ADMIN"]}
                />
                {/* BANK ACCOUNT */}
                <PrivateRoute
                  exact
                  path="/bank-account-create/"
                  component={BankAccountCreate}
                  permissions={["BANK_ACCOUNT_ADMIN"]}
                />
                <PrivateRoute
                  path="/bank-account-create/:from/:id"
                  component={BankAccountCreate}
                  permissions={["BANK_ACCOUNT_ADMIN"]}
                />
                <PrivateRoute
                  path="/bank-account-modify/:id"
                  component={BankAccountModify}
                  permissions={["BANK_ACCOUNT_ADMIN"]}
                />
                {/*STATISTICS*/}
                <PrivateRoute
                  path="/statistics"
                  component={Statistics}
                  permissions={["ITEM_STATISTIC_GET"]}
                />
                {/*PLANNING*/}
                <PrivateRoute
                  path="/planning"
                  component={Planning}
                  permissions={["VEHICLE_ADMIN", "VEHICLE_GET"]}
                />
                {/*VEHICLE*/}
                <PrivateRoute
                  path="/vehicle"
                  component={VehicleTabs}
                  permissions={["VEHICLE_ADMIN", "VEHICLE_GET"]}
                />
                <PrivateRoute
                  path="/vehicle-create"
                  component={VehicleCreate}
                  permissions={["VEHICLE_ADMIN"]}
                />
                <PrivateRoute
                  path="/vehicle-modify/:id"
                  component={VehicleModify}
                  permissions={["VEHICLE_ADMIN"]}
                />
                <PrivateRoute
                  path="/vehicle-details/:id"
                  component={VehicleDetailsTabs}
                  permissions={["VEHICLE_GET"]}
                />
                {/*OFFER*/}
                <PrivateRoute
                  path="/offer-list"
                  component={OfferList}
                  permissions={["OFFER_ADMIN", "OFFER_GET"]}
                />
                <PrivateRoute
                  path="/offer-create"
                  component={OfferCreate}
                  permissions={["OFFER_ADMIN", "PROJECT_ADMIN"]}
                />
                <PrivateRoute
                  path="/offer-details/:id"
                  component={OfferDetails}
                  permissions={["OFFER_GET"]}
                />
                {/*CONTRACT*/}
                <PrivateRoute
                  path="/contracts"
                  component={ContractList}
                  permissions={["CONTRACT_GET"]}
                />
                <PrivateRoute
                  path="/contract-create"
                  component={ContractCreate}
                  permissions={["CONTRACT_GET"]}
                />
                <PrivateRoute
                  path="/contract-modify"
                  component={ContractModify}
                  permissions={["CONTRACT_GET"]}
                />
                <PrivateRoute
                  path="/contract-details"
                  component={ContractDetails}
                  permissions={["CONTRACT_GET"]}
                />
                {/*ORDER*/}
                <PrivateRoute
                  path="/order-create/:id"
                  component={OrderCreate}
                  permissions={["ORDER_ADMIN"]}
                />
                {/*INSIDE DELIVERY*/}
                <PrivateRoute
                  path="/inside-delivery-create"
                  component={InsideDeliveryCreate}
                  permissions={["DELIVERY_ADMIN"]}
                />
                {/*TRANSACTION*/}
                <PrivateRoute
                  path="/warehouse-transaction-create/:id/:from"
                  component={WarehouseTransactionCreate}
                  permissions={["TRANSACTIONS_ADMIN"]}
                />
                <PrivateRoute
                  path="/invoice-create"
                  component={InvoiceCreate}
                  permissions={["INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/order"
                  component={OrderListByStatus}
                  permissions={["ORDER_GET"]}
                />
                <PrivateRoute
                  path="/order-create"
                  component={OrderCreate}
                  permissions={["ORDER_ADMIN"]}
                />
                <PrivateRoute
                  path="/order-modify/:id"
                  component={OrderModify}
                  permissions={["ORDER_ADMIN"]}
                />
                <PrivateRoute
                  path="/order-details/:id"
                  component={OrderDetails}
                  permissions={["ORDER_GET"]}
                />

                <PrivateRoute
                  path="/order-list"
                  component={OrderListByStatus}
                  permissions={["ORDER_GET", "ORDER_ADMIN"]}
                />

                <PrivateRoute
                  path="/invoice-details/:id"
                  component={InvoiceDetails}
                  permissions={["INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/issue-type-create/:id?"
                  component={IssueTypeCreate}
                  permissions={["ISSUE_TYPE_ADMIN"]}
                />
                <PrivateRoute
                  path="/issue-type-modify/:id"
                  component={IssueTypeModify}
                  permissions={["ISSUE_TYPE_ADMIN"]}
                />
                <PrivateRoute
                  path="/invoice-modify/:id"
                  component={InvoiceModify}
                  permissions={["INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/cargo-create/:from/:id"
                  component={CargoCreate}
                  permissions={["CARGO_ADMIN"]}
                />
                {/* EMAIL VIEW*/}
                <PrivateRoute path="/mail-view" component={MailView} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute
                  path="/tenant-profile"
                  component={TenantProfile}
                />
                <PrivateRoute
                  path="/company-item-create/:id"
                  component={RelCompanyItemCreate}
                  permissions={["REl_ITEM_COMPANY_ADMIN"]}
                />
                <PrivateRoute
                  path="/incoming-invoice-create/"
                  component={IncomingInvoiceCreate}
                  permissions={["INCOMING_INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/incoming-invoice-details/:id"
                  component={IncomingInvoiceDetails}
                  permissions={["INCOMING_INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/incoming-invoice-modify/:id"
                  component={IncomingInvoiceModify}
                  permissions={["INCOMING_INVOICE_ADMIN"]}
                />
                <PrivateRoute
                  path="/custom-fields-create"
                  component={CustomFieldCreate}
                  permissions={["CUSTOM_FIELDS_ADMIN"]}
                />
                <PrivateRoute
                  path="/custom-fields-modify/:id"
                  component={CustomFieldModify}
                  permissions={["CUSTOM_FIELDS_ADMIN"]}
                />
                <PrivateRoute exact path="/articles" component={ListArticle} />
                <PrivateRoute
                  exact
                  path="/article-create"
                  component={CreateArticle}
                />
                <PrivateRoute
                  path="/article-modify/:id"
                  component={ModifyArticle}
                />
                <PrivateRoute
                  path="/inventory-create/:id"
                  component={InventoryCreate}
                />
                <Route path="/tos" component={Tos} />
                <Route path="/gdpr" component={Gdpr} />
                <Route component={PageNotFound} />
              </Switch>
              {/* CHAT ABLAK*/}
              {/*!!sessionStorage.getItem("@rap_software_auth_token") && (
                <ChatView />
              )*/}
            </Layout>
          </Suspense>
        </MuiPickersUtilsProvider>
      </HeaderProvider>
    </>
  );
}
