import axios from "config/axios";
import { Employee, Permission, User } from "shared/types";
import { GenericPageResponse, GenericResponse, HttpResponse } from "../common";

export type UserRequest = {
  id: number;
  name: string;
  email: string;
  isSuperAdmin: boolean;
  isTenantAdmin: boolean;
};

export type GetAccountResponse = {
  user: User;
  permissions: Permission[];
};

const ENDPOINT = "/user";

export const userLogin = (
  email: string,
  password: string,
  rememberMe: boolean
) =>
  axios.post<{ item: string } & HttpResponse>(`${ENDPOINT}/login`, {
    email,
    password,
    rememberMe,
  });

export const listUsers = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.post<GenericPageResponse<User>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //USER_ADMIN

export const getUserById = (tenantId: number, param: number | string) =>
  axios.post<{ user: User; employee: Employee }>(`${ENDPOINT}/get-by-id`, {
    tenantId,
    param,
  }); //USER_ADMIN

export const getAccount = () =>
  axios.get<GetAccountResponse>(`${ENDPOINT}/account`); //ACCOUNT

export const registerUser = (
  param: UserRequest,
  companyId: string | number,
  tenantId: number
) =>
  axios.post(
    `${ENDPOINT}/create${companyId ? `?companyId=${companyId}` : ""}`,
    {
      param,
      companyId,
      tenantId,
    }
  ); //USER_ADMIN

export const modifyUser = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //ACCOUNT

export const modifyPassword = (
  param: { oldPassword: string; newPassword: string; email: string },
  tenantId: number
) =>
  axios.post(`${ENDPOINT}/password`, {
    ...param,
    tenantId,
  }); //ACCOUNT

export const activateUser = (password: string, token: string | null) =>
  axios.put(`${ENDPOINT}/activate`, { password, token }); //nincs

export const forgottenPasswordStart = (data: string) =>
  axios.post<GenericResponse<String>>(`${ENDPOINT}/forgotten`, {
    param: data,
  }); //nincs

export const forgottenPasswordFinish = (data: {
  newPassword: string;
  newPassword2: string;
  token: string | null;
}) => axios.post(`${ENDPOINT}/forgotten/finish`, data); //nincs

export const setHomePage = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/home-page`, { param, tenantId });

export const postLogout = () => axios.post(`${ENDPOINT}/logout`); //login required
