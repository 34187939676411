import axios from "config/axios";
import { ItemCategory } from "shared/types";
import { GenericListResponse } from "shared/common";

const ENDPOINT = "/category";

export const createItemCategory = (
  tenantId: number,
  param: {
    name: string;
    parentId?: number;
  }
) => axios.post(ENDPOINT, { param, tenantId }); //CATEGORY_ADMIN

export const modifyItemCategory = (param: ItemCategory, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId }); //CATEGORY_ADMIN

export const deleteItemCategory = (param: number, tenantId: number) =>
  axios.delete(ENDPOINT, { data: { param, tenantId } }); //CATEGORY_ADMIN

export const listItemCategory = (tenantId: number, param?: number) =>
  axios.post<GenericListResponse<ItemCategory>>(`${ENDPOINT}/all-by-parent`, {
    param,
    tenantId,
  }); //CATEGORY_ADMIN

export const getCategoriesByItemId = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<ItemCategory>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //CATEGORY_GET

export const getAllCategories = (tenantId: number) =>
  axios.post<GenericListResponse<ItemCategory>>(`${ENDPOINT}/all`, {
    tenantId,
  }); //CATEGORY_GET
