import axios from "config/axios";
import { GenericListResponse } from "shared/common";
import { PersonalData } from "shared/types";

const ENDPOINT = "/personal-data";

export const createEmployeePersonalData = (
  type: string,
  employeeId: string,
  tenantId: string,
  comment: string | null,
  status: string,
  validTo: string,
  file?: File
) => {
  const formData = new FormData();
  formData.set("type", type);
  formData.set("employeeId", employeeId);
  formData.set("tenantId", tenantId);
  comment && formData.set("comment", comment);
  formData.set("status", status);
  formData.set("validTo", validTo);
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/create`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //EMPLOYEE_ADMIN

export const listEmployeePersonalData = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<PersonalData>>(
    `${ENDPOINT}/list-by-employee`,
    { param, tenantId }
  ); //EMPLOYEE_ADMIN
