import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import DeliveryList from "views/Delivery/DeliveryList";
import VehicleDetails from "./VehicleDetails";
import VehicleItems from "./Cargo/VehicleItems";
import { hasAuthority } from "shared/authorization";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { useEffect } from "react";
import { getVehicleById } from "shared/network/vehicle.api";
import { useHeader } from "components/Layout/HeaderContext";

export const VEHICLE_TABS = [
  "/vehicle-details/details",
  "/vehicle-details/delivery",
  "/vehicle-details/items",
];

const VehicleDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderName } = useHeader();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );

  const vehicleQuery = useQuery(["getVehicleDetailsQuery"], async () => {
    if (id) {
      const { data } = await getVehicleById(
        Number.parseInt(id),
        selectedRelTenant.tenant.id
      );

      return data.item;
    }
    return Promise.reject();
  });

  useEffect(() => {
    if (vehicleQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (vehicleQuery.data?.licensePlateNumber) {
      setHeaderName(
        `${vehicleQuery.data?.licensePlateNumber} ${t(
          "common:details.vehicle"
        )}`
      );
    }
    return () => {
      setHeaderName(null);
    };
  }, [
    setHeaderName,
    t,
    vehicleQuery.data?.licensePlateNumber,
    vehicleQuery.isFetching,
  ]);

  return (
    <Container maxWidth="xl">
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_GET",
          "VEHICLE_ADMIN",
        ]) && (
          <Tab
            label={t("vehicle.tabs.data")}
            component={Link}
            to={`${VEHICLE_TABS[0]}?id=${id}`}
            value={VEHICLE_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("vehicle.tabs.delivery")}
            component={Link}
            to={`${VEHICLE_TABS[1]}?id=${id}`}
            value={VEHICLE_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_GET",
          "VEHICLE_ADMIN",
        ]) && (
          <Tab
            label={t("vehicle.tabs.items")}
            component={Link}
            to={`${VEHICLE_TABS[2]}?id=${id}`}
            value={VEHICLE_TABS[2]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={VEHICLE_TABS[0]}
            component={() => (
              <VehicleDetails
                vehicle={vehicleQuery.data || null}
                loading={vehicleQuery.isFetching}
              />
            )}
            permissions={["VEHICLE_GET", "VEHICLE_ADMIN"]}
          />
          <PrivateRoute
            path={VEHICLE_TABS[1]}
            component={DeliveryList}
            permissions={["DELIVERY_ADMIN", "DELIVERY_GET"]}
          />
          <PrivateRoute
            path={VEHICLE_TABS[2]}
            component={VehicleItems}
            permissions={["VEHICLE_GET", "VEHICLE_ADMIN"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default VehicleDetailsTabs;
