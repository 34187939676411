import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import {
  AddBox,
  Assignment,
  Block,
  CheckCircleOutline,
  Done,
  Edit,
  PermIdentity,
} from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { USER_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getEmployeeListPageable } from "shared/network/employee.api";
import { listUsers } from "shared/network/user.api";
import { TableState } from "views/Company/CompanyList/CompanyList";

const EmployeeList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-employee-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-employee-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listEmployeePageableQuery = useQuery(
    [
      "listEmployeePageableQuery",
      page,
      pageSize,
      filterValue,
      sortState,
      selectedRelTenant.tenant.id,
    ],
    async () => {
      const { data } = await getEmployeeListPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  async function goToUser(employee: GridRowData) {
    const { data } = await listUsers(
      0,
      1000,
      selectedRelTenant.tenant.id,
      `email:${encodeURIComponent(employee.email)}`,
    );

    const user = data?.page?.content?.[0] || undefined;

    if (user?.id) {
      history.push(`/user-modify/${user.id}`);
    } else {
      history.push(
        `/user-create?name=${employee.name}&email=${encodeURIComponent(
          employee.email,
        )}`,
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      width: 120,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`userList.status.${row.status}`).toString()}>
          {row.status === "ACTIVE" ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : row.status === "NEEDS_ACTIVATION" ? (
            <Done />
          ) : (
            <Block style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
      type: "select",
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Tooltip title={t("employee.addUser").toString()}>
              <div>
                <IconButton
                  size="small"
                  disabled={!!row.relUserCompany?.length}
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => goToUser(row)}
                >
                  <PermIdentity />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_GET",
          ]) && (
            <Tooltip title={t("employee.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/employee/details?id=${row.id}`}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Tooltip title={t("employee.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/employee-modify/${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/employee-create"
            variant="contained"
            startIcon={<AddBox />}
            style={{ margin: "auto" }}
          >
            {t("employee.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/employee/details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      filterOptions={[
        {
          columnName: "status",
          options: USER_STATUS.map(value => {
            return {
              translated: t(`userList.status.${value}`),
              value,
            };
          }),
        },
      ]}
      filterable={["name", "email"]} //ha majd backenden jó, akkor ezt a sort ki kell szedni
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="employee"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listEmployeePageableQuery}
      onCellClick={onCellClick}
    />
  );
};

export default EmployeeList;
