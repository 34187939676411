import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Property } from "shared/types";

const ENDPOINT = "/properties";

export const getSystemProperties = () =>
  axios.get<GenericListResponse<Property>>(`${ENDPOINT}/getSystemProperties`); //NOT_REQUIRED

export const getAllProperties = (tenantId: number) =>
  axios.post<GenericListResponse<Property>>(`${ENDPOINT}/all`, {
    tenantId: tenantId,
  }); //SUPER_ADMIN

export const getPropertyByName = (name: string) =>
  axios.get<GenericResponse<Property>>(`${ENDPOINT}/byName?name=${name}`); //SUPER_ADMIN

export const modifyProperty = (data: Property, tenantId: number) =>
  axios.put(ENDPOINT, { param: data, tenantId: tenantId }); //SUPER_ADMIN
