import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { BankAccount, BaseCurrency } from "shared/types";

const ENDPOINT = "/bank-account";

export const createBankAccount = (
  param: {
    baseCurrency: BaseCurrency;
    name: string;
    accountNumber: string;
    company?: { id: number };
  },
  tenantId: number
) => axios.post(`${ENDPOINT}/create`, { param, tenantId }); //BANK_ACCOUNT_ADMIN

export const modifyBankAccount = (param: BankAccount, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify`, { param, tenantId }); //BANK_ACCOUNT_ADMIN

export const deleteBankAccount = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/delete`, { param, tenantId }); //BANK_ACCOUNT_ADMIN

export const listBankAccountByTenant = (tenantId: number) =>
  axios.post<GenericListResponse<BankAccount>>(`${ENDPOINT}/list-by-tenant`, {
    tenantId,
  }); //BANK_ACCOUNT_GET

export const listBankAccountByCompany = (
  param: number | string,
  tenantId: number
) =>
  axios.post<GenericListResponse<BankAccount>>(`${ENDPOINT}/list-by-company`, {
    param,
    tenantId,
  }); //BANK_ACCOUNT_GET

export const getBankAccountById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<BankAccount>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //BANK_ACCOUNT_GET
