import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { listCompanyCategoryByCompany } from "shared/network/company-category.api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import RelCompanyCategoryForm from "./RelCompanyCategoryForm";
import { FormProvider, useForm } from "react-hook-form";
import { RelCompanyCategory } from "shared/types";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import {
  createRelCompanyCategoryCompany,
  deleteRelCompanyCategoryCompany,
} from "shared/network/rel-company-category-company";
import { AddBox } from "@material-ui/icons";
import Loading from "components/Loading";
import { hasAuthority } from "shared/authorization";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

const RelCompanyCategoryList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const relCompanyCategoryList = useQuery(
    ["listRelCompanyCategoryCompany", tenant.id, id],
    async () => {
      if (id) {
        const { data } = await listCompanyCategoryByCompany(tenant.id, id);
        return data;
      }
      return Promise.reject();
    }
  );

  const form = useForm<RelCompanyCategory>();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitCreate = async (values: RelCompanyCategory) => {
    try {
      if (id && values.companyCategory) {
        await createRelCompanyCategoryCompany(
          [
            {
              companyCategoryIds: [values.companyCategory.id],
              companyId: id,
            },
          ],
          tenant.id
        );
        enqueueSnackbar("Sikeres kategória hozzárendelés.", {
          variant: "success",
        });
        relCompanyCategoryList.refetch();
        setOpen(false);
      }
    } catch {
      enqueueSnackbar("Sikertelen kategória hozzárendelés.", {
        variant: "error",
      });
    }
  };

  async function removeCompanyCategory(relCompanyCategoryId: number) {
    try {
      if (id) {
        await deleteRelCompanyCategoryCompany(
          [
            {
              companyCategoryIds: [relCompanyCategoryId],
              companyId: id,
            },
          ],
          tenant.id
        );
      }
      relCompanyCategoryList.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("companyCategory.subject"),
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("companyCategory.subject"),
        }),
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <>
      <Box className={classes.cardHeader}>
        <Typography variant="h2">
          {t("relCompanyCategory.companyCategory")}
        </Typography>
        <Grid container item xs={6} justifyContent="flex-end">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              variant="text"
              style={{ marginBottom: 8 }}
              onClick={() => setOpen(true)}
              startIcon={<AddBox />}
            >
              {t("relCompanyCategory.create")}
            </Button>
          )}
        </Grid>
      </Box>
      <Box>
        <Loading open={relCompanyCategoryList.isFetching} />
        {relCompanyCategoryList.isError ? (
          <Box style={{ margin: "20px auto", textAlign: "center" }}>
            <Typography
              variant="h5"
              align="center"
              color="secondary"
              style={{ marginBottom: "20px" }}
            >
              {t("common:errorWhileFetching")}
            </Typography>
          </Box>
        ) : (
          <>
            {relCompanyCategoryList.data?.items.length === 0 ? (
              <Grid item xs={12} className={classes.cardContainer}>
                <Typography className={classes.text}>
                  {t("relCompanyCategory.noCategory")}
                </Typography>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {relCompanyCategoryList.data?.items.length &&
                  relCompanyCategoryList.data.items.map((companyCategory) => (
                    <Grid item xs={6} container key={companyCategory.id}>
                      <Box
                        className={classes.cardContainer}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography className={classes.text}>
                          {companyCategory.name}
                        </Typography>
                        <Tooltip
                          title={t("companyCategory.delete").toString()}
                          className={classes.cell}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            style={{ margin: "0 8px" }}
                            onClick={() => {
                              if (companyCategory.id) {
                                removeCompanyCategory(companyCategory.id);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        )}
      </Box>
      <Dialog open={open}>
        <DialogTitle>{t("relCompanyCategory.create")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <RelCompanyCategoryForm setOpen={setOpen} />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RelCompanyCategoryList;
