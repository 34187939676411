import axios from "config/axios";
import { Absence } from "shared/types";
import { GenericListResponse, GenericPageResponse } from "shared/common";

const ENDPOINT = "/absence";

export type AbsenceEntry = {
  type: string;
  instantFrom: string;
  instantTo: string;
  isOrdered: boolean;
};

export const createAbsence = (
  absenceList: AbsenceEntry[],
  tenantId: number,
  employeeId: number | string,
) => axios.post(`${ENDPOINT}/create`, { absenceList, tenantId, employeeId }); //ABSENCE_ADMIN

export const modifyAbsence = (
  absenceList: {
    id: number;
    tenantId: number;
    type: string;
    dateFrom: string;
    dateTo: string;
    isOrdered: boolean;
  }[],
  tenantId: number,
  employeeId: number | string,
) => axios.post(`${ENDPOINT}/modify`, { absenceList, tenantId, employeeId }); //ABSENCE_ADMIN

export const listAbsenceByDate = (
  tenantId: number,
  dateFrom: string,
  dateTo: string,
  employeeId?: number,
) =>
  axios.post<GenericListResponse<Absence>>(
    `${ENDPOINT}/list-by-date-interval`,
    { tenantId, dateFrom, dateTo, employeeId },
  ); //ABSENCE_GET

export const listAllAbsence = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Absence>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //ABSENCE_GET

export const deleteAbsence = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete`, { data: { param, tenantId } }); //ABSENCE_ADMIN
