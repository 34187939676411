import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { SliceStatus } from "shared/common";
import { modifyDelivery } from "shared/network/delivery.api";
import { listItems } from "shared/network/item.api";
import { BaseCurrency, Delivery, Item, RelItemDelivery } from "shared/types";
import DeliveryItemsRow from "./DeliveryItemRow";

const useStyles = makeStyles({
  header: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    marginTop: 8,
  },
  newItem: {
    marginTop: 20,
    marginBottom: 10,
  },
  footy: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  bodyMain: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
  bodyCollapse: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
});

export type Props = {
  selectedDelivery: Delivery | null;
  openId: string | number | null;
  setOpenId: Dispatch<SetStateAction<string | number | null>>;
  refetch: () => void;
};

export type DeliveryDateFormValues = {
  date: Date;
};

export type DeliveryItemFormValues = {
  item: Item | null;
  quantity: number | string | null;
  price: number | string | null;
};

const DeliveryItemDialog = ({
  selectedDelivery,
  openId,
  setOpenId,
  refetch,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const [addItemState, setAddItemState] = useState(false);
  const form = useForm<DeliveryItemFormValues>();
  const setValue = form.setValue;
  const getValues = form.getValues;
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<SliceStatus>("idle");
  const [itemPrice, setItemPrice] = useState("");
  const [itemCurrency, setItemCurrency] = useState<BaseCurrency>();
  const relItemList = selectedDelivery?.relItemDeliveryItems;

  const itemListQuery = useQuery(
    ["itemList", selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listItems(
        0,
        1000000,
        selectedRelTenant.tenant.id,
        "purchasable:true",
      );
      return data.page;
    },
  );

  const onSubmitModify = async (values: DeliveryItemFormValues) => {
    try {
      setStatus("pending");
      if (selectedDelivery && values.item && values.quantity && values.price) {
        await modifyDelivery(
          {
            deliveryEntry: selectedDelivery,
            relList: [
              ...(relItemList?.map(item => {
                return { ...item, deliveryId: selectedDelivery.id };
              }) || []),
              {
                item: values.item,
                quantity: values.quantity,
                price: values.price,
                deliveryId: selectedDelivery.id,
              },
            ],
          },
          selectedRelTenant.tenant.id,
        );
        setStatus("success");
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("deliveryItem.subject"),
          }),
          {
            variant: "success",
          },
        );
        setValue("item", null);
        setValue("quantity", "");
        setAddItemState(false);
        refetch();
        setOpenId(openId);
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = e as any;
      if (error.message === "quantity-not-number") {
        form.setError(
          "quantity",
          new Error(t("common:validation.numberFormat").toString()),
        );
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "error" },
        );
      }
      setStatus("failure");
    }
  };

  const onCloseModify = async (values: DeliveryItemFormValues) => {
    setValue("item", null);
    setValue("quantity", "");
    setValue("price", "");
    setItemPrice("");
    setItemCurrency(undefined);
    form.clearErrors();
    setAddItemState(false);
  };

  useEffect(() => {
    if (itemPrice) {
      setValue("price", itemPrice);
    }
  }, [itemPrice, setValue]);

  return (
    <Dialog
      open={openId?.toString() === selectedDelivery?.id?.toString()}
      onClose={() => {
        setOpenId(null);
        onCloseModify(getValues());
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>{t("delivery.itemListTitle")}</DialogTitle>
      <DialogContent>
        {!selectedDelivery?.relItemDeliveryItems?.length ? (
          <Box display="flex" justifyContent="center">
            <Typography className={classes.text}>
              {t("delivery.noItem")}
            </Typography>
          </Box>
        ) : (
          <>
            <Loading open={status === "pending"} />
            <Grid
              container
              style={{ padding: "8px 0" }}
              className={classes.header}
            >
              <Grid item xs={3} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("item.name")}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("item.productCode")}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("delivery.quantityTitle")}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("delivery.priceTitle")}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.bodyMain}>
              {selectedDelivery?.relItemDeliveryItems?.map(
                (relItem: RelItemDelivery) => (
                  <DeliveryItemsRow
                    key={relItem.id}
                    deliveryItem={{
                      ...relItem,
                      deliveryId: selectedDelivery.id,
                    }}
                    deliveryStatus={selectedDelivery.status}
                    refetchVechileAndDate={refetch}
                    refetch={itemListQuery.refetch}
                  />
                ),
              )}
            </Grid>
          </>
        )}
        <Collapse
          in={addItemState}
          className={classes.bodyCollapse}
          style={{
            paddingLeft: addItemState ? 8 : 0,
            paddingRight: addItemState ? 8 : 0,
          }}
        >
          <form>
            <FormProvider {...form}>
              <Grid container className={classes.newItem} spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="item"
                    defaultValue={null}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        options={itemListQuery?.data?.content || []}
                        getOptionLabel={(option: Item) => {
                          if (option.name && option.productCode) {
                            return `${option.name} (${option.productCode})`;
                          }
                          return "";
                        }}
                        getOptionSelected={option =>
                          option.id === field.value?.id
                        }
                        onChange={(_, value) => {
                          field.onChange(value);
                          if (value?.itemPriceHistories) {
                            setItemPrice(
                              value.itemPriceHistories[
                                value.itemPriceHistories.length - 1
                              ]?.price?.toString(),
                            );
                          }
                          if (value?.sellPriceCurrency) {
                            setItemCurrency(value.sellPriceCurrency);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t(
                              "delivery.formValues.itemNameOrProductNumber",
                            )}
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="quantity"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                      validate: value => {
                        if (value && !value.toString().match(NUMBER_FORMAT)) {
                          return t("common:validation.numberFormat").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("delivery.formValues.quantity")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="price"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("delivery.formValues.priceNetPerPiece")}
                        InputLabelProps={{ shrink: true, required: true }}
                        InputProps={{
                          endAdornment: itemCurrency?.postfix?.toString(),
                          startAdornment: itemCurrency?.prefix?.toString(),
                        }}
                        disabled
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={1} container justifyContent="center">
                  <Tooltip title={t("delivery.formValues.addItem").toString()}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ width: 30, height: 30, padding: 0 }}
                      onClick={() => {
                        onSubmitModify(getValues());
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t("delivery.formValues.deleteItem").toString()}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ width: 30, height: 30, padding: 0 }}
                      onClick={() => {
                        onCloseModify(getValues());
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </FormProvider>
          </form>
        </Collapse>
        <Grid
          container
          style={{
            padding: "8px 0",
            borderTop: addItemState ? `1px solid ${COLORS.mainGrey}` : 0,
          }}
          justifyContent="center"
          className={classes.footy}
        >
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              size="small"
              color="primary"
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: 30,
                background: COLORS.white,
              }}
              variant="outlined"
              startIcon={<AddBoxOutlined />}
              disabled={addItemState}
              onClick={() => {
                setAddItemState(true);
              }}
            >
              {t("delivery.addDeliveryItem").toString()}
            </Button>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenId(null);
            onCloseModify(getValues());
          }}
          variant="text"
        >
          {t("common:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryItemDialog;
