import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";

const DEFAULT_COLORS = {
  main: "#172C40",
  mainLight: "#14A3D9",
  mainGrey: "#E7EDF1",
  red: "#BA3727",
  green: "#14d9ad",
  white: "#FFFFFF",
  shadowLight: "rgba(0, 0, 0, 0.06)",
  shadowDark: "rgba(0, 0, 0, 0.1)",
  greyWater: "#5C5F58",
  lighterGrey: "#DCE1E3",
  mintGreenLight: "#D4F3EC",
  lightGrey: "#959595",
  lightRed: "#f7e3e1",
};

const RORA_COLORS = {
  main: "#172C40",
  mainLight: "#14A3D9",
  mainGrey: "#E7EDF1",
  red: "#BA3727",
  green: "#14d9ad",
  white: "#FFFFFF",
  shadowLight: "rgba(0, 0, 0, 0.06)",
  shadowDark: "rgba(0, 0, 0, 0.1)",
  greyWater: "#5C5F58",
  lighterGrey: "#DCE1E3",
  mintGreenLight: "#D4F3EC",
  lightGrey: "#959595",
  lightRed: "#f7e3e1",
};

const SCHPS_COLORS = {
  main: "#002a9b",
  mainLight: "#0094D6",
  mainGrey: "#E5F4FA",
  red: "#BA3727",
  green: "#14d9ad",
  white: "#FFFFFF",
  shadowLight: "rgba(0, 0, 0, 0.06)",
  shadowDark: "rgba(0, 0, 0, 0.1)",
  greyWater: "#5C5F58",
  lighterGrey: "#DCE1E3",
  mintGreenLight: "#D4F3EC",
  lightGrey: "#959595",
  lightRed: "#f7e3e1",
};

export const COLORS = window.location.hostname.includes("rajosszikviz")
  ? RORA_COLORS
  : window.location.hostname.includes("sch-ps")
  ? SCHPS_COLORS
  : DEFAULT_COLORS;

export const BOX_SHADOW = `${COLORS.shadowLight} 0px 4px 6px -1px, ${COLORS.shadowLight} 0px 2px 4px -1px`;

export const GRADIENT = `linear-gradient(45deg, ${COLORS.main} 0%, ${COLORS.mainLight} 100%)`;

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.main,
      light: COLORS.mainLight,
    },
    secondary: {
      main: COLORS.greyWater,
      light: COLORS.lighterGrey,
    },
    background: {
      default: COLORS.white,
    },
    error: {
      main: COLORS.red,
    },
    success: {
      main: COLORS.green,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        a: {
          color: COLORS.main,
        },
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "Montserrat",
        fontSize: "30px",
        fontWeight: "bold",
      },
      h2: {
        fontFamily: "Montserrat",
        fontSize: "22px",
        fontWeight: "bold",
      },
      h3: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "bold",
      },
      h6: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
      },
      body1: {
        fontFamily: "Roboto",
        fontSize: "0.875rem",
      },
      body2: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiChip: {
      outlined: {
        color: COLORS.mainLight,
        border: `1px solid ${COLORS.mainLight}`,
        boxShadow: BOX_SHADOW,
      },
      deleteIcon: {
        color: COLORS.mainLight,
        "&:hover": {
          color: COLORS.red,
        },
      },
      colorPrimary: {
        backgroundColor: COLORS.mainLight,
      },
      label: {
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiButton: {
      root: {
        boxShadow: "unset",
        minWidth: "max-content",
        borderRadius: 8,
      },
      label: {
        whiteSpace: "nowrap",
      },
    },
    MuiSwitch: {
      root: {
        "& + .MuiFormControlLabel-label": {
          margin: "0 4px",
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: BOX_SHADOW,
        border: `1px solid ${COLORS.mainGrey}`,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
  },
  props: {
    MuiTextField: {
      margin: "none",
      fullWidth: true,
      variant: "outlined",
      size: "small",
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
    MuiCardHeader: {
      titleTypographyProps: {
        variant: "h2",
      },
    },
  },
});

export default theme;
