import axios from "config/axios";
import { CompositeItem, Item } from "shared/types";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { ItemFormValues } from "views/Items/components/ItemForm";

const ENDPOINT = "/item";

export const itemCreate = (tenantId: number, param: ItemFormValues) =>
  axios.post<GenericResponse<Item>>(ENDPOINT, {
    tenantId,
    item: param.item,
    itemTaxHistoryEntry: param.itemTaxHistoryEntry,
    serviceDetails: param.serviceDetails,
    itemPriceHistoryEntry: param.itemPriceHistoryEntry,
    categoryIds: param.categories?.map((item) => item.id),
  }); //ITEM_ADMIN

export const itemModify = (tenantId: number, param: ItemFormValues) =>
  axios.put(ENDPOINT, { ...param, tenantId }); //ITEM_ADMIN

export const listItems = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  categoryName: string = ""
) =>
  axios.post<GenericPageResponse<Item>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&categoryName=${categoryName}`
  ); //ITEM_GET

export const getItemById = (tenantId: number, param: string) =>
  axios.post<{ item: Item }>(`${ENDPOINT}/byId`, {
    tenantId,
    param,
  }); //ITEM_GET

export const getCompositeItemsById = (tenantId: number, param: string) =>
  axios.post<GenericListResponse<CompositeItem>>(
    `${ENDPOINT}/get-building-blocks`,
    {
      tenantId,
      param,
    }
  ); //ITEM_GET

export const addCompositeItems = (tenantId: number, params: CompositeItem[]) =>
  axios.post(`${ENDPOINT}/add-building-blocks-to-composite`, {
    params,
    tenantId,
  }); //ITEM_ADMIN

export const deleteCompositeItem = (tenantId: number, param: string) =>
  axios.delete(`/rel-item-item`, { data: { param, tenantId } });

export const addItemCategory = (
  categoryId: number,
  itemId: string,
  tenantId: number
) => axios.post(`${ENDPOINT}/add-category`, { categoryId, itemId, tenantId }); //ITEM_ADMIN

export const removeItemCategory = (
  categoryId: number,
  itemId: string,
  tenantId: number
) =>
  axios.post(`${ENDPOINT}/delete-category`, { categoryId, itemId, tenantId }); //ITEM_ADMIN
