import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Packaging } from "shared/types";

const ENDPOINT = "/packaging";

export const createPackaging = (param: Packaging, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifyPackaging = (param: Packaging, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getPackaging = (id: number, tenantId: number) =>
  axios.get<GenericResponse<Packaging>>(
    `${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`,
  );

export const listPackaging = (
  page: number,
  size: number,
  tenantId: string | number,
) =>
  axios.get<GenericPageResponse<Packaging>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}`,
  );
