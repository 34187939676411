import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  addToolToEmployee,
  removeToolToEmployee,
} from "shared/network/employee.api";
import { getToolsByEmployeeId, listTools } from "shared/network/tools.api";
import { Tool } from "shared/types";
import { TableState } from "views/Company/CompanyList/CompanyList";

const EmployeeTool = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { control, handleSubmit, reset } = useForm<{
    selectedTool: Tool | null;
  }>({});

  const [open, setOpen] = useState(false);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-employee-tool-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-employee-tool-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const avaibleToolsQuery = useQuery(
    ["listToolsQuery", tenant.id],
    async () => {
      const { data } = await listTools(
        0,
        100000,
        tenant.id,
        `isActive:true;isHandedOut:false;`,
      );
      return data;
    },
  );
  const avaibleToolsList = avaibleToolsQuery.data?.page.content;

  const listToolsByEmployeeIdQuery = useQuery(
    [
      "listToolsByEmployeeIdQuery",
      page,
      pageSize,
      filterValue,
      tenant.id,
      sortState,
    ],
    async () => {
      if (id) {
        const { data } = await getToolsByEmployeeId(
          page,
          pageSize,
          id,
          tenant.id,
          filterValue,
          sortState,
        );
        return data;
      }
      return Promise.reject();
    },
    { enabled: true },
  );

  async function create(values: { selectedTool: Tool | null }) {
    setSaveInProgress(true);
    try {
      if (id && values.selectedTool) {
        await addToolToEmployee(id, [values.selectedTool], tenant.id);
        listToolsByEmployeeIdQuery.refetch();
        avaibleToolsQuery.refetch();
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("tool.subject"),
          }),
          {
            variant: "success",
          },
        );
        reset();
        setOpen(false);
        setSaveInProgress(false);
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("tool.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function deleteItem(toolId?: number) {
    try {
      if (id && toolId) {
        await removeToolToEmployee(id, toolId, tenant.id);
      }
      listToolsByEmployeeIdQuery.refetch();
      avaibleToolsQuery.refetch();
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("tool.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("tool.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ABSENCE_ADMIN",
          "ABSENCE_GET",
          "EMPLOYEE_GET",
        ]) && (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<AddBox />}
          >
            {t("tool.linkToEmployee")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  const columns: GridColDef[] = [
    {
      field: "tool.serialNumber",
      headerName: t("tool.formValues.serialNumber"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.tool?.serialNumber,
    },
    {
      field: "tool.name",
      headerName: t("tool.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.tool?.name,
    },
    {
      field: "fromDate",
      headerName: t("tool.formValues.fromDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.fromDate), "yyyy. MM. dd."),
    },
    {
      field: "toDate",
      headerName: t("tool.formValues.toDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.toDate ? format(new Date(row.toDate), "yyyy. MM. dd.") : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          alignItems="center"
        >
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) &&
            !row.toDate && (
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => deleteItem(row.tool.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <PageableTable
          sortState={sortState}
          filterable={["serialNumber", "name"]}
          setSortState={setSortState}
          sessionStorageKey="employee-tool"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={listToolsByEmployeeIdQuery}
        />
      </Container>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>{t("tool.linkToEmployee")}</DialogTitle>
        <DialogContent>
          <Loading open={saveInProgress} />
          <form id="toolSelectForm" onSubmit={handleSubmit(create)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="selectedTool"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={avaibleToolsList || []}
                      getOptionLabel={(option: Tool) =>
                        `${option.name} (${option.serialNumber})`
                      }
                      getOptionSelected={option =>
                        option.id === field.value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("tool.selectedTool")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("common:button.close")}
          </Button>
          <Button type="submit" form="toolSelectForm" disabled={saveInProgress}>
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeTool;
