import axios from "config/axios";
import { AuditLog } from "shared/types";
import { GenericPageResponse } from "shared/common";

const ENDPOINT = "/audit-log";

export const listAuditLogPageable = (
  page: number,
  size: number,
  tenantId: number,
  filters: string
) =>
  axios.get<GenericPageResponse<AuditLog>>(
    `${ENDPOINT}?page=${page}&size=${size}&tenantId=${tenantId}&search=${filters}`
  ); //AUDITLOG_GET

export const listAuditLogByTenantPageable = (
  page: number,
  size: number,
  tenantId: number,
  filters: string
) =>
  axios.get<GenericPageResponse<AuditLog>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${filters}`
  ); //AUDITLOG_GET
