import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import {
  AddBox,
  Block,
  CheckCircleOutline,
  Delete,
  Done,
} from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listSubordinatesOfSuperiorEmployee } from "shared/network/employee.api";
import { deleteRelEmployeeEmployee } from "shared/network/rel-employee-employee.api";

const SubordinatesOfSuperiors = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const subordinatesQuery = useQuery(["subordinateListQuery"], async () => {
    if (id) {
      const { data } = await listSubordinatesOfSuperiorEmployee(tenant.id, id);
      return data;
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.subordinateEmployee.name || "-",
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.subordinateEmployee.email || "-",
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          title={t(
            `userList.status.${row.subordinateEmployee.status}`,
          ).toString()}
        >
          {row.subordinateEmployee.status === "ACTIVE" ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : row.subordinateEmployee.status === "NEEDS_ACTIVATION" ? (
            <Done />
          ) : (
            <Block style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Tooltip
              title={t("relEmployeeEmployee.deleteSubordinate").toString()}
            >
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  if (row?.id) {
                    removeRelEmployeeEmployee(row.id);
                  }
                }}
              >
                <Delete color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  async function removeRelEmployeeEmployee(id: number) {
    try {
      await deleteRelEmployeeEmployee(id, tenant.id);
      subordinatesQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("relEmployeeEmployee.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("relEmployeeEmployee.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to={`/employee-relate/subordinate-to-superior/${id}`}
            startIcon={<AddBox />}
          >
            {t("relEmployeeEmployee.createSubordinate")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={subordinatesQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};

export default SubordinatesOfSuperiors;
