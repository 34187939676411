import {
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  deleteQualification,
  listQualifications,
} from "shared/network/qualification.api";

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginTop: 8,
  },
});

const QualificationList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const listQualificationsQuery = useQuery(
    ["listQualificationsQuery"],
    async () => {
      if (id) {
        const { data } = await listQualifications(id, tenant.id);
        return data;
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("qualification.formValues.name"),
      flex: 1,
    },
    {
      field: "timeOfObtaining",
      headerName: t("qualification.formValues.timeOfObtaining"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.timeOfObtaining), "P", { locale: hu }),
    },
    {
      field: "placeOfObtaining",
      headerName: t("qualification.formValues.placeOfObtaining"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip
            title={t("qualification.delete").toString()}
            className={classes.text}
          >
            <IconButton
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
              onClick={() => {
                if (row.id) {
                  removeQualification(row.id);
                }
              }}
            >
              <Delete color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  async function removeQualification(id: number) {
    try {
      await deleteQualification(id, tenant.id);
      listQualificationsQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("qualification.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("qualification.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button
          component={Link}
          to={`/qualification-create/${id}`}
          startIcon={<AddIcon />}
        >
          {t("qualification.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={listQualificationsQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};

export default QualificationList;
