import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listWarehousePageable } from "shared/network/warehouse.api";
import displayAddress from "shared/util/displayAddress";
import { TableState } from "views/Company/CompanyList/CompanyList";

const WarehouseList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-warehouse-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-warehouse-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const warehouseListQuery = useQuery(
    [
      "warehouseListPageable",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await listWarehousePageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );

      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("warehouse.formValues.name"),
      flex: 1,
    },
    {
      field: "address",
      headerName: t("warehouse.formValues.address.title"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.address),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "WAREHOUSE_GET",
            "WAREHOUSE_ADMIN",
          ]) && (
            <Tooltip title={t("warehouse.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/warehouse-details/details?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "WAREHOUSE_GET",
            "WAREHOUSE_ADMIN",
          ]) && (
            <Tooltip title={t("warehouse.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/warehouse-modify/${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/warehouse-create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("warehouse.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/warehouse-details/details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="warehouse"
      filterable={["name"]}
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={warehouseListQuery}
      onCellClick={onCellClick}
    />
  );
};

export default WarehouseList;
