import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { INVOICE_STATUS_TYPE } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateInvoiceStatus } from "shared/network/invoice.api";
import { Invoice } from "shared/types";

export type InvoiceStatusFormValues = {
  tenantId: string;
  status: string;
  invoiceId: string;
};

type Props = {
  selectedInvoice: Invoice | null;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | null>>;
  refetch: () => void;
};

const InvoiceStatusModifyDialog = ({
  selectedInvoice,
  setSelectedInvoice,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<InvoiceStatusFormValues>();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const onSubmitCreate = async (values: InvoiceStatusFormValues) => {
    if (selectedInvoice) {
      try {
        await updateInvoiceStatus(tenant.id, values.status, selectedInvoice.id);
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("invoice.statusSubject"),
          }),
          { variant: "success" }
        );
        refetch();
        setSelectedInvoice(null);
      } catch (error) {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("invoice.statusSubject"),
          }),
          { variant: "error" }
        );
      }
    }
  };

  return (
    <Dialog open={!!selectedInvoice}>
      <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        <DialogContent>
          <FormProvider {...form}>
            <Grid container spacing={2} style={{ width: 250 }}>
              <Grid item xs={12}>
                <Controller
                  name="status"
                  defaultValue={selectedInvoice?.status || "PENDING"}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("invoice.formValues.status.title")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      {INVOICE_STATUS_TYPE.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {t(`invoice.formValues.status.${status}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" m={2} gridGap={8}>
            <Button
              color="primary"
              variant="text"
              onClick={() => setSelectedInvoice(null)}
            >
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InvoiceStatusModifyDialog;
