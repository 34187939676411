import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateDeliveryItemQuantity } from "shared/network/delivery.api";
import { RelItemDelivery } from "shared/types";

type Props = {
  deliveryItem: RelItemDelivery;
  deliveryStatus: string;
  refetchVechileAndDate?: () => void;
  refetch: () => void;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const DeliveryItemsRow = ({
  deliveryItem,
  deliveryStatus,
  refetch,
  refetchVechileAndDate,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, formState, reset, setValue } =
    useForm<{ quantity: number }>();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  useEffect(() => {
    setValue("quantity", deliveryItem.quantity);
  }, [setValue, deliveryItem]);

  const onSubmitEditQuantity = async (values: { quantity: number }) => {
    try {
      await updateDeliveryItemQuantity(
        { ...deliveryItem, quantity: values.quantity },
        tenant.id,
      );
      if (refetchVechileAndDate) {
        refetchVechileAndDate();
      }
      refetch();
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("order.quantity").toLowerCase(),
        }),
        {
          variant: "success",
        },
      );
      reset();
    } catch (error) {
      setValue("quantity", deliveryItem.quantity);
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("order.quantity").toLowerCase(),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Grid container className={classes.row}>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {deliveryItem.item.name}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {deliveryItem.item.productCode}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        {["BILLABLE", "BILLED", "DELIVERED", "WITHDRAWN"].includes(
          deliveryStatus,
        ) ? (
          <Typography className={classes.text}>
            {deliveryItem.quantity}
          </Typography>
        ) : (
          <form onSubmit={handleSubmit(onSubmitEditQuantity)}>
            <Controller
              control={control}
              name="quantity"
              defaultValue={deliveryItem?.quantity || 0}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    endAdornment: formState.isDirty && (
                      <InputAdornment position="end">
                        <IconButton type="submit">
                          <Check />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        )}
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {t("common:number", { num: deliveryItem.price }) + " Ft"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeliveryItemsRow;
