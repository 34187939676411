import { Button } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
};

const SignatureCanvas = ({ id = "signature" }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLCanvasElement | null>(null);

  const [drawing, setDrawing] = useState(false);
  const [{ ctx, rect }, setState] = useState<{
    ctx?: null | CanvasRenderingContext2D;
    rect?: null | DOMRect;
  }>({ ctx: null, rect: null });

  useEffect(() => {
    setState({
      ctx: ref.current?.getContext("2d"),
      rect: ref.current?.getBoundingClientRect(),
    });
  }, [ref.current]); //eslint-disable-line

  useEffect(() => {
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 100000, 100000);
      ctx.strokeStyle = "black";
      ctx.lineCap = "round";
      ctx.lineWidth = 2;
    }
  }, [ctx]);

  return (
    <>
      <canvas
        id={id}
        ref={ref}
        onMouseDown={(e) => {
          if (ctx) {
            ctx.beginPath();
            ctx.moveTo(
              e.clientX - (rect?.left ?? 0),
              e.clientY - (rect?.top ?? 0)
            );
            setDrawing(true);
          }
        }}
        onTouchStart={(e) => {
          if (ctx) {
            ctx.beginPath();
            ctx.moveTo(
              e.touches[e.touches.length - 1].clientX - (rect?.left ?? 0),
              e.touches[e.touches.length - 1].clientY - (rect?.top ?? 0)
            );
            setDrawing(true);
          }
        }}
        onMouseMove={(e) => {
          if (drawing && ctx) {
            ctx.lineTo(
              e.clientX - (rect?.left ?? 0),
              e.clientY - (rect?.top ?? 0)
            );
            ctx.stroke();
          }
        }}
        onTouchMove={(e) => {
          if (drawing && ctx) {
            ctx.lineTo(
              e.touches[e.touches.length - 1].clientX - (rect?.left ?? 0),
              e.touches[e.touches.length - 1].clientY - (rect?.top ?? 0)
            );
            ctx.stroke();
          }
        }}
        onMouseUp={() => {
          ctx?.closePath();
          setDrawing(false);
        }}
        onMouseOut={() => {
          ctx?.closePath();
          setDrawing(false);
        }}
        width={350}
        height={250}
        style={{
          border: "1px solid gray",
          touchAction: "none",
        }}
      />
      <Button
        onClick={() => {
          if (ctx) {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, 100000, 100000);
          }
        }}
      >
        {t("common:button.delete")}
      </Button>
    </>
  );
};

export default SignatureCanvas;
