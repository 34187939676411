import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteCompanySite,
  getCompanySiteListPageable,
} from "shared/network/companysite.api";
import displayAddress from "shared/util/displayAddress";
import { TableState } from "../CompanyList/CompanyList";

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

type Props = {
  isCompany: boolean;
};

const CompanySite = ({ isCompany = false }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-companysite-page-number") ||
        JSON.stringify(0)
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-companysite-page-size") ||
        JSON.stringify(10)
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const { tenant } = selectedRelTenant;

  const companySiteListQuery = useQuery(
    [
      "companySiteListPageableQuery",
      tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await getCompanySiteListPageable(
        page,
        pageSize,
        tenant.id,
        id ? `company.id:${id};${filterValue}` : filterValue,
        sortState
      );
      return data;
    }
  );

  async function removeCompanySite(id: number) {
    try {
      await deleteCompanySite(id, selectedRelTenant.tenant.id);
      companySiteListQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("companySite.subject"),
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("companySite.subject"),
        }),
        {
          variant: "error",
        }
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("companySite.formValues.name"),
      flex: 1,
    },
    {
      field: "company.name",
      headerName: t("companySite.formValues.company"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.company.name,
    },
    {
      field: "address",
      headerName: t("companySite.formValues.address.title"),
      flex: 2,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.address),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("companySite.delete").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  if (row.id) {
                    removeCompanySite(row.id);
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    !isCompany &&
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              component={Link}
              variant="contained"
              to={id ? `/companysite-create/${id}` : "/companysite-create"}
              startIcon={<AddBox />}
              style={{ margin: "auto" }}
            >
              {t("companySite.create")}
            </Button>
          )}
        </Box>
      );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  return (
    <>
      {isCompany && (
        <Box className={classes.cardHeader}>
          <Typography variant="h2">{t("company.tabs.sites")}</Typography>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              component={Link}
              variant="text"
              to={id ? `/companysite-create/${id}` : "/companysite-create"}
              startIcon={<AddBox />}
            >
              {t("companySite.create")}
            </Button>
          )}
        </Box>
      )}
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="companysite"
        filterable={["name", "company.name"]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={companySiteListQuery}
      />
    </>
  );
};

export default CompanySite;
