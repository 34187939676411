import axios from "config/axios";
import { Company, RelUserCompany } from "shared/types";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";

const ENDPOINT = "/company";

export const createCompany = (param: Company, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    param,
    tenantId,
  }); //COMPANY_ADMIN

export const modifyCompany = (param: Company, tenantId: number) =>
  axios.put<GenericResponse<Company>>(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //COMPANY_ADMIN

export const listCompanyByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<Company>>(
    `${ENDPOINT}/reduced-list?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //COMPANY_GET

export const listCompanyPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<Company>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //COMPANY_GET

export const getCompanyById = (companyId: number | string, tenantId: number) =>
  axios.post<GenericResponse<Company>>(`${ENDPOINT}/get-by-id`, {
    param: companyId,
    tenantId: tenantId,
  }); //COMPANY_GET

export const getCompanyUsersById = (
  companyId: number | string,
  tenantId: number
) =>
  axios.post<GenericListResponse<RelUserCompany>>(`${ENDPOINT}/get-users`, {
    param: companyId,
    tenantId: tenantId,
  }); //COMPANY_GET
