import { DeliveryStatus } from "shared/types";

export const BACKEND_URL =
  //process.env.REACT_APP_BACKEND_URL || "http://192.168.88.237:8081"; //Norbi
  //process.env.REACT_APP_BACKEND_URL || "http://192.168.88.95:8081"; //FGábor
  // process.env.REACT_APP_BACKEND_URL || "http://192.168.88.112:8081"; //NGábor
  // prettier-ignore
  process.env.REACT_APP_BACKEND_URL || "https://rap-backend.dev.promera.systems"; //DEV
//process.env.REACT_APP_BACKEND_URL || "https://rap-api.demo.promera.systems"; //DEMO
//process.env.REACT_APP_BACKEND_URL || "http://localhost:8081"; //local
//process.env.REACT_APP_BACKEND_URL || "http://192.168.88.189:8081"; //Bálint

export const AUTH_TOKEN_KEY = "@rap_software_auth_token";
export const PROFILE_TYPE_KEY = "@rap_software_profile_type";
export const CALENDAR_VIEW_KEY = "@rap_software_calendar_view";
export const COOKIE_CONSENT = "@rap_software_cookie_consent";

export const NUMBER_FORMAT = /^[-+]?[0-9]+\.?[0-9]*$/;

export const PHONE_FORMAT = /^[+]?[0-9]{10,11}$/;

export const TAX_NUMBER =
  /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
export const PASSWORD_POLICY_REGEX =
  /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;

export const BANK_ACCOUNT_NUMBER = /^(\d{8})-(\d{8})-(\d{8})$/;

export const SIDEBAR_WIDTH = 73;
export const HEADER_HEIGHT = 78;

export const USER_STATUS = ["ACTIVE", "BANNED", "NEEDS_ACTIVATION"];

export const GENDERS = ["FEMALE", "MALE", "OTHER", "NODEF"];

export const LANGUAGES = ["HU", "EN"];

export const STATUSES = ["NEW", "ACTIVE", "IN_PROGRESS", "COMPLETED", "CLOSED"];

export const PRIORITY = ["LOW", "NORMAL", "HIGH", "URGENT", "IMMEDIATE"];

export const PERIODIC_CYCLE = ["DAY", "WEEK", "MONTH", "YEAR"];

export const SOURCE_TYPE = ["VEHICLE", "ITEM"];

export const PROGRESS_STATUS = [
  "NEW",
  "PRODUCED",
  "SCHEDULED",
  "IN_CARGO",
  "DELIVERED",
];

export const ORDER_ITEM_AMOUNT_TYPE = ["PIECE", "PACKAGING"];

export const ALL_DELIVERY_STATUS: DeliveryStatus[] = [
  "ACTIVE",
  "IN_CARGO",
  "IN_PROGRESS",
  "DELIVERED",
  "BILLABLE",
  "BILLED",
  "WITHDRAWN",
  "SUSPENDED",
];

export const ISSUE_USER_TYPE = ["ASSIGNEE", "RESPONSIBLE", "VISITOR"];

export const ID_TYPE = ["ISSUE", "INVOICE", "DELIVERY_NOTE"];

export const COMMENT_TYPE = ["DOCUMENT", "TEXT"];
export const DELIVERY_TYPE = [
  "ACTIVE",
  "DELIVERED",
  "BILLABLE",
  "BILLED",
  "SUSPENDED",
  "WITHDRAWN",
  "IN_PROGRESS",
  "IN_CARGO",
];

export const INVOICE_STATUS_TYPE = [
  "PENDING",
  "ACCEPTED",
  "REJECTED",
  "SAVED",
  "CLOSED",
];

export const PERSONAL_DATA_STATUS_TYPES = ["ACTIVE", "NACTIVE"];

export const INVOICE_TYPE = ["NORMAL", "DEPOSIT", "STORNO"];

export const VTSZ_SZJ_TYPE = ["VTSZ", "SZJ", "TESZOR", "NOT_GIVEN"];

export const PAYMENT_TYPE = ["CASH", "TRANSFER", "CARD"];

export const FREQUENCY_OF_INVOICE_EXHIBITION_TYPE = [
  "DAY",
  "WEEK",
  "MONTH",
  "YEAR",
];

export const ITEM_PAYMENT_PERIOD = [
  "OCCASSIONAL",
  "DAY",
  "WEEK",
  "MONTH",
  "BASED_ON_MEASUREMENT",
];

export const ITEM_TYPES = [
  "PRODUCT",
  "WRAPPING",
  "SERVICE",
  "DEVICE",
  "PART",
  "SERIAL_NUMBER",
];

export const VEHICLE_BODY_TYPES = [
  "VAN",
  "CLOSED_BOX",
  "SHEETED_FLATBED",
  "REFRIGERATOR_VAN",
  "TRACTOR",
];

export const ABSENCE_TYPES = [
  "SICK_LEAVE",
  "LEAVE",
  "UNPAID_LEAVE",
  "PAID_LEAVE",
  "LEAVE_FOR_FAMILY_REASONS",
  "SHORT_TERM_DIASABILITY",
  "MOURNING_FREEDOM",
];

export const OFFER_TYPES = ["DELIVERY", "RENTAL", "MAINTENANCE", "FLAT_RATE"];

export const DOCUMENT_TYPES = [
  "ADDRESS_CARD",
  "DRIVING_LICENSE",
  "IDENTITY_CARD",
  "SOCIAL_SECURITY_CARD",
  "TAX_CARD",
  "CONTRACT",
  "MEDICAL_CRETIFICATE",
];

export const COMPLETITION_PERCENTAGE = [
  "0",
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];

export const COUNTIES = {
  BACS_KISKUN: "Bács-Kiskun",
  BARANYA: "Baranya",
  BEKES: "Békés",
  BORSOD_ABAUJ_ZEMPLEN: "Borsod-Abaúj-Zemplén",
  BUDAPEST: "Budapest",
  CSONGRAD_CSANAD: "Csongrád-Csanád",
  FEJER: "Fejér",
  GYOR_MOSON_SOPRON: "Győr-Moson-Sopron",
  HAJDU_BIHAR: "Hajdú-Bihar",
  HEVES: "Heves",
  JASZ_NAGYKUN_SZOLNOK: "Jász-Nagykun-Szolnok",
  KOMAROM_ESZTERGOM: "Komárom-Esztergom",
  NOGRAD: "Nógrád",
  PEST: "Pest",
  SOMOGY: "Somogy",
  SZABOLCS_SZATMAR_BEREG: "Szabolcs-Szatmár-Bereg",
  TOLNA: "Tolna",
  VAS: "Vas",
  VESZPREM: "Veszprém",
  ZALA: "Zala",
};

export const TAX_TYPES = [
  "PERCENT",
  "TAM",
  "AAM",
  "EU",
  "EUK",
  "MAA",
  "F_AFA",
  "K_AFA",
  "AKK",
  "ATHK",
  "FAD",
];

export const ARTICLE_TYPES = ["TOS", "GDPR"];

export const AREA_TYPES = [
  "UTCA",
  "UT",
  "TER",
  "KOZ",
  "KORUT",
  "LAKOTELEP",
  "SETANY",
  "AROK",
  "ATJARO",
  "DULO",
  "DULOUT",
  "ERDOSOR",
  "FASOR",
  "FORDULO",
  "GAT",
  "HATARSOR",
  "HATARUT",
  "KAPU",
  "KOROND",
  "KORTER",
  "LEJARO",
  "LEJTO",
  "LEPCSO",
  "LIGET",
  "MELYUT",
  "OROM",
  "OSVENY",
  "PARK",
  "PART",
  "PINCESOR",
  "RAKPART",
  "SIKATOR",
  "SOR",
  "SUGARUT",
  "TERE",
  "UDVAR",
  "UDULOPART",
];

export const AUDITLOG_TYPE = {
  ARTICLE_SAVE: "article.save",
  ARTICLE_DELETE: "article.delete",
  ARTICLE_GET_ACTIVE_GENERAL_DATA_PROTECTION_REGULATION:
    "article.getActiveGeneralDataProtectionRegulation",
  ARTICLE_GET_ACTIVE_TERMS_OF_SERVICE: "article.getActiveTermsOfService",
  ARTICLE_LIST: "article.list",
  ARTICLE_LIST_BY_SUBJECT: "article.listBySubject",
  ABSENCE_CREATE: "absence.create",
  ABSENCE_DELETE: "absence.delete",
  ABSENCE_LIST: "absence.list",
  ABSENCE_MODIFY: "absence.modify",
  ARTICLE_PAGE: "article.page",
  ARTICLE_EXPORT: "article.export",
  BUNDLE_CREATE: "bundle.create",
  BUNDLE_MODIFY: "bundle.modify",
  BUNDLE_GET: "bundle.get",
  REL_USER_COMPANY_CREATE: "relusercompany.create",
  REL_USER_COMPANY_DELETE: "relusercompany.delete",
  REL_USER_COMPANY_GET: "relusercompany.get",
  ISSUE_TEMPLATE_CREATE: "issueTemplate.create",
  ISSUE_TEMPLATE_GET: "issueTemplate.get",
  ISSUE_TEMPLATE_MODIFY: "issueTemplate.modify",
  TIME_ENTRY_CREATE: "timeEntry.create",
  TIME_ENTRY_MODIFY: "timeEntry.modify",
  TIME_ENTRY_GET_BY_ID: "timeEntry.getById",
  TIME_ENTRY_GET_PAGE_BY_TENANT: "timeEntry.getPageByTenant",
  ITEM_CREATE: "item.create",
  ITEM_GET: "item.get",
  ITEM_MODIFY: "item.modify",
  ITEM_LIST: "item.list",
  ITEM_TAX_HISTORY_CREATE: "itemTaxHistory.create",
  ITEM_PRICE_HISTORY_CREATE: "itemPriceHistory.create",
  TOOL_CREATE: "tool.create",
  TOOL_MODIFY: "tool.modify",
  TOOL_GET_BY_ID: "tool.getById",
  TOOL_GET_PAGE_BY_TENANT: "tool.getPageByTenant",
  TOOL_DELETE: "tool.delete",
  WAREHOUSE_CREATE: "warehouse.create",
  WAREHOUSE_MODIFY: "warehouse.modify",
  WAREHOUSE_GET: "warehouse.get",
  WAREHOUSE_LIST: "warehouse.create",
  VEHICLE_CREATE: "vehicle.create",
  VEHICLE_MODIFY: "vehicle.modify",
  QUALIFICATION_CREATE: "qualification.create",
  QUALIFICATION_DELETE: "qualification.delete",
  QUALIFICATION_MODIFY: "qualification.modify",
  QUALIFICATION_LIST: "qualification.list",
  DELIVERY_CREATE: "delivery.create",
  DELIVERY_DELETE: "delivery.delete",
  DELIVERY_MODIFY: "delivery.modify",
  DELIVERY_LIST: "delivery.list",
  DELIVERY_PAGE: "delivery.page",
  DELIVERY_NOTE_CREATE: "deliveryNote.create",
  DELIVERY_NOTE_MODIFY: "deliveryNote.modify",
  DELIVERY_NOTE_ADD_SIGNATURE_FILE: "deliveryNote.addSignatureFile",
  DEPARTMENT_CREATE: "department.create",
  DEPARTMENT_GET: "department.get",
  DEPARTMENT_MODIFY: "department.modify",
  TOOL_EXPENSE_CREATE: "toolexpense.create",
  TOOL_EXPENSE_MODIFY: "toolexpense.modify",
  TOOL_EXPENSE_GET_BY_ID: "toolexpense.getbyid",
  TOOL_EXPENSE_GET_PAGE: "toolexpense.getpage",
  COMPANY_SITE_CREATE: "companySite.create",
  COMPANY_SITE_GET: "companySite.get",
  COMPANY_SITE_MODIFY: "companySite.modify",
  COMPANY_SITE_PAGE: "companySite.page",
  CONTACT_PAGE: "contact.page",
  CURRENCY_PAGE: "currency.page",
  CURRENCY_GET_BY_ID: "currency.byId",
  PERMISSION_PAGE: "permission.page",
  REL_COMPANY_CATEGORY_COMPANY_CREATE: "relCompanyCategoryCompany.create",
  REL_COMPANY_CATEGORY_COMPANY_DELETE: "relCompanyCategoryCompany.delete",
  REL_ISSUE_CUSTOM_FIELDS_CREATE: "relIssueCustomFields.create",
  REL_ISSUE_TEMPLATE_CUSTOM_FIELDS_CREATE:
    "relIssueTemplateCustomFields.create",
  CATEGORY_GET: "category.get",
  CATEGORY_CREATE: "category.create",
  CATEGORY_MODIFY: "category.modify",
  CATEGORY_GET_PAGE: "category.getPage",
  CUSTOM_FIELDS_CREATE: "customFields.create",
  CUSTOM_FIELDS_MODIFY: "customFields.modify",
  CUSTOM_FIELDS_DELETE: "customFields.delete",
  SALARY_CREATE: "salary.create",
  SALARY_MODIFY: "salary.modify",
  SALARY_DELETE: "salary.delete",
  SALARY_LIST_BY_EMPLOYEE: "salary.listByEmployee",
  SALARY_GET_BY_ID: "salary.getById",
  ISSUE_TYPE_CREATE: "issueType.create",
  ISSUE_TYPE_MODIFY: "issueType.modify",
  ISSUE_TYPE_LIST_BY_TENANT: "issueType.listByTenant",
  ISSUE_TYPE_GET_BY_ID: "issueType.getById",
  ISSUE_TYPE_PAGE: "issueType.page",
  PROJECT_CREATE: "project.create",
  PROJECT_MODIFY: "project.modify",
  COMPANY_NICKNAME_CREATE: "companyNickname.create",
  COMPANY_NICKNAME_MODIFY: "companyNickname.modify",
  COMPANY_NICKNAME_DELETE: "companyNickname.delete",
  COMPANY_CREATE: "company.create",
  COMPANY_MODIFY: "company.modify",
  COMPANY_STATUS_UPDATE: "company.statusUpdate",
  COMPANY_GET_BY_ID: "company.getById",
  COMPANY_LIST_BY_TENANT: "company.listByTenant",
  COMPANY_PAGE: "company.page",
  COMPANY_CATEGORY_SAVE: "companyCategory.save",
  COMPANY_CATEGORY_DELETE: "companyCategory.delete",
  COMMENT_CREATE_WITH_FILE: "comment.createWithFile",
  COMMENT_LIST_BY_SUBJECT: "comment.listBySubject",
  PERSONAL_DATA_CREATE: "personalData.create",
  PERSONAL_DATA_MODIFY: "personalData.modify",
  PERSONAL_DATA_GET_BY_ID: "personalData.getById",
  PERSONAL_DATA_DELETE: "personalData.delete",
  PERSONAL_DATA_LIST_BY_EMPLOYEE: "personalData.listByEmployee",
  COMMENT_PAGE: "comment.page",
  USER_GET: "user.get",
  FILE_GET: "file.get",
  TRANSACTIONS_CREATE: "transactions.create",
  ISSUE_CREATE: "issue.create",
  ISSUE_MODIFY: "issue.modify",
  ISSUE_GET_BY_ID: "issue.getbyid",
  ISSUE_GET_PAGE_BY_TENANT: "issue.getpagebytenant",
  USER_MODIFY: "user.modify",
  TENANT_CREATE: "tenant.create",
  TENANT_GET_BY_ID: "tenant.getById",
  TENANT_MODIFY: "tenant.modify",
  TENANT_CHANGE_ACTIVE_STATUS: "tenant.changeActiveStatus",
  SUBSCRIPTION_CREATE: "subscription.create",
  EMPLOYEE_GET_BY_ID: "employee.getById",
  EMPLOYEE_LIST: "employee.list",
  EMPLOYEE_MODIFY: "employee.modify",
  EMPLOYEE_CREATE: "employee.create",
  EMPLOYEE_PAGE: "employee.page",
  EMPLOYEE_SEND_EMAIL: "employee.send.email",
  OFFER_CREATE: "offer.create",
  OFFER_GET_BY_ID: "offer.getById",
  INVOICE_GET_PAGE: "invoice.getPage",
  INVOICE_GET_BY_ID: "invoice.getById",
  ORDER_CREATE: "order.create",
  BANK_ACCOUNT_CREATE: "bankAccount.create",
  BANK_ACCOUNT_MODIFY: "bankAccount.modify",
  BANK_ACCOUNT_DELETE: "bankAccount.delete",
  AUDIT_LOG_LIST: "auditlog.list",
  AUDIT_LOG_PAGE: "auditlog.page",
  USER_FORGOTTEN_PASSWORD: "user.forgottenPassword",
  USER_FORGOTTEN_PASSWORD_FINISH: "user.forgottenPasswordFinish",
  USER_CHANGE_PASSWORD: "user.changePassword",
};

export const PROJECT_TYPE = ["BASIC", "SUPPORT", "RENT"];

export const CONTRACT_TYPE = ["DELIVERY", "RENTAL", "MAINTENANCE", "FLAT_RATE"];

export const PAYMENT_PERIOD = [
  "IMMEDIATE",
  "DAY",
  "WEEK",
  "MONTH",
  "QUARTER_YEAR",
  "HALF_YEAR",
  "YEAR",
];
export const PRODUCT_UNIT_TYPE = [
  "PIECE",
  "KILOGRAM",
  "TON",
  "KWH",
  "DAY",
  "HOUR",
  "MINUTE",
  "MONTH",
  "LITER",
  "KILOMETER",
  "CUBIC_METER",
  "METER",
  "LINEAR_METER",
  "CARTON",
  "PACK",
  "OWN",
];

export const PRODUCT_UNIT = [
  "PACK",
  "PIECE",
  "LINEAR_METER",
  "MONTH",
  "CARTON",
  "KILOGRAM",
  "KILOMETER",
  "KWH",
  "CUBIC_METER",
  "LITER",
  "METER",
  "DAY",
  "HOUR",
  "MINUTE",
  "TON",
  "OWN",
];

export const INCOMING_INVOICE_EXPENSE_TYPE = [
  "EMPLOYEE",
  "VEHICLE",
  "TOOL",
  "DEPARTMENT",
];
