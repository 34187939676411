import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Assignment, Autorenew, Close, Done } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority, hasPermissionButHasCompany } from "shared/authorization";
import {
  acceptOffer,
  declineOffer,
  listOfferByTenant,
} from "shared/network/offer.api";
import { TableState } from "views/Company/CompanyList/CompanyList";

const OfferList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const projectId = query.get("offerId");
  const companyId = query.get("id");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [confirmation, setConfirmation] = useState<{
    type: string;
    id: number;
  }>({ type: "", id: 0 });

  const [sortState, setSortState] = useState("company.name,asc");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-tenant-offerlist-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-tenant-offerlist-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const getOfferList = useQuery(
    [
      "getOfferList",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await listOfferByTenant(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        !hasPermissionButHasCompany(account) && !!account.user.relUserCompany
          ? `company.id:${account.user.relUserCompany[0]?.id};`
          : companyId
          ? `company.id:${companyId};`
          : "" + filterValue,
        sortState,
      );
      return data;
    },
  );

  async function acceptOfferFunction(offerId: number) {
    try {
      await acceptOffer(offerId, selectedRelTenant.tenant.id);
      getOfferList.refetch();
      enqueueSnackbar(
        t("common:notification.acceptance.success", {
          subject: t("offer.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      getOfferList.refetch();
      enqueueSnackbar(
        t("common:notification.acceptance.failure", {
          subject: t("offer.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function declineOfferFunction(offerId: number) {
    try {
      await declineOffer(offerId, selectedRelTenant.tenant.id);
      getOfferList.refetch();
      enqueueSnackbar(
        t("common:notification.decline.success", {
          subject: t("offer.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      getOfferList.refetch();
      enqueueSnackbar(
        t("common:notification.decline.failure", {
          subject: t("offer.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "company.name",
      headerName: t("offer.formValues.companyName"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.company?.name || "-",
    },
    {
      field: "name",
      headerName: t("offer.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.name || "-",
    },
    {
      field: "currency.name",
      headerName: t("offer.formValues.currency"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.currency ? row.currency.name : "-",
    },
    {
      field: "description",
      headerName: t("offer.formValues.description"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.description || "-",
    },
    {
      field: "validTo",
      headerName: t("offer.formValues.validTo"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.validTo ? format(new Date(row.validTo), "yyyy. MM.dd.") : "-",
    },
    {
      field: "discount",
      headerName: t("offer.formValues.discount"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => `${row.discount} %`,
    },
    {
      field: "status",
      headerName: t("offer.formValues.status"),
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`offer.statuses.${row.status}`).toString()}>
          {row.status === "ACCEPTED" ? (
            <Done style={{ color: COLORS.green }} />
          ) : row.status === "DECLINED" ? (
            <Close style={{ color: COLORS.red }} />
          ) : (
            <Autorenew />
          )}
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "OFFER_ADMIN",
          ]) &&
            row.status !== "ACCEPTED" && (
              <Tooltip title={t("offer.accept").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (row.id) {
                      row.status === "DECLINED"
                        ? setConfirmation({ type: "DECLINED", id: row.id })
                        : acceptOfferFunction(row.id);
                    }
                  }}
                >
                  <Done color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "OFFER_ADMIN",
          ]) &&
            row.status !== "DECLINED" && (
              <Tooltip title={t("offer.decline").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (row.id) {
                      row.status === "ACCEPTED"
                        ? setConfirmation({ type: "ACCEPTED", id: row.id })
                        : declineOfferFunction(row.id);
                    }
                  }}
                >
                  <Close color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "OFFER_ADMIN",
          ]) && (
            <Tooltip title={t("offer.details").toString()}>
              <IconButton
                component={Link}
                to={`/offer-details/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/offer-details/${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "OFFER_ADMIN",
        ]) && (
          <Button
            component={Link}
            to={`/offer-create?projectId=${projectId}&companyId=${companyId}`}
            variant="contained"
            startIcon={<AddBox />}
          >
            {t(`offer.create`)}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container maxWidth="xl">
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="offerlist"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getOfferList}
        onCellClick={onCellClick}
      />
      <Dialog
        open={
          confirmation.type === "ACCEPTED" || confirmation.type === "DECLINED"
        }
        maxWidth="xs"
      >
        <DialogTitle>
          {confirmation.type === "ACCEPTED"
            ? t("offer.decline")
            : t("offer.accept")}
        </DialogTitle>
        <DialogContent>
          <Box width="100%">
            <Typography align="center">
              {confirmation.type === "ACCEPTED"
                ? t("offer.confirmationText.decline")
                : t("offer.confirmationText.accept")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmation({ type: "", id: 0 });
              confirmation.type === "ACCEPTED"
                ? declineOfferFunction(confirmation.id)
                : acceptOfferFunction(confirmation.id);
            }}
          >
            {t("common:button.ok")}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setConfirmation({ type: "", id: 0 });
            }}
          >
            {t("common:button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OfferList;
