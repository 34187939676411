import axios from "config/axios";
import { Contact } from "shared/types";
import { GenericPageResponse, GenericResponse } from "shared/common";

const ENDPOINT = "/contact";

export const createContact = (param: Contact, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //COMPANY_ADMIN

export const modifyContact = (param: Contact, tenantId: number) =>
  axios.put<GenericResponse<Contact>>(ENDPOINT, { param, tenantId }); //COMPANY_ADMIN

export const listContactByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<Contact>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //COMPANY_ADMIN

export const getContactById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Contact>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //COMPANY_ADMIN
