import axios from "config/axios";
import { Employee } from "shared/types";

const ENDPOINT = "/rel-employee-employee";

export type RelEmployeeEmployeeRequest = {
  superiorEmployee: Employee | { id: string } | null;
  subordinateEmployee: Employee | { id: string } | null;
};

export const createRelEmployeeEmployee = (
  param: RelEmployeeEmployeeRequest,
  tenantId: number
) => axios.post(`${ENDPOINT}/create`, { param, tenantId }); //EMPLOYEE_ADMIN

export const deleteRelEmployeeEmployee = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/delete`, { param, tenantId }); //EMPLOYEE_ADMIN
