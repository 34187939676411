import axios from "config/axios";
import { GenericListResponse } from "shared/common";
import { Qualification } from "shared/types";

const ENDPOINT = "/qualification";

export const createQualification = (param: Qualification) =>
  axios.post(ENDPOINT, { param, tenantId: param.tenantId }); //EMPLOYEE_ADMIN

export const modifyQualification = (param: Qualification) =>
  axios.put(ENDPOINT, { param, tenantId: param.tenantId }); //EMPLOYEE_ADMIN

export const deleteQualification = (param: number, tenantId: number) =>
  axios.delete(ENDPOINT, { data: { param, tenantId } }); //EMPLOYEE_ADMIN

export const listQualifications = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<Qualification>>(
    `${ENDPOINT}/list-by-employee`,
    { param, tenantId }
  ); //EMPLOYEE_ADMIN
