import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CheckCircleOutline, Edit } from "@material-ui/icons";
import Block from "@material-ui/icons/Block";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Tenant } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import TenantBundleModify from "./TenantBundleModify";
import UploadTenantIcon from "./TenantIcon/UploadTenantIcon";

type Props = {
  tenant: Tenant | null;
  loading: boolean;
  refetch: () => void;
};

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginBottom: 8,
  },
  dataHeaderItems: {
    justifyContent: "space-between",
  },
  dataHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
    width: "100%",
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 12,
    marginBottom: 20,
    width: "100%",
  },
});

const TenantDetails = ({ tenant, loading, refetch }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [invoiceIcon, setInvoiceIcon] = useState(false);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <UploadTenantIcon
          setOpen={setInvoiceIcon}
          open={invoiceIcon}
          tenantId={tenant?.id.toString()}
        />
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className={classes.dataHeader}>
              <Typography variant="h2">{t("tenant.datas")}</Typography>
              <Box className={classes.dataHeaderItems}>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to={`/tenant-modify/${id}`}
                  startIcon={<Edit />}
                  style={{ height: 24 }}
                >
                  {t("common:button.modify")}
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => setInvoiceIcon(true)}
                  startIcon={<Edit />}
                  style={{ height: 24 }}
                >
                  {t("tenant.addInvoiceIcon")}
                </Button>
              </Box>
            </Box>
            <Box className={classes.dataContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.name")}
                  </Typography>
                  <Typography variant="body1">{tenant?.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.tenantCode")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.tenantCode || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.bankAccount")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.bankAccount || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.taxNumber")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.taxNumber || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.registrationNumber")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.registrationNumber || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.baseLanguage")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.baseLanguage
                      ? t(`common:language.${tenant.baseLanguage}`)
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.baseCurrency.title")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.baseCurrency.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.baseCurrencySource")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.baseCurrencySource || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.description")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.description || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.dataHeader}>
              <Typography variant="h2">{t("tenant.availability")}</Typography>
            </Box>
            <Box className={classes.dataContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.phone")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.phone || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.fullAddress")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.address ? displayAddress(tenant?.address) : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="body2">
                    {t("tenant.formValues.email")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.notificationEmail || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.dataHeader}>
              <Typography variant="h2">{t("subscription.title")}</Typography>
            </Box>
            <Box className={classes.dataContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("subscription.formValues.isActive")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.isActive
                      ? t("common:YES")
                      : t("common:NO")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("subscription.formValues.subscriptionStart")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.subscriptionStart
                      ? format(
                          new Date(
                            tenant?.subscriptions?.[0]?.subscriptionStart
                          ),
                          "yyyy-MM-dd"
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("subscription.formValues.subscriptionEnd")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.subscriptionEnd
                      ? format(
                          new Date(tenant?.subscriptions?.[0]?.subscriptionEnd),
                          "yyyy-MM-dd"
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("subscription.formValues.autoRenew")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.autoRenew
                      ? t("common:YES")
                      : t("common:NO")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.dataHeader}>
              <Typography variant="h2">
                {t("bundle.tenantDetails.title")}
              </Typography>
              <Button
                color="primary"
                variant="text"
                startIcon={<Edit />}
                onClick={() => {
                  setOpen(true);
                }}
                style={{ height: 24 }}
              >
                {t("common:button.modify")}
              </Button>
            </Box>
            <Box className={classes.dataContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("bundle.formValues.name")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2">
                    {t("bundle.formValues.price")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.price}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body2">
                    {t("bundle.formValues.description")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={1}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.hr")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.hr ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={1}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.depot")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.depot ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.invoiceManagement")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.invoiceManagement ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.deliveryNote")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.deliveryNote ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.manageTasks")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.manageTasks ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.productionControl")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.productionControl ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {t("bundle.formValues.isWorksheetAllowed")}
                  </Typography>
                  <Typography variant="body1">
                    {tenant?.subscriptions?.[0]?.bundle.isWorksheetAllowed ? (
                      <CheckCircleOutline style={{ color: COLORS.green }} />
                    ) : (
                      <Block style={{ color: COLORS.red }} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Container>
      <Dialog open={open}>
        <DialogTitle>{t("bundle.tenantDetails.modify")}</DialogTitle>
        <DialogContent>
          <TenantBundleModify
            bundle={tenant?.subscriptions?.[0]?.bundle}
            setOpen={setOpen}
            refetch={refetch}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TenantDetails;
