import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { Assignment, Business, Edit, GroupAdd } from "@material-ui/icons";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { listTenants } from "shared/network/tenant.api";
import displayAddress from "shared/util/displayAddress";

const TenantList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();

  const tenantList = useQuery(["tenants"], async () => {
    const { data } = await listTenants();
    return data;
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("tenant.formValues.name"),
      flex: 1,
    },
    {
      field: "notificationEmail",
      headerName: t("tenant.formValues.email"),
      flex: 1,
    },
    {
      field: "phone",
      headerName: t("tenant.formValues.phone"),
      flex: 1,
    },
    {
      field: "address",
      headerName: t("tenant.formValues.address.title"),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.address ? displayAddress(row.address) : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip title={t("tenant.details").toString()}>
            <IconButton
              component={Link}
              to={`/tenant/details?id=${row.id}&from=tenant`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Assignment color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("tenant.modify").toString()}>
            <IconButton
              component={Link}
              to={`/tenant-modify/${row.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("department.create").toString()}>
            <IconButton
              component={Link}
              to={`/department-create/${row.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Business color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button
          component={Link}
          variant="outlined"
          to="/subscription-create"
          startIcon={<SubscriptionsIcon />}
        >
          {t("subscription.create")}
        </Button>
        <Button component={Link} to="/tenant-create" startIcon={<GroupAdd />}>
          {t("tenant.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/tenant/details?id=${params.row.id}`);
    }
  }

  return (
    <Container>
      <SingleQueryTable
        query={tenantList}
        columns={columns}
        page={page}
        setPage={setPage}
        onCellClick={onCellClick}
      />
    </Container>
  );
};

export default TenantList;
