import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCashByVehicle } from "shared/network/delivery.api";
import { Vehicle } from "shared/types";
import VehicleCashByVehicleRow from "./VehicleCashByVehicleRow";

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  row: {
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
});

export type VehicleCashData = {
  vehicle: Vehicle;
  cashInHand: number;
  cashOnRefuse?: number;
};

const VehicleCashByVehicle = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const getCashByVehiclesQuery = useQuery(
    ["cashByVehicleQuery", date, tenant.id],
    async () => {
      const { data } = await getCashByVehicle(
        tenant.id,
        format(date, "yyyy-MM-dd")
      );
      return data.items;
    }
  );

  return (
    <>
      <Loading open={getCashByVehiclesQuery.isFetching} />
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12} justifyContent="center">
          <Grid item xs={4}>
            <KeyboardDatePicker
              size="small"
              value={date}
              onChange={(date) => {
                date && setDate(date);
              }}
              label={t("delivery.formValues.date")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
            />
          </Grid>
        </Grid>
      </Grid>
      {getCashByVehiclesQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
        </Box>
      ) : (
        <>
          {getCashByVehiclesQuery.data?.length === 0 ? (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:noData")}
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container className={classes.header}>
                <Grid item xs={2} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("vehicle.formValues.licensePlateNumber")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("vehicle.formValues.brand")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("vehicle.formValues.type")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("vehicle.formValues.user")}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {"Készpénz"}
                  </Typography>
                </Grid>
              </Grid>
              {getCashByVehiclesQuery.data?.map((item) => (
                <VehicleCashByVehicleRow
                  key={item.vehicle.id}
                  item={item}
                  refetch={getCashByVehiclesQuery.refetch}
                  date={date}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VehicleCashByVehicle;
