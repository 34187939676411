import { Address } from "shared/types";
import i18next from "i18next";

export const getInvoiceAddress = (address?: Address) => {
  return address
    ? `${address.areaName} ${i18next.t(
        `common:areaType.${address.areaType}`
      )} ${address.houseNumber} ${address.floor ? address.floor : ""}`
    : "";
};

const displayAddress = (address?: Address) => {
  return address
    ? `${address.zipCode} ${address.city}, ${address.areaName} ${i18next.t(
        `common:areaType.${address.areaType}`,
        address.areaType
      )} ${address.houseNumber} ${address.floor ? address.floor : ""}`
    : "";
};

export default displayAddress;
