import axios from "config/axios";

const ENDPOINT = "/rel-company-category-company";

export const createRelCompanyCategoryCompany = (
  param: {
    companyCategoryIds: number[];
    companyId: string;
  }[],
  tenantId: number
) =>
  axios.post(`${ENDPOINT}/create`, {
    params: param,
    tenantId: tenantId,
  }); //COMPANY_ADMIN

export const deleteRelCompanyCategoryCompany = (
  params: {
    companyCategoryIds: number[];
    companyId: string;
  }[],
  tenantId: number
) => axios.delete(`${ENDPOINT}/delete`, { data: { params, tenantId } }); //COMPANY_ADMIN
