import axios from "config/axios";
import { GenericListResponse } from "shared/common";
import { Cargo } from "shared/types";
import { CargoRemoveFormValues } from "views/Vehicle/Cargo/CargoRemove";

const ENDPOINT = "/cargo";

type CargoRequest = {
  itemId: string | number | undefined;
  quantity: string;
  vehicleId: string | number | undefined;
  warehouseId: string | number | undefined;
};

export const listCargoForVehicle = (vehicleId: string, tenantId: number) =>
  axios.get<GenericListResponse<Cargo>>(
    `${ENDPOINT}?vehicleId=${vehicleId}&tenantId=${tenantId}`,
  ); //CARGO_GET

export const modifyVehicleCargo = (
  request: CargoRemoveFormValues,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/modify-items`, {
    vehicleId: request.vehicleId,
    removeEverything: request.removeEverything,
    cargoItems: request.cargoItems,
    warehouseId: request.warehouseId,
    tenantId,
  });

export const getVehicleCargoByDriver = (userId: number, tenantId: number) =>
  axios.get<GenericListResponse<Cargo>>(
    `${ENDPOINT}/get-by-driver?userId=${userId}&tenantId=${tenantId}`,
  ); //CARGO_GET

export const createBackupCargo = (request: CargoRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}`, {
    itemId: request.itemId,
    quantity: request.quantity,
    vehicleId: request.vehicleId,
    warehouseId: request.warehouseId,
    tenantId,
  }); //CARGO_ADMIN
