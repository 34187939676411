import UserList from "./UserList";
import EmployeeList from "views/Employee/EmployeeList";
import { useTranslation } from "react-i18next";
import { Switch, Link, Redirect } from "react-router-dom";
import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { useLocation } from "react-router";
import DepartmentList from "views/Department/DepartmentList";
import PrivateRoute from "components/PrivateRoute";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import AbsenceList from "./Absence/AbsenceList";
import VehicleList from "views/Vehicle/VehicleList";
import ToolList from "views/Tools/ToolList/ToolList";

export const USER_TABS = [
  "/my-company/users",
  "/my-company/employees",
  "/my-company/departments",
  "/my-company/absences",
  "/my-company/vehicles",
  "/my-company/tools",
];

const MyCompanyTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Container maxWidth="xl">
      {location.pathname === "/my-company" && (
        <Redirect to="/my-company/users" />
      )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "USER_ADMIN",
        ]) && (
          <Tab
            label={t("drawer.users")}
            component={Link}
            to={USER_TABS[0]}
            value={USER_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Tab
            label={t("drawer.employee")}
            component={Link}
            to={USER_TABS[1]}
            value={USER_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DEPARTMENT_ADMIN",
        ]) && (
          <Tab
            label={t("drawer.department")}
            component={Link}
            to={USER_TABS[2]}
            value={USER_TABS[2]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "ABSENCE_GET",
        ]) && (
          <Tab
            label={t("drawer.absence")}
            component={Link}
            to={USER_TABS[3]}
            value={USER_TABS[3]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHICLE_ADMIN",
          "VEHICLE_GET",
        ]) && (
          <Tab
            label={t("drawer.vehicle")}
            component={Link}
            to={USER_TABS[4]}
            value={USER_TABS[4]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "TOOL_ADMIN",
          "TOOL_GET",
        ]) && (
          <Tab
            label={t("drawer.tools")}
            component={Link}
            to={USER_TABS[5]}
            value={USER_TABS[5]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={USER_TABS[0]}
            component={UserList}
            permissions={["USER_ADMIN"]}
          />
          <PrivateRoute
            path={USER_TABS[1]}
            component={EmployeeList}
            permissions={["EMPLOYEE_ADMIN"]}
          />
          <PrivateRoute
            path={USER_TABS[2]}
            component={DepartmentList}
            permissions={["DEPARTMENT_ADMIN"]}
          />
          <PrivateRoute
            path={USER_TABS[3]}
            component={AbsenceList}
            permissions={["ABSENCE_GET"]}
          />
          <PrivateRoute
            path={USER_TABS[4]}
            component={VehicleList}
            permissions={["VEHICLE_GET"]}
          />
          <PrivateRoute
            path={USER_TABS[5]}
            component={ToolList}
            permissions={["TOOL_GET"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default MyCompanyTabs;
