import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import {
  createCompanyNickname,
  modifyCompanyNickname,
} from "shared/network/nickname.api";
import { CompanyNickname } from "shared/types";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  nickname?: CompanyNickname;
  companyId?: string | number;
  refetch: () => void;
};

const NicknameDialog = ({
  open,
  setOpen,
  nickname,
  companyId,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<SliceStatus>("idle");
  const form = useForm<CompanyNickname>();
  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const onSubmitCreate = async (values: CompanyNickname) => {
    if (companyId) {
      setStatus("pending");
      try {
        await createCompanyNickname(
          {
            ...values,
            companyId,
            tenantId: tenant.id,
          },
          tenant.id
        );
        setStatus("success");
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("companyNickname.subject"),
          }),
          {
            variant: "success",
          }
        );
        refetch();
        setOpen(false);
      } catch {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("companyNickname.subject"),
          }),
          {
            variant: "error",
          }
        );
        setStatus("failure");
      }
    }
  };

  const onSubmitModify = async (values: CompanyNickname) => {
    if (nickname) {
      setStatus("pending");
      try {
        await modifyCompanyNickname(
          {
            nickname: values.nickname,
            id: nickname.id,
            tenantId: nickname.tenantId,
          },
          tenant.id
        );
        setStatus("success");
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("companyNickname.subject"),
          }),
          { variant: "success" }
        );
        refetch();
        setOpen(false);
      } catch {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("companyNickname.subject"),
          }),
          { variant: "error" }
        );
        setStatus("failure");
      }
    }
  };

  return (
    <>
      <Loading open={status === "pending"} />
      <Dialog open={open}>
        <form
          onSubmit={form.handleSubmit(
            nickname ? onSubmitModify : onSubmitCreate
          )}
        >
          <DialogTitle>{t("companyNickname.modify")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={form.control}
                  name="nickname"
                  defaultValue={nickname?.nickname || ""}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("companyNickname.nickname")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" m={2} gridGap={8}>
              <Button
                color="primary"
                variant="text"
                onClick={() => setOpen(false)}
              >
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default NicknameDialog;
