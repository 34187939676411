import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Tenant, RelItemCompany } from "shared/types";

const ENDPOINT = "/tenant";

export const createTenant = (tenant: Tenant) =>
  axios.post(`${ENDPOINT}/create`, {
    tenant: {
      name: tenant.name,
      taxNumber: tenant.taxNumber,
      registrationNumber: tenant.registrationNumber,
      notificationEmail: tenant.notificationEmail,
      phone: tenant.phone,
      bankAccount: tenant.bankAccount,
      address: tenant.address,
      baseLanguage: tenant.baseLanguage,
      baseCurrency: tenant.baseCurrency,
      baseCurrencySource: tenant.baseCurrencySource,
      tenantCode: tenant.tenantCode,
    },
  }); //superadmin

export const listTenants = () =>
  axios.get<GenericListResponse<Tenant>>(`${ENDPOINT}/list`, {
    data: { tenantId: 0 },
  }); //superadmin

export const getTenantById = (id?: number) =>
  axios.post<GenericResponse<Tenant>>(`${ENDPOINT}/get-by-id`, {
    param: id,
    tenantId: id,
  }); //istenantadmin superadmin

export const modifyTenant = (tenant: Tenant) =>
  axios.put(`${ENDPOINT}`, {
    param: {
      id: tenant.id,
      name: tenant.name,
      taxNumber: tenant.taxNumber,
      registrationNumber: tenant.registrationNumber,
      notificationEmail: tenant.notificationEmail,
      phone: tenant.phone,
      bankAccount: tenant.bankAccount,
      address: tenant.address,
      baseLanguage: tenant.baseLanguage,
      baseCurrency: tenant.baseCurrency,
      baseCurrencySource: tenant.baseCurrencySource,
      tenantCode: tenant.tenantCode,
      isTaxCashFlow: tenant.isTaxCashFlow,
      invoiceCreditingIsRequired: tenant.invoiceCreditingIsRequired,
      isSignatureRequired: tenant.isSignatureRequired,
      isScrapApproveRequired: tenant.isScrapApproveRequired,
      accessToDeliveryPoint: tenant.accessToDeliveryPoint,
      cautionContract: tenant.cautionContract,
      defaultPartnerPermissionGroup: tenant.defaultPartnerPermissionGroup,
      accountantCsv: tenant.accountantCsv,
      accountantEmail: tenant.accountantEmail,
      accountantName: tenant.accountantName,
      accountantInvoiceLink: tenant.accountantInvoiceLink,
      accountantXls: tenant.accountantXls,
      cashCounting: tenant.cashCounting,
      useWrapping: tenant.useWrapping,
    },
    tenantId: tenant.id,
  }); //istenantadmin superadmin

export const addTechnicalUserRequest = (
  tenantId: number,
  login: string,
  passwordHash: string,
  signatureKey: string,
  exchangeKey: string,
) =>
  axios.post(`${ENDPOINT}/add-technical-user`, {
    tenantId,
    login,
    passwordHash,
    signatureKey,
    exchangeKey,
  }); //ADMIN

export const technicalUserValidator = (
  tenantId: number,
  login: string,
  passwordHash: string,
  signatureKey: string,
  exchangeKey: string,
) =>
  axios.post(`${ENDPOINT}/technical-user-validate`, {
    tenantId,
    login,
    passwordHash,
    signatureKey,
    exchangeKey,
  }); //ADMIN

export const listItemByCompany = (companyId: string, tenantId: number) =>
  axios.post<GenericListResponse<RelItemCompany>>(
    `/rel-item-company/get-by-company`,
    {
      param: companyId,
      tenantId,
    },
  ); //REl_ITEM_COMPANY_ADMIN

export const createItemByCompany = (
  relList: RelItemCompany[],
  tenantId: number,
  companyId: string,
) => axios.post("/rel-item-company", { relList, tenantId, companyId }); //REl_ITEM_COMPANY_ADMIN

export const addInvoiceIcon = (tenantId?: string, invoiceIconFile?: File) => {
  const formData = new FormData();
  tenantId && formData.set("tenantId", tenantId);
  invoiceIconFile && formData.set("invoiceIconFile", invoiceIconFile);

  return axios.post(`${ENDPOINT}/add-invoice-icon`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
