import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import {
  AddBox,
  Assignment,
  Block,
  CheckCircleOutline,
  Edit,
} from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listTools } from "shared/network/tools.api";
import { TableState } from "views/Company/CompanyList/CompanyList";

const ToolList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-tool-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-tool-page-size") || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listToolsQuery = useQuery(
    [
      "listToolsQuery",
      page,
      pageSize,
      filterValue,
      sortState,
      selectedRelTenant.tenant.id,
    ],
    async () => {
      const { data } = await listTools(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("tool.formValues.name"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("tool.formValues.description"),
      flex: 1,
    },
    {
      field: "validTo",
      headerName: t("tool.formValues.validTo"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.validTo
          ? format(new Date(row.validTo), "yyyy.MM.dd.", {
              locale: hu,
            })
          : "-",
      type: "date",
    },
    {
      field: "price",
      headerName: t("tool.formValues.price"),
      flex: 1,
    },
    {
      field: "isActive",
      headerName: t("tool.status.title"),
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`tool.status.${row.isActive}`).toString()}>
          {row.isActive ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : (
            <Block style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "TOOL_GET",
          ]) && (
            <Tooltip title={t("tool.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                component={Link}
                to={`/tool-details/${row.id}`}
                style={{ margin: "0 8px" }}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "TOOL_ADMIN",
            "USER_ADMIN",
          ]) && (
            <Tooltip title={t("tool.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                component={Link}
                to={`/tool-modify/${row.id}`}
                style={{ margin: "0 8px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" alignItems="center" gridGap={8}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "TOOL_ADMIN",
          "USER_ADMIN",
        ]) && (
          <Button component={Link} to="/tool-create" startIcon={<AddBox />}>
            {t("tool.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/tool-details/${params.row.id}`);
    }
  }

  return (
    <PageableTable
      filterable={["name", "description", "validTo", "price"]}
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="tool"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listToolsQuery}
      onCellClick={onCellClick}
    />
  );
};

export default ToolList;
