import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  listWarehousePageable,
  transferItems,
} from "shared/network/warehouse.api";
import { Item, Warehouse } from "shared/types";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { useQuery } from "react-query";
import { Autocomplete } from "@material-ui/lab";
import { Fragment } from "react";
import { AddBox, Delete } from "@material-ui/icons";
import { NUMBER_FORMAT } from "config/constants";
import { COLORS } from "config/theme";
import { listItems } from "shared/network/item.api";
import FormCard from "components/FormCard";

export type ItemTransferValues = {
  fromWarehouse: Warehouse;
  toWarehouse: Warehouse;
  itemWithQuantityEntries: {
    quantity: string;
    itemEntry: Item | null;
  }[];
};
const ItemTransfer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const form = useForm<ItemTransferValues>();

  const warehouseListQuery = useQuery(
    ["planWarehouseListPageable"],
    async () => {
      const { data } = await listWarehousePageable(0, 1000000, tenant.id);

      return data.page.content;
    },
  );

  const { append, fields, remove } = useFieldArray({
    control: form.control,
    name: "itemWithQuantityEntries",
    keyName: "key",
  });

  const itemListQuery = useQuery(["itemListQuery"], async () => {
    const { data } = await listItems(0, 1000000, tenant.id, "purchasable:true");
    return data.page.content;
  });
  const onSubmitCreate = async (values: ItemTransferValues) => {
    try {
      await transferItems(
        {
          ...values,
          fromWarehouseId: values.fromWarehouse?.id,
          toWarehouseId: values.toWarehouse?.id,
        },
        tenant.id,
      );
      enqueueSnackbar(t("warehouse.itemTransfer.success"), {
        variant: "success",
      });
      form.reset();
    } catch {
      enqueueSnackbar(t("warehouse.itemTransfer.success"), {
        variant: "error",
      });
    }
  };
  return (
    <Container maxWidth="md">
      <FormCard title={t("warehouse.itemTransfer.warehouseTitle")}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="fromWarehouse"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={warehouseListQuery.data || []}
                      getOptionLabel={(option: Warehouse) => option.name}
                      getOptionSelected={option =>
                        option.id === field.value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("warehouse.itemTransfer.warehouseFrom")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="toWarehouse"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={warehouseListQuery.data || []}
                      getOptionLabel={(option: Warehouse) => option.name}
                      getOptionSelected={option =>
                        option.id === field.value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("warehouse.itemTransfer.warehouseTo")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {fields.map((field, index) => (
                  <Fragment key={index}>
                    <Grid container item xs={12} justifyContent="space-between">
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "8px 16px 8px 12px",
                          color: COLORS.main,
                          borderRadius: "50%",
                          fontSize: 18,
                        }}
                      >
                        {index + 1}. {t("common:item")}
                      </Typography>
                      <Tooltip title={t("common:button.delete").toString()}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => remove(index)}
                          style={{ margin: "8px 12px 8px 16px" }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid container spacing={2} item xs={12}>
                      <Grid item xs={8}>
                        <Controller
                          control={form.control}
                          name={`itemWithQuantityEntries.${index}.itemEntry`}
                          rules={{
                            required: t("validation.required").toString(),
                          }}
                          defaultValue={null}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              {...field}
                              onChange={(_, value) => field.onChange(value)}
                              options={itemListQuery.data || []}
                              getOptionLabel={(option: Item) => option.name}
                              getOptionSelected={option =>
                                option.id === field.value?.id
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                    required: true,
                                  }}
                                  label={t(
                                    "warehouse.transaction.formValues.item",
                                  )}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          control={form.control}
                          name={`itemWithQuantityEntries.${index}.quantity`}
                          defaultValue={""}
                          rules={{
                            required: t("validation.required").toString(),
                            validate: value => {
                              if (value && !value.match(NUMBER_FORMAT)) {
                                return t(
                                  "common:validation.numberFormat",
                                ).toString();
                              }
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              onChange={event => {
                                field.onChange(event.target.value);
                              }}
                              label={t("order.quantity")}
                              InputLabelProps={{
                                shrink: true,
                                required: true,
                              }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" mt={2} p={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={event => {
                        event.stopPropagation();
                        append({
                          itemEntry: null,
                          quantity: "",
                        });
                      }}
                      startIcon={<AddBox />}
                    >
                      {t("offer.relOfferItem.add")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              width="100%"
              p={2}
              gridGap={8}
            >
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </FormProvider>
        </form>
      </FormCard>
    </Container>
  );
};

export default ItemTransfer;
