import { Box, IconButton, Tooltip } from "@material-ui/core";
import { GetApp, Visibility } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listDeliveryNotePageable } from "shared/network/deliveryNote.api";
import {
  getDeliveryNoteFile,
  getDeliveryNoteFileURL,
} from "shared/network/files.api";
import {
  deliveryNoteDownload,
  deliveryNoteLinkOpen,
} from "shared/util/fileDownload";
import { TableState } from "views/Company/CompanyList/CompanyList";

const DeliveryNoteList = () => {
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-deliverynote-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-deliverynote-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const deliveryNoteListQuery = useQuery(
    [
      "deliveryNoteListQuery",
      page,
      pageSize,
      tenant.id,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await listDeliveryNotePageable(
        page,
        pageSize,
        tenant.id,
        id ? "companyId:" + id + ";" + filterValue : filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "deliveryNoteNumber",
      headerName: t("deliveryNote.formValues.deliveryNoteNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "contactName",
      headerName: t("deliveryNote.formValues.contactName"),
      flex: 1,
    },
    {
      field: "date",
      headerName: t("deliveryNote.formValues.date"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.date), "yyyy. MM. dd."),
      type: "date",
    },
    {
      field: "cashInHand",
      headerName: t("deliveryNote.formValues.cashInHand"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.cashInHand }) + " Ft",
    },
    {
      field: "status",
      headerName: t("delivery.formValues.status"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`delivery.status.${row.delivery.status}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteView").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={async () => {
                  if (row.id) {
                    try {
                      deliveryNoteLinkOpen(
                        getDeliveryNoteFileURL(row.id, tenant.id),
                      );
                    } catch {
                      enqueueSnackbar(
                        t("common:notification.download.failure", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "error",
                        },
                      );
                    }
                  }
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteDownload").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={async () => {
                  if (row.id) {
                    try {
                      const { data } = await getDeliveryNoteFile(
                        row.id,
                        tenant.id,
                      );
                      deliveryNoteDownload(
                        data,
                        t("deliveryNote.deliveryNoteFile"),
                      );
                      enqueueSnackbar(
                        t("common:notification.download.success", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "success",
                        },
                      );
                    } catch {
                      enqueueSnackbar(
                        t("common:notification.download.failure", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "error",
                        },
                      );
                    }
                  }
                }}
              >
                <GetApp />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {/*Export gomb*/}
        {/*<Button
          color="primary"
          variant="outlined"
          startIcon={<GetApp />}
          style={{ margin: "auto" }}
        >
          {t("common:button.export")}
        </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <Box mb={2.5}>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="deliverynote"
        filterable={[
          "deliveryNoteNumber",
          "contactName",
          "cashInHand",
          "status",
          "date",
        ]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={deliveryNoteListQuery}
      />
    </Box>
  );
};

export default DeliveryNoteList;
