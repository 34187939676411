import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { CompanySite } from "shared/types";
import { CompanySiteFormValues } from "views/Company/CompanySite/CompanySiteForm";

const ENDPOINT = "/company-site";

export const createCompanySite = (
  param: CompanySiteFormValues,
  tenantId: number
) => axios.post<GenericResponse<CompanySite>>(ENDPOINT, { param, tenantId }); //COMPANY_ADMIN

export const modifyCompanySite = (param: CompanySite, tenantId: number) =>
  axios.put(ENDPOINT, {
    param,
    tenantId,
  }); //COMPANY_ADMIN

export const deleteCompanySite = (param: number, tenantId: number) =>
  axios.delete(ENDPOINT, {
    data: { param, tenantId },
  }); //COMPANY_ADMIN

export const getCompanySiteList = () =>
  axios.get<GenericListResponse<CompanySite>>(`${ENDPOINT}/all`); //COMPANY_GET

export const getCompanySiteListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<CompanySite>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //COMPANY_GET
