import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanyUsersById } from "shared/network/company.api";
import { Company } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import RelCompanyCategoryList from "views/Admin/CompanyCategory/RelCompanyCategoryList";
import CommentList from "views/Comment/CommentList";
import CompanySite from "views/Company/CompanySite/CompanySite";
import ContactList from "views/Company/Contact/ContactList";
import NicknameList from "views/Nickname/NicknameList";
import BankAccountListPage from "../BankAccount/BankAccountListPage";

type Props = {
  company: Company | null;
  loading: boolean;
  refetch: () => void;
};

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

const CompanyDetails = ({ company, loading, refetch }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const [page, setPage] = useState(0);

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const tenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant.tenant
  );

  const companyUsersQuery = useQuery(["companyUsersQuery"], async () => {
    if (id) {
      const { data } = await getCompanyUsersById(
        Number.parseInt(id),
        tenant.id
      );
      return { ...data, items: data.items || [] };
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("userList.status." + row.status) || "-",
    },
  ];

  return loading || companyUsersQuery.isFetching ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="300px"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.cardHeader}>
          <Typography variant="h2">Adatok</Typography>
          <Button
            variant="text"
            component={Link}
            to={`/company-modify/${id}`}
            startIcon={<Edit />}
            style={{ height: 24 }}
          >
            {t("common:button.modify")}
          </Button>
        </Box>
        <Box className={classes.cardContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.name")}:
              </Typography>
              <Typography variant="body1">{company?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.taxNumber")}:
              </Typography>
              <Typography variant="body1">{company?.taxNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.registrationNumber")}:
              </Typography>
              <Typography variant="body1">
                {company?.registrationNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.email")}:
              </Typography>
              <Typography variant="body1">
                {company?.notificationEmail}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.phone")}:
              </Typography>
              <Typography variant="body1">{company?.phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.bankAccount")}:
              </Typography>
              <Typography variant="body1">{company?.bankAccount}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.isSupplier")}:
              </Typography>
              <Typography variant="body1">
                {company?.isSupplier ? t("common:YES") : t("common:NO")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.isCustomer")}:
              </Typography>
              <Typography variant="body1">
                {company?.isCustomer ? t("common:YES") : t("common:NO")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body2">
                {t("company.formValues.baseLanguage")}:
              </Typography>
              <Typography variant="body1">
                {company?.baseLanguage
                  ? t(`common:language.${company?.baseLanguage}`)
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t("company.addressTitle")}:
              </Typography>
              <Typography variant="body1">
                {displayAddress(company?.address)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.cardHeader}>
          <Typography variant="h2">{t("company.baseCurrencyTitle")}</Typography>
        </Box>
        <Box className={classes.cardContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body2">
                {t("company.formValues.baseCurrency.name")}:
              </Typography>
              <Typography variant="body1">
                {company?.baseCurrency.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body2">
                {t("company.formValues.baseCurrency.code")}:
              </Typography>
              <Typography variant="body1">
                {company?.baseCurrency.code}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body2">
                {t("company.formValues.baseCurrency.prefix")}:
              </Typography>
              <Typography variant="body1">
                {company?.baseCurrency.prefix || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body2">
                {t("company.formValues.baseCurrency.postfix")}:
              </Typography>
              <Typography variant="body1">
                {company?.baseCurrency.postfix || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          {!!company && <NicknameList company={company} refetch={refetch} />}
        </Box>
        <RelCompanyCategoryList />
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMMENT_ADMIN",
        ]) &&
          !!id && <CommentList paramId={id} />}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box mb={2}>
          <Box className={classes.cardHeader}>
            <Typography variant="h2">{t("company.users")}</Typography>
          </Box>
          <SingleQueryTable
            query={companyUsersQuery}
            columns={columns}
            page={page}
            setPage={setPage}
          />
        </Box>
        <Box mb={2}>
          <ContactList isCompany />
        </Box>
        <Box mb={2}>
          <CompanySite isCompany />
        </Box>
        <Box mb={2}>
          <BankAccountListPage isCompany />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompanyDetails;
