import axios from "config/axios";
import { GenericListResponse, GenericPageResponse } from "shared/common";
import {
  Address,
  Delivery,
  Department,
  Item,
  Order,
  RelItemDelivery,
  Warehouse,
} from "shared/types";
import { DeliveryNewItem } from "views/Delivery/DeliveryAddItemForm";
import { VehicleCashData } from "views/Vehicle/VehicleCashByVehicle";

export type DeliveryRequest = {
  vehicleId: string | number;
  date: string | number;
  fromWarehouse: Partial<Warehouse>;
  order: Order;
  deliveryOrder: 1;
};

export type InsideDeliveryRequest = {
  vehicleId: string | number;
  date: string;
  sourceAddress?: Address;
  fromWarehouse?: Warehouse;
  fromDepartment?: Department;
  targetAddress?: Address;
  toWarehouse?: Warehouse;
  toDepartment?: Department;
};

export type RelItemDeliveryItemModify = {
  item?: Item | null;
  quantity?: number | string | null;
  deliveryId?: number | string | null;
  price?: number | string | null;
  createdBy?: number | string | null;
  updatedBy?: number | string | null;
  id?: number | string | null;
};

export type DeliveryItemModifyRequest = {
  deliveryEntry: Delivery;
  relList: RelItemDeliveryItemModify[];
};

const ENDPOINT = "/delivery";

export const createDelivery = (
  deliveryEntry: DeliveryRequest,
  tenantId: number,
) => axios.post(`${ENDPOINT}/create`, { deliveryEntry, tenantId }); //DELIVERY_ADMIN

export const createInsideDelivery = (
  deliveryEntry: InsideDeliveryRequest,
  relList: { item: Item; quantity: number }[],
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/create-inside`, {
    deliveryEntry,
    relList,
    tenantId,
  }); //DELIVERY_ADMIN

export const deleteDelivery = (param: number, tenantId: number) =>
  axios.delete(ENDPOINT, { data: { param, tenantId } }); //DELIVERY_ADMIN

export const getDeliveryByTenantIdAndVehicleIdAndDate = (
  tenantId: number,
  vehicleId: number,
  date: string,
) =>
  axios.post<GenericListResponse<Delivery>>(
    `${ENDPOINT}/list-by-vehicle-and-date`,
    {
      tenantId,
      vehicleId,
      date,
    },
  ); //DELIVERY_GET

export const getCashByVehicle = (tenantId: number, date: string) =>
  axios.get<GenericListResponse<VehicleCashData>>(
    `${ENDPOINT}/cash-by-vehicles?tenantId=${tenantId}&date=${date}`,
  ); //DELIVERY_GET

export const getAllDelivery = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Delivery>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //DELIVERY_GET

export const updateDeliveryStatus = (
  status: string,
  deliveryId: string,
  tenantId: string,
  comment?: string,
  contactName?: string,
  files?: {
    file?: File;
    transferorSignature?: File;
    recipientSignature?: File;
  },
) => {
  const formData = new FormData();
  formData.set("deliveryId", deliveryId);
  formData.set("tenantId", tenantId);
  formData.set("status", status);
  contactName && formData.set("contactName", contactName);
  comment && formData.set("comment", comment);
  files?.file && formData.set("file", files?.file);
  files?.transferorSignature &&
    formData.set("transferorSignature", files?.transferorSignature);
  files?.recipientSignature &&
    formData.set("recipientSignature", files?.recipientSignature);

  return axios.post(`${ENDPOINT}/update-status`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //DELIVERY_ADMIN

export const updateDeliveryItemQuantity = (
  param: RelItemDelivery,
  tenantId: number,
) => axios.put(`${ENDPOINT}/update-quantity`, { param, tenantId });

export const addDeliveryItem = (param: DeliveryNewItem, tenantId: number) =>
  axios.put(`${ENDPOINT}/add-item`, { param, tenantId }); //DELIVERY_ADMIN

export const modifyDelivery = (
  deliveryRequest: DeliveryItemModifyRequest,
  tenantId: number,
) => axios.put(`${ENDPOINT}`, { ...deliveryRequest, tenantId });
