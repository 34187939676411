import axios from "config/axios";

const ENDPOINT = "/reldepartmentemployee";

export const createRelDepartmentEmployee = (
  param: { employeeId: string; departmentId: number; tenantId: number },
  tenantId: number
) => axios.post(ENDPOINT, { param, tenantId }); //DEPARTMENT_ADMIN

export const deleteRelDepartmentEmployee = (
  employeeId: string,
  departmentId: number,
  tenantId: number
) =>
  axios.delete(`${ENDPOINT}/delete`, {
    data: { employeeId, departmentId, tenantId },
  }); //DEPARTMENT_ADMIN
