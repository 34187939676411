import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { BaseCurrency, Company, Offer } from "shared/types";

const ENDPOINT = "/offer";

type OfferRequest = {
  tenantId: number;
  company: Company;
  name: string;
  status: string;
  type: string;
  description: string;
  validTo: string;
  discount: string;
  currency: BaseCurrency | null;
  relOfferItems: {
    itemId: string | number;
    price: string | number;
    constructionNumber: string;
  }[];
};

export const createOffer = (param: OfferRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId }); //OFFER_ADMIN

export const getOfferById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Offer>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //OFFER_GET

export const listOfferByProject = (param: number | string, tenantId: number) =>
  axios.post<GenericListResponse<Offer>>(`${ENDPOINT}/list-by-project`, {
    param,
    tenantId,
  }); //OFFER_GET

export const listOfferByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Offer>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //OFFER_GET

export const acceptOffer = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/accept`, { param, tenantId }); //OFFER_GET

export const declineOffer = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/decline`, { param, tenantId }); //OFFER_GET
