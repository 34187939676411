export function getCommentSubjectType(pathname: string) {
  if (pathname.includes("/user/")) {
    return "USER";
  } else if (pathname.includes("/tool-details/")) {
    return "TOOL";
  } else if (pathname.includes("/contact-details/")) {
    return "CONTACT";
  } else if (pathname.includes("/issue-details/")) {
    return "ISSUE";
  } else if (pathname.includes("/item/")) {
    return "ITEM";
  }
  switch (pathname) {
    case "/profile/details":
      return "USER_PROFILE";
    case "/company/details":
      return "COMPANY";
    case "/project-details/details":
      return "PROJECT";
    case "/vehicle-details/details":
      return "VEHICLE";
    case "/employee/details":
      return "EMPLOYEE";
    default:
      return "";
  }
}

export function getSubjectId(
  subjectType: string,
  accountId: string,
  queryId?: string | null,
  notQueryId?: string | null
) {
  switch (subjectType) {
    case "USER_PROFILE":
      return accountId;
    case "USER":
      return notQueryId;
    case "TOOL":
      return notQueryId;
    case "CONTACT":
      return notQueryId;
    case "ISSUE":
      return notQueryId;
    case "ITEM":
      return notQueryId;
    default:
      return queryId;
  }
}
