import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { BaseCurrency, Salary } from "shared/types";

const ENDPOINT = "/salary";

type SalaryRequest = {
  id?: string | number;
  employee: {
    id: string | number;
  };
  salaryCurrency: BaseCurrency | null;
  salary: string | number;
  dateFrom: string;
  dateTo?: string;
};

export const listSalaryByEmployee = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<Salary>>(`${ENDPOINT}/list-by-employee`, {
    param,
    tenantId,
  }); //SALARY_GET

export const getSalaryById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Salary>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const modifySalary = (param: SalaryRequest, tenantId: number) =>
  axios.put<GenericResponse<Salary>>(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const createSalary = (param: SalaryRequest, tenantId: number) =>
  axios.post<GenericResponse<Salary>>(`${ENDPOINT}/create`, {
    param,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const deleteSalary = (salaryId: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete?param=${salaryId}&tenantId=${tenantId}`); //EMPLOYEE_ADMIN
