import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Clear, Done, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIncomingInvoice } from "shared/network/incoming-invoice.api";
import { TableState } from "views/Company/CompanyList/CompanyList";

const IncomingInvoiceList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-tenantincominginvoice-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-tenantincominginvoice-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const invoiceListQuery = useQuery(
    [
      "IncomingInvoiceListQuery",
      page,
      pageSize,
      tenant.id,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await listIncomingInvoice(
        page,
        pageSize,
        tenant.id,
        (id ? "customerId:" + id + ";" : "") + filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "supplierCompanyName",
      headerName: t("invoice.formValues.supplier.supplierSelect"),
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.creationDate), "yyyy. MM. dd."),
      type: "date",
    },
    {
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 1,
    },
    {
      field: "isPaid",
      headerName: t("incomingInvoice.isPaid.title"),
      flex: 1,

      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip title={t(`incomingInvoice.isPaid.true`).toString()}>
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`incomingInvoice.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_GET",
          ]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                component={Link}
                to={`/incoming-invoice-details/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modify").toString()}>
              <IconButton
                component={Link}
                to={`/incoming-invoice-modify/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/incoming-invoice-create"
            startIcon={<AddBox />}
          >
            {t("incomingInvoice.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/incoming-invoice-details/${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="tenantincominginvoice"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={invoiceListQuery}
      onCellClick={onCellClick}
    />
  );
};
export default IncomingInvoiceList;
