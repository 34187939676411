import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = Omit<SvgIconProps, "viewBox" | "fill">;

const ItemIcon = (props: Props) => {
  const width = props.width ?? 512;
  const height = props.height ?? 512;

  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M389.03,234.667c10.135-11.322,16.303-26.269,16.303-42.667c0-35.355-28.645-64-64-64H320v-21.333h21.333
          c11.782,0,21.333-9.551,21.333-21.333v-64C362.667,9.551,353.115,0,341.333,0H170.667c-11.782,0-21.333,9.551-21.333,21.333v64
          c0,11.782,9.551,21.333,21.333,21.333H192V128h-21.333c-35.355,0-64,28.645-64,64c0,16.397,6.169,31.344,16.303,42.667
          c-10.135,11.322-16.303,26.269-16.303,42.667s6.169,31.344,16.303,42.667c-10.135,11.322-16.303,26.269-16.303,42.667
          s6.169,31.344,16.303,42.667c-10.135,11.322-16.303,26.269-16.303,42.667c0,35.355,28.645,64,64,64h170.667
          c35.355,0,64-28.645,64-64c0-16.397-6.169-31.344-16.303-42.667c10.135-11.322,16.303-26.269,16.303-42.667
          S399.165,331.322,389.03,320c10.135-11.322,16.303-26.269,16.303-42.667S399.165,245.989,389.03,234.667z M362.667,362.667
          c0,11.791-9.542,21.333-21.333,21.333H170.667c-11.791,0-21.333-9.542-21.333-21.333s9.542-21.333,21.333-21.333h170.667
          C353.125,341.333,362.667,350.875,362.667,362.667z M170.667,298.667c-11.791,0-21.333-9.542-21.333-21.333
          S158.875,256,170.667,256h170.667c11.791,0,21.333,9.542,21.333,21.333s-9.542,21.333-21.333,21.333H170.667z M192,42.667h128V64
          h-21.333h-85.333H192V42.667z M234.667,106.667h42.667V128h-42.667V106.667z M170.667,170.667h42.667h85.333h42.667
          c11.791,0,21.333,9.542,21.333,21.333s-9.542,21.333-21.333,21.333H170.667c-11.791,0-21.333-9.542-21.333-21.333
          S158.875,170.667,170.667,170.667z M341.333,469.333H170.667c-11.791,0-21.333-9.542-21.333-21.333s9.542-21.333,21.333-21.333
          h170.667c11.791,0,21.333,9.542,21.333,21.333S353.125,469.333,341.333,469.333z"
      />
    </SvgIcon>
  );
};

export default ItemIcon;
