import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Assignment, Edit, Visibility } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getDeliveryByTenantIdAndVehicleIdAndDate } from "shared/network/delivery.api";
import { getDeliveryNoteByDeliveryId } from "shared/network/deliveryNote.api";
import { getDeliveryNoteFileURL } from "shared/network/files.api";
import { Delivery } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { deliveryNoteLinkOpen } from "shared/util/fileDownload";
import AllDeliveryStatusCell from "views/Admin/Tenants/AllDeliveryStatusCell";
import DeliveryItemDialog from "./DeliveryItemDialog";
import DeliveryStatusDialog from "./DeliveryStatusDialog";

export type DeliveryDateFormValues = {
  date: Date;
};

const DeliveryList = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const query = new URLSearchParams(location.search);
  const vehicleId = query.get("id");
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [openId, setOpenId] = useState<string | number | null>(null);
  const [statusDelivery, setStatusDelivery] = useState<Delivery | null>(null);
  const [page, setPage] = useState(0);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const deliveryListQuery = useQuery(
    ["deliveryListQueryVehicles", date, vehicleId, selectedRelTenant.tenant.id],
    async () => {
      if (vehicleId) {
        const { data } = await getDeliveryByTenantIdAndVehicleIdAndDate(
          selectedRelTenant.tenant.id,
          Number.parseInt(vehicleId),
          format(date, "yyyy-MM-dd", { locale: hu }),
        );

        return data;
      }
      return Promise.reject();
    },
  );

  async function getDeliveryNote(deliveryId: number) {
    const { data } = await getDeliveryNoteByDeliveryId(
      deliveryId,
      selectedRelTenant.tenant.id,
    );
    return data.item;
  }

  async function viewDeliveryNote(deliveryId: number) {
    try {
      const deliveryNoteId = (await getDeliveryNote(deliveryId)).id;
      try {
        if (deliveryNoteId) {
          deliveryNoteLinkOpen(
            getDeliveryNoteFileURL(
              deliveryNoteId.toString(),
              selectedRelTenant.tenant.id,
            ),
          );
        } else {
          enqueueSnackbar(
            t("common:notification.deliveryNote.notExist", {
              subject: t("deliveryNote.subject"),
            }),
            {
              variant: "error",
            },
          );
        }
      } catch {
        enqueueSnackbar(
          t("common:notification.download.failure", {
            subject: t("deliveryNote.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.deliveryNote.notExist", {
          subject: t("deliveryNote.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "companySite",
      headerName: t("delivery.formValues.partnerName"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.companySiteId?.company ? row.companySiteId?.company?.name : "-",
    },
    {
      field: "sourceAddress",
      headerName: t("delivery.formValues.sourceAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.sourceAddress ? displayAddress(row.sourceAddress) : "-",
    },
    {
      field: "targetAddress",
      headerName: t("delivery.formValues.destinationAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.targetAddress ? displayAddress(row.targetAddress) : "-",
    },
    {
      field: "fromWarehouse.name",
      headerName: t("delivery.formValues.fromWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.fromWarehouse ? row.fromWarehouse.name : "-",
    },
    {
      field: "toWarehouse.name",
      headerName: t("delivery.formValues.toWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.toWarehouse ? row.toWarehouse.name : "-",
    },
    {
      field: "status",
      headerName: t("delivery.formValues.status"),
      headerAlign: "center",
      flex: 1,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) =>
        !row.isInsideDelivery && (
          <AllDeliveryStatusCell
            delivery={row as Delivery}
            refetch={deliveryListQuery.refetch}
            tenant={selectedRelTenant.tenant}
          />
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {!row.isInsideDelivery &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "DELIVERY_ADMIN",
            ]) && (
              <Tooltip title={t("delivery.modifyStatus").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => setStatusDelivery(row as Delivery)}
                  disabled={["DELIVERED", "BILLED"].includes(
                    (row as Delivery).status.toString(),
                  )}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteView").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={async () => {
                  viewDeliveryNote(row.id);
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ADMIN",
          ]) && (
            <Tooltip title={t("delivery.showItems").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setOpenId(row.id)}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          <DeliveryItemDialog
            selectedDelivery={row as Delivery}
            openId={openId}
            setOpenId={setOpenId}
            refetch={deliveryListQuery.refetch}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {/*Export gomb*/}
        {/*<Button
          color="primary"
          variant="outlined"
          startIcon={<GetApp />}
          style={{ margin: "auto" }}
        >
          {t("common:button.export")}
        </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <>
      <DeliveryStatusDialog
        selectedDelivery={statusDelivery}
        setSelectedDelivery={setStatusDelivery}
        refetch={deliveryListQuery.refetch}
      />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={3} style={{ marginRight: 8, marginBottom: 24 }}>
          <KeyboardDatePicker
            size="small"
            value={date}
            onChange={date => {
              date && setDate(date);
            }}
            label={t("delivery.formValues.date")}
            format="yyyy.MM.dd."
            InputLabelProps={{ shrink: true, required: true }}
            variant="inline"
            autoOk
            ref={undefined}
          />
        </Grid>
      </Grid>
      <SingleQueryTable
        query={deliveryListQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default DeliveryList;
