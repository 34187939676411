import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Invoice } from "shared/types";

const ENDPOINT = "/invoice";

export const createInvoice = (param: Invoice, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId }); //INVOICE_ADMIN

export const modifyInvoice = (param: Invoice, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId }); //INVOICE_ADMIN

export const approveInvoice = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/approve`, { params: [param], tenantId }); //INVOICE_GET

export const getInvoiceById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Invoice>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //INVOICE_GET

export const listInvoicePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<Invoice>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //INVOICE_GET

export const updateInvoiceStatus = (
  tenantId: number,
  status: string,
  invoiceId?: number
) =>
  axios.put(
    `${ENDPOINT}/update-status?tenantId=${tenantId}&status=${status}&invoiceId=${invoiceId}`
  ); //INVOICE_GET

export const exportInvoiceCSV = (
  tenantId: number,
  startDate: string,
  endDate: string
) =>
  axios.get(
    `${ENDPOINT}/csv-export?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`
  ); //INVOICE_GET
