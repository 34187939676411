import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import Loading from "components/Loading";
import { COMMENT_TYPE } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { createComment } from "shared/network/comment.api";

export type CommentFormValues = {
  tenantId: number;
  type: string;
  originalFileName: string;
  subjectType: string;
  subjectId: number;
  note: string;
  document: FileList;
};
type Props = {
  subjectType:
    | "USER"
    | "TOOL"
    | "CONTACT"
    | "ISSUE"
    | "ITEM"
    | "USER_PROFILE"
    | "COMPANY"
    | "PROJECT"
    | "VEHICLE"
    | "EMPLOYEE"
    | "";
  subjectId: string | null | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};

const CommentCreate = ({
  subjectType,
  subjectId,
  open,
  setOpen,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<CommentFormValues>();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const onSubmitCreate = async (values: CommentFormValues) => {
    setStatus("pending");
    try {
      if (subjectId && subjectType) {
        await createComment(
          tenant.id,
          values.type,
          subjectType,
          subjectId,
          values.note,
          values.document?.[0]
        );
        setStatus("success");
        setOpen(false);
        refetch();
        form.reset();
      }
    } catch {
      setStatus("failure");
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("comment.subject"),
        }),
        { variant: "error" }
      );
    }
  };
  const type = form.watch("type");
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <Loading open={status === "pending"} />
      <DialogTitle>{t("common:comment")}</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="type"
                  defaultValue="TEXT"
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("comment.type.title")}
                      error={!!fieldState.error}
                      SelectProps={{ displayEmpty: true }}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem disabled value="">
                        {t("common:choose")}
                      </MenuItem>
                      {COMMENT_TYPE.map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(`comment.type.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {type === "DOCUMENT" && (
                <Grid item container xs={12} justifyContent="center">
                  <Button
                    variant="text"
                    component="label"
                    htmlFor="noteFile"
                    startIcon={
                      !form.watch("document")?.length ? (
                        <AttachFile />
                      ) : (
                        <Check style={{ color: COLORS.green }} />
                      )
                    }
                  >
                    {t("personalData.formValues.chooseFile")}
                    <input
                      id="noteFile"
                      style={{ display: "none" }}
                      type="file"
                      {...form.register("document", {
                        required: {
                          value: false,
                          message: "Fájl kiválasztása kötelező",
                        },
                        validate: (value) => {
                          if (value?.[0]?.size >= 5242880) {
                            return t("validation.maxSize", {
                              size: 5,
                              unit: "MB",
                            }).toString();
                          }
                        },
                      })}
                    />
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="note"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={5}
                      label={t("comment.note")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" m={2} gridGap={8}>
              <Button
                color="primary"
                variant="text"
                onClick={() => setOpen(false)}
              >
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.create")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default CommentCreate;
