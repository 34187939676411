//ISSUE_TEMPLATE_ADMIN
import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { IssueType } from "shared/types";

const ENDPOINT = "/issue-type";

export const createIssueType = (param: IssueType, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //ISSUE_TYPE_ADMIN

export const modifyIssueType = (param: IssueType, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId }); //ISSUE_TYPE_ADMIN

export const listIssueTypeByTenant = (tenantId: number) =>
  axios.post<GenericListResponse<IssueType>>(`${ENDPOINT}/list-by-tenant`, {
    tenantId,
  }); //ISSUE_TYPE_GET

export const getIssueType = (param: number, tenantId: number) =>
  axios.post<GenericResponse<IssueType>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //ISSUE_TYPE_GET

export const listIssueTypePageable = (
  page: number,
  size: number,
  tenantId: number,
  search = "",
) =>
  axios.get<GenericPageResponse<IssueType>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}`,
  ); //ISSUE_TYPE_GET

export const listIssueTypeByTenantAndCompany = (
  companyId: number,
  tenantId: number,
) =>
  axios.post<GenericListResponse<IssueType>>(
    `${ENDPOINT}/list-by-tenant-and-company`,
    { companyId, tenantId },
  );
