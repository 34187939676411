import axios from "config/axios";
import { CompanyNickname } from "shared/types";

const ENDPOINT = "/company-nickname";

export const createCompanyNickname = (
  values: CompanyNickname,
  tenantId: number
) => axios.post(`${ENDPOINT}/create`, { param: values, tenantId: tenantId }); //COMPANY_NICKNAME_ADMIN

export const modifyCompanyNickname = (
  param: { nickname: string; id?: number; tenantId: number },
  tenantId: number
) => axios.put(`${ENDPOINT}/modify`, { param, tenantId: tenantId }); //COMPANY_NICKNAME_ADMIN

export const getCompanyNicknameById = (id: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param: id, tenantId: tenantId }); //COMPANY_NICKNAME_GET

export const deleteCompanyNickname = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete`, {
    data: { param: id, tenantId: tenantId },
  }); //COMPANY_NICKNAME_ADMIN
