import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { hasAuthority } from "shared/authorization";
import { listComment } from "shared/network/comment.api";
import { Comment } from "shared/types";
import CommentCreate from "./CommentCreate";
import CommentRow from "./CommentRow";
import { getCommentSubjectType, getSubjectId } from "./function";

type Props = {
  paramId: string;
};

const CommentList = ({ paramId }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const subjectType = getCommentSubjectType(location.pathname);
  const [open, setOpen] = useState(false);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const id = getSubjectId(
    subjectType,
    account.user.id.toString(),
    query.get("id"),
    paramId
  );

  const commentListQuery = useQuery(
    ["commentList", tenant.id, subjectType, id],
    async () => {
      if (id != null) {
        const { data } = await listComment(tenant.id, subjectType, id);
        return data.items;
      }
      return Promise.reject();
    }
  );

  return (
    <Card>
      <Loading open={commentListQuery.isFetching} />
      <CommentCreate
        subjectType={subjectType}
        subjectId={id}
        open={open}
        setOpen={setOpen}
        refetch={commentListQuery.refetch}
      />
      <CardHeader
        disableTypography
        title={
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            gridGap={8}
          >
            <Typography variant="h2">{t("comment.title")}</Typography>
            {hasAuthority(
              account.user,
              account.permissions,
              selectedRelTenant,
              ["COMMENT_ADMIN"]
            ) && (
              <Button
                color="primary"
                size="small"
                variant="text"
                startIcon={<AddBox />}
                onClick={() => setOpen(true)}
              >
                {t("comment.create")}
              </Button>
            )}
          </Box>
        }
      />
      <CardContent>
        {commentListQuery.isError ? (
          <Typography>{t("common:errorWhileFetching")}</Typography>
        ) : commentListQuery.data?.length === 0 ? (
          <Typography>{t("comment.noComment")}</Typography>
        ) : (
          commentListQuery.data?.map((comment: Comment) => (
            <CommentRow key={comment.id} comment={comment} />
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default CommentList;
