import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteSalary, listSalaryByEmployee } from "shared/network/salary.api";

const SalaryList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const salaryList = useQuery(["salaryList", id], async () => {
    if (id) {
      const { data } = await listSalaryByEmployee(id, tenant.id);
      return data;
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "superNet",
      headerName: t("salary.formValues.superNet"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.superNet }) +
        " " +
        row.salaryCurrency.name,
    },
    {
      field: "salary",
      headerName: t("salary.formValues.salary"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.salary }) + " " + row.salaryCurrency.name,
    },
    {
      field: "superGross",
      headerName: t("salary.formValues.superGross"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.superGross }) +
        " " +
        row.salaryCurrency.name,
    },
    {
      field: "dateFrom",
      headerName: t("salary.formValues.dateFrom"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.dateFrom), "yyyy. MM. dd."),
    },
    {
      field: "dateTo",
      headerName: t("salary.formValues.dateTo"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.dateTo ? format(new Date(row.dateTo), "yyyy. MM. dd.") : "",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("salary.modify").toString()}>
                <IconButton
                  component={Link}
                  to={`/salary-modify/${row.id}`}
                  size="small"
                  color="primary"
                >
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("salary.delete").toString()}>
                <IconButton
                  onClick={() => {
                    if (row.id) {
                      removeSalary(row.id);
                    }
                  }}
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                >
                  <Delete color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeSalary(id: number) {
    try {
      await deleteSalary(id, tenant.id);
      salaryList.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("salary.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("salary.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to={`/salary-create/${id}`}
            startIcon={<AddBox />}
          >
            {t("salary.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={salaryList}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};

export default SalaryList;
