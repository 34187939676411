import axios from "config/axios";

const ENDPOINT = "/files";

export const getDeliveryNoteFile = (param: string, tenantId: number) =>
  axios.get(`${ENDPOINT}/delivery-note/${param}`, {
    responseType: "blob",
  });

export const getDeliveryNoteFileURL = (param: string, tenantId: number) => {
  return axios.defaults.baseURL + ENDPOINT + `/delivery-note/${param}`;
};

export const getInvoiceFile = (param: string, tenantId: number) =>
  axios.get(`${ENDPOINT}/invoice/${param}`, {
    responseType: "blob",
  });
