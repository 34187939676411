import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import { huHU } from "components/PageableTable/huHU";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteCustomField,
  getCustomFields,
} from "shared/network/custom-fields.api";

const CustomFields = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const [page, setPage] = useState(0);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const listCustomFieldsQuery = useQuery(["listBankAccountQuery"], async () => {
    const { data } = await getCustomFields(tenant.id);
    return data.items;
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Mezőnév",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Típus",
      flex: 1,
      valueGetter: ({ row }) => t(`common:customFieldType.${row.type}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "CUSTOM_FIELDS_ADMIN",
          ]) && (
            <>
              <Tooltip title={"Módosítás"}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={`/custom-fields-modify/${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("bankAccount.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => remove(row.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function remove(id: number) {
    try {
      await deleteCustomField(id, selectedRelTenant.tenant.id);
      listCustomFieldsQuery.refetch();
      enqueueSnackbar("Sikeres törlés.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Siketelen törlés", { variant: "error" });
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "CUSTOM_FIELDS_ADMIN",
        ]) && (
          <Button
            variant="contained"
            to="/custom-fields-create"
            component={Link}
            startIcon={<AddBox />}
          >
            Új mező
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Box mb={2.5}>
      <DataGrid
        autoHeight
        localeText={huHU}
        loading={listCustomFieldsQuery.isFetching}
        error={listCustomFieldsQuery.isError ? true : undefined}
        rows={listCustomFieldsQuery.data || []}
        columns={columns}
        page={page}
        pageSize={10}
        rowCount={listCustomFieldsQuery.data?.length}
        rowsPerPageOptions={[]}
        pagination
        paginationMode="client"
        onPageChange={setPage}
        disableColumnMenu
        disableSelectionOnClick
      />
    </Box>
  );
};

export default CustomFields;
