import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { RootState } from "config/store";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  listAuditLogByTenantPageable,
  listAuditLogPageable,
} from "shared/network/auditlog.api";
import { Pagination } from "@material-ui/lab";
import AuditLogRow from "./AuditLogRow";
import AuditLogForm from "./AuditlogForm";
import Loading from "components/Loading";
import { COLORS } from "config/theme";

export type AuditLogFilter = {
  createdOn: string;
  userId: string;
  type: string;
  message: string;
  status: string;
};

const useStyles = makeStyles({
  userListTitle: {
    color: "secondary",
    fontSize: "16px",
    fontWeight: "bold",
  },
  header: {
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const AuditLog = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(
    parseInt(
      window.sessionStorage.getItem("AuditLogPageNumber") || JSON.stringify(0)
    )
  );

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const { user } = useSelector(
    (state: RootState) => state.authentication.account
  );

  const auditLogQuery = useQuery(["auditLogQuery", page, filter], async () => {
    if (user?.isSuperAdmin) {
      const { data } = await listAuditLogPageable(page, 10, tenant.id, filter);
      return data;
    } else {
      const { data } = await listAuditLogByTenantPageable(
        page,
        10,
        tenant.id,
        filter
      );
      return data;
    }
  });

  const form = useForm<AuditLogFilter>({
    shouldUnregister: false,
  });

  const onSubmit = (values: AuditLogFilter) => {
    setFilter("");
    setPage(0);
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        if (key === "user") {
          Object.entries(value).forEach(([key2, value2]) => {
            setFilter(
              (state) => `${state}${state ? ";" : ""}user.${key2}:${value2}`
            );
          });
        } else {
          setFilter((state) => `${state}${state ? ";" : ""}${key}:${value}`);
        }
      }
    });
    auditLogQuery.refetch();
  };

  return (
    <>
      <Loading open={auditLogQuery.isFetching} />
      {auditLogQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
        </Box>
      ) : (
        <>
          {auditLogQuery.data?.page.totalElements === 0 ? (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:errorWhileFetching")}
              </Typography>
            </Box>
          ) : (
            <Box style={{ minWidth: 1000 }}>
              <Grid container className={classes.header}>
                <Grid item xs={2} className={classes.cell}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("auditLog.columns.userName")}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("auditLog.columns.message")}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.cell}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("auditLog.columns.type")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("auditLog.columns.status")}
                  </Typography>
                </Grid>
              </Grid>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormProvider {...form}>
                  <AuditLogForm />
                </FormProvider>
              </form>
              {!!auditLogQuery.data?.page.content.length &&
                auditLogQuery.data?.page.content?.map((log, index, array) => (
                  <AuditLogRow
                    key={log.id}
                    log={log}
                    array={array}
                    index={index}
                  />
                ))}
            </Box>
          )}
          {auditLogQuery.data && auditLogQuery.data?.page.totalPages > 1 && (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "24px 0",
              }}
              count={auditLogQuery?.data?.page.totalPages}
              color="primary"
              page={auditLogQuery.data?.page.number + 1}
              onChange={(e, page) => {
                sessionStorage.setItem(
                  "AuditLogPageNumber",
                  JSON.stringify(page - 1)
                );
                setPage(page - 1);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default AuditLog;
