import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import DeliveryRoundList from "views/DeliveryRound/DeliveryRoundList";
import CompanyList from "./CompanyList/CompanyList";
//import CompanySite from "./CompanySite/CompanySite";
//import ContactList from "./Contact/ContactList";

export const COMPANY_TABS = [
  "/companies/list-all",
  "/companies/list-customer",
  "/companies/list-supplier",
  "/companies/delivery-round",
  /*"/companies/company-site",
  "/companies/contact",*/
];
const CompanyTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Container maxWidth="xl">
      {location.pathname === "/companies" && (
        <Redirect to="/companies/list-all" />
      )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("company.titles.all")}
            component={Link}
            to={COMPANY_TABS[0]}
            value={COMPANY_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("company.titles.customer")}
            component={Link}
            to={COMPANY_TABS[1] + `?isCustomer=true`}
            value={COMPANY_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("company.titles.supplier")}
            component={Link}
            to={COMPANY_TABS[2] + `?isSupplier=true`}
            value={COMPANY_TABS[2]}
          />
        )}
        {selectedRelTenant.tenant.accessToDeliveryPoint &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
          ]) && (
            <Tab
              label={t("company.titles.deliveryRound")}
              component={Link}
              to={COMPANY_TABS[3]}
              value={COMPANY_TABS[3]}
            />
          )}
        {/*{hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_GET",
        ]) && (
          <Tab
            label={t("companySite.title")}
            component={Link}
            to={COMPANY_TABS[1]}
            value={COMPANY_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_ADMIN",
        ]) && (
          <Tab
            label={t("company.tabs.contacts")}
            component={Link}
            to={COMPANY_TABS[2]}
            value={COMPANY_TABS[2]}
          />
        )}*/}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={COMPANY_TABS[0]}
            component={CompanyList}
            permissions={["COMPANY_GET"]}
          />
          <PrivateRoute
            path={COMPANY_TABS[1]}
            component={CompanyList}
            permissions={["COMPANY_GET"]}
          />
          <PrivateRoute
            path={COMPANY_TABS[2]}
            component={CompanyList}
            permissions={["COMPANY_GET"]}
          />
          <PrivateRoute
            path={COMPANY_TABS[3]}
            component={DeliveryRoundList}
            permissions={["DELIVERY_ROUND_GET"]}
          />
          {/*<PrivateRoute
            path={COMPANY_TABS[1]}
            component={CompanySite}
            permissions={["COMPANY_GET"]}
          />
          <PrivateRoute
            path={COMPANY_TABS[2]}
            component={ContactList}
            permissions={["COMPANY_ADMIN"]}
          />*/}
        </Switch>
      </Box>
    </Container>
  );
};

export default CompanyTabs;
