import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { Vehicle } from "shared/types";
import CommentList from "views/Comment/CommentList";

type Props = {
  vehicle: Vehicle | null;
  loading: boolean;
};

const useStyles = makeStyles({
  title: {
    fontWeight: "bold",
  },
  data: {
    marginBottom: 14,
  },
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginBottom: 20,
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  dataHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
    width: "100%",
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 12,
    marginBottom: 20,
    width: "100%",
  },
});

const VehicleDetails = ({ vehicle, loading }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  return (
    <>
      <Loading open={loading} />
      <Box className={classes.dataHeader}>
        <Typography variant="h2">{t("vehicle.generalData")}</Typography>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "VEHCILE_ADMIN",
        ]) && (
          <Button
            color="primary"
            variant="text"
            component={Link}
            to={`/vehicle-modify/${id}`}
            startIcon={<Edit />}
            style={{ height: 24 }}
          >
            {t("common:button.modify")}
          </Button>
        )}
      </Box>
      <Box className={classes.dataContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.licensePlateNumber")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.licensePlateNumber || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.expenseIdentifier")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.expenseIdentifier || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.brand")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.brand || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.type")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.type || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.vehicleColor")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.vehicleColor || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.productionYear")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.productionYear || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.gearboxType")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.gearboxType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.propellant")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.propellant || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.chassisNumber")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.chassisNumber || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.motorCode")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.motorCode || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.cylinderCapacity")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.cylinderCapacity
                ? `${vehicle.cylinderCapacity} cm³`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.seatNumber")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.seatNumber || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.dataHeader}>
        <Typography variant="h2">{t("vehicle.storageData")}</Typography>
      </Box>
      <Box className={classes.dataContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.height")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.height ? `${vehicle.height} m` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.width")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.width ? `${vehicle.width} m` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.length")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.length ? `${vehicle.length} m` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.volume")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.volume ? `${vehicle.volume} m³` : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.dataHeader}>
        <Typography variant="h2">{t("vehicle.technicalData")}</Typography>
      </Box>
      <Box className={classes.dataContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.weightCapacity")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.weightCapacity ? `${vehicle.weightCapacity} kg` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.vehicleCategory")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.vehicleCategory || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.carDocumentsExpirationDate")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.carDocumentsExpirationDate
                ? format(
                    new Date(vehicle.carDocumentsExpirationDate),
                    "yyyy. MM. dd.",
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.maxPerformance")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.maxPerformance ? `${vehicle.maxPerformance} kW` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.tachographExpirationDate")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.tachographExpirationDate
                ? format(
                    new Date(vehicle.tachographExpirationDate),
                    "yyyy. MM. dd.",
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.allWeight")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.allWeight ? `${vehicle.allWeight} kg` : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.selfWeight")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.selfWeight ? `${vehicle.selfWeight} kg` : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.dataHeader}>
        <Typography variant="h2">{t("vehicle.otherData")}</Typography>
      </Box>
      <Box className={classes.dataContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.environmentalCategory")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.environmentalCategory || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.colEncumbrance")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.colEncumbrance || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.axleNumber")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.axleNumber || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.typeApproveNumber")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.typeApproveNumber || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.haulData")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.haulData || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.eurotaxCode")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.eurotaxCode || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.firstRegisteredDate")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.firstRegisteredDate
                ? format(new Date(vehicle.firstRegisteredDate), "yyyy. MM. dd.")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.registeredDateInHungary")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.registeredDateInHungary
                ? format(
                    new Date(vehicle.registeredDateInHungary),
                    "yyyy. MM. dd.",
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.title}>
              {t("vehicle.formValues.bodyType")}
            </Typography>
            <Typography className={classes.data}>
              {vehicle?.bodyType
                ? t(`vehicle.bodyType.${vehicle.bodyType}`)
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {hasAuthority(account.user, account.permissions, selectedRelTenant, [
        "COMMENT_ADMIN",
      ]) &&
        !!id && <CommentList paramId={id} />}
    </>
  );
};

export default VehicleDetails;
