import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, GetApp } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listItemByCompany } from "shared/network/tenant.api";

const RelItemCompanyList = () => {
  const history = useHistory();
  const { setHeaderButtons } = useHeader();
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const listItemsByCompany = useQuery(
    ["listItemsByCompanyQuery", id],
    async () => {
      if (id) {
        const { data } = await listItemByCompany(
          id,
          selectedRelTenant.tenant.id,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("item.name"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name,
    },
    {
      field: "item.productCode",
      headerName: t("item.productCode"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.productCode,
    },
    {
      field: "item.type",
      headerName: t("item.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`item.types.${row.item.type}`),
    },
    {
      field: "quantity",
      headerName: t("item.quantity"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.quantity }) +
        " " +
        t(`item.unit.${row.item.productUnit}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ITEM_GET",
          ]) && (
            <Tooltip title={t("item.itemDetails").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/item/details?id=${row.item.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ITEM_ADMIN",
        ]) && (
          <Button
            variant="contained"
            to={`/company-item-create/${id}`}
            component={Link}
            startIcon={<AddBox />}
            style={{ marginBottom: 8 }}
          >
            {t("item.itemCreate")}
          </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GetApp />}
          style={{ margin: "auto" }}
        >
          {t("common:button.export")}
        </Button>
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/item/${params.row.item.id}`);
    }
  }

  return (
    <Container>
      <SingleQueryTable
        query={listItemsByCompany}
        columns={columns}
        page={page}
        setPage={setPage}
        onCellClick={onCellClick}
      />
    </Container>
  );
};

export default RelItemCompanyList;
