import axios from "config/axios";
import { Permission, PermissionGroup, User } from "shared/types";

const ENDPOINT = "/rel-user-permission-group";

export type GetAccountResponse = {
  user: User;
  permissions: Permission[];
};

export const relateUserPermissionGroup = (
  permissionGroupEntries: PermissionGroup[],
  userId: number,
  tenantId: number
) =>
  axios.post(ENDPOINT, {
    permissionGroupEntries,
    userId,
    tenantId,
  }); //Permission_admin
