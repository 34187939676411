import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { CompanyCategory } from "shared/types";
import { useSnackbar } from "notistack";
import { deleteCompanyCategory } from "shared/network/company-category.api";
import { COLORS } from "config/theme";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

type Props = {
  companyCategory: CompanyCategory;
  refetch: () => void;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const CompanyCategoryRow = ({ companyCategory, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  async function removeCompanyCategory(id: number) {
    try {
      await deleteCompanyCategory(id, tenant.id);
      refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("companyCategory.subject"),
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      const error = e as any;
      if (error?.data?.status === "DEPENDENT") {
        enqueueSnackbar(t("companyCategory.inUse"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("companyCategory.subject"),
          }),
          {
            variant: "error",
          }
        );
      }
    }
  }

  return (
    <Grid container className={classes.row} style={{ alignItems: "center" }}>
      <Grid item xs={6} className={classes.cell}>
        <Typography className={classes.text}>{companyCategory.name}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent="flex-end"
        className={classes.cell}
      >
        <Tooltip
          title={t("companyCategory.delete").toString()}
          className={classes.cell}
        >
          <IconButton
            size="small"
            color="primary"
            style={{ margin: "0 8px" }}
            onClick={() => {
              if (companyCategory.id) {
                removeCompanyCategory(companyCategory.id);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default CompanyCategoryRow;
