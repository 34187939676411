import { Box, Button, IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { AddBox, Edit, LockOpen, PermIdentity } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DoneIcon from "@material-ui/icons/Done";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { Link as RouterLink } from "components/Router";
import { USER_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getUserById, listUsers, modifyUser } from "shared/network/user.api";
import { TableState } from "views/Company/CompanyList/CompanyList";
import PermissionGroupModal from "./components/PermissionGroupModal";

const UserList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<number | null>(null);

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-user-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-user-page-size") || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listUsersQuery = useQuery(
    [
      "listUsersQuery",
      page,
      pageSize,
      filterValue,
      sortState,
      selectedRelTenant.tenant.id,
    ],
    async () => {
      const { data } = await listUsers(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  async function goToEmployee(user: GridRowData) {
    const { data } = await getUserById(selectedRelTenant.tenant.id, user.id);
    const { employee } = data;

    if (employee?.id) {
      history.push(`/employee-modify/${employee.id}`);
    } else {
      history.push(
        `/employee-create?name=${encodeURIComponent(
          user.name,
        )}&email=${encodeURIComponent(user.email)}`,
      );
    }
  }

  async function ban(user: GridRowData) {
    try {
      if (user.status === "ACTIVE") {
        user.status = "BANNED";
      } else if (user.status === "BANNED") {
        user.status = "ACTIVE";
      }
      await modifyUser(user, selectedRelTenant.tenant.id);
      listUsersQuery.refetch();
      enqueueSnackbar(
        t("common:notification.ban.success", {
          subject: t("user.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.ban.failure", {
          subject: t("user.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "relUserCompany[0].company.name",
      headerName: t("company.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.relUserCompany?.[0]?.company
          ? row.relUserCompany?.[0]?.company.name
          : "-",
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      width: 120,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`userList.status.${row.status}`).toString()}>
          {row.status === "ACTIVE" ? (
            <CheckCircleOutlineIcon style={{ color: COLORS.green }} />
          ) : row.status === "NEEDS_ACTIVATION" ? (
            <DoneIcon />
          ) : (
            <BlockIcon style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
      type: "select",
    },
    {
      field: "actions",
      headerName: " ",
      width: 240,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "PERMISSION_GROUP_GET",
            "PERMISSION_ADMIN",
          ]) && (
            <Tooltip title={"Jogosultságok"}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setOpen(row.id)}
              >
                <LockOpen />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) ? (
            <>
              <Tooltip title={t("user.addAsEmployee").toString()}>
                <div>
                  <IconButton
                    size="small"
                    disabled={!!row.relUserCompany?.length}
                    color="primary"
                    style={{ margin: "0 8px" }}
                    onClick={() => goToEmployee(row)}
                  >
                    <PermIdentity />
                  </IconButton>
                </div>
              </Tooltip>
              {row.status === "ACTIVE" ? (
                <Tooltip title={t(`user.banUser`).toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    onClick={() => ban(row)}
                  >
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
              ) : row.status === "BANNED" ? (
                <Tooltip title={t(`user.activateUser`).toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    onClick={() => ban(row)}
                  >
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  disabled
                >
                  <BlockIcon />
                </IconButton>
              )}
              <Tooltip title={t(`userList.details`).toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={RouterLink}
                  to={`/user/${row.id}`}
                >
                  <AssignmentIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t(`userList.modify`).toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={RouterLink}
                  to={`/user-modify/${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title={t(`userList.details`).toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={RouterLink}
                to={`/user/${row.id}`}
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <>
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "USER_ADMIN",
          ]) && (
            <Button
              variant="contained"
              component={Link}
              startIcon={<AddBox />}
              style={{ margin: "auto" }}
              to="/user-create"
            >
              {t("userList.create")}
            </Button>
          )}
          {/*Export gomb*/}
          {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
        </Box>
      </>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/user/${params.row.id}`);
    }
  }

  return (
    <>
      <PermissionGroupModal open={open} setOpen={setOpen} />
      <PageableTable
        filterOptions={[
          {
            columnName: "status",
            options: USER_STATUS.map(value => {
              return {
                translated: t(`userList.status.${value}`),
                value,
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="user"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={listUsersQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};
export default UserList;
