import {
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addInvoiceIcon } from "shared/network/tenant.api";

export type InvoiceIconFormValues = {
  invoiceIcon: FileList;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tenantId?: string;
};

const UploadTenantIcon = ({ open, setOpen, tenantId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formState, register, watch, handleSubmit } =
    useForm<InvoiceIconFormValues>();
  const [imageState, setImageState] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const imageList = watch("invoiceIcon");

  useEffect(() => {
    if (imageList?.length) {
      setImageState(true);
    }
  }, [setImageState, watch, imageList?.length]);

  useEffect(() => {
    if (imageList?.[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imageRef.current && e) {
          // @ts-ignore
          imageRef.current.src = e.target?.result;
        }
      };
      reader.readAsDataURL(imageList[0]);
    }
  }, [imageList]);
  const onSubmitCreateInviceIcon = async (value: InvoiceIconFormValues) => {
    try {
      await addInvoiceIcon(tenantId, value.invoiceIcon[0]);
      enqueueSnackbar(
        t("common:notification.upload.success", {
          subject: t("tenant.invoiceIconSubject"),
        }),
        {
          variant: "success",
        }
      );
      setOpen(false);
    } catch {
      enqueueSnackbar(
        t("common:notification.upload.failure", {
          subject: t("tenant.invoiceIconSubject"),
        }),
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <Container maxWidth="sm">
      <Dialog open={open}>
        <DialogTitle>{t("tenant.addInvoiceIcon")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmitCreateInviceIcon)}>
          <DialogContent>
            <Grid
              xs={12}
              container
              alignItems="center"
              style={{ width: 400 }}
              justifyContent="center"
            >
              {imageState && imageList[0] && (
                <Grid item xs={12} sm={6} container justifyContent="flex-start">
                  <Typography
                    style={{
                      marginLeft: 8,
                      color: COLORS.main,
                      fontWeight: 500,
                      fontSize: 16,
                      fontFamily: "Roboto",
                    }}
                  >
                    {imageList[0].name.toString()}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                justify={imageState ? "flex-end" : "center"}
                container
              >
                <Button
                  variant="text"
                  component="label"
                  htmlFor="noteFile"
                  startIcon={
                    !watch("invoiceIcon")?.length ? (
                      <AttachFile />
                    ) : (
                      <Check style={{ color: COLORS.green }} />
                    )
                  }
                >
                  {imageState
                    ? t("personalData.formValues.fileChosen")
                    : t("personalData.formValues.chooseFile")}
                  <input
                    id="noteFile"
                    style={{ display: "none" }}
                    type="file"
                    {...register("invoiceIcon", {
                      required: {
                        value: true,
                        message: "Fájl kiválasztása kötelező",
                      },
                      validate: (value) => {
                        if (value?.[0]?.size >= 5242880) {
                          return t("validation.maxSize", {
                            size: 5,
                            unit: "MB",
                          }).toString();
                        }
                      },
                    })}
                  />
                </Button>
              </Grid>
              <FormHelperText error>
                {formState.errors.invoiceIcon?.message}
              </FormHelperText>
            </Grid>
            <Collapse in={imageState}>
              <Box justifyContent="center" display="flex">
                {imageList?.[0] && (
                  <img width="400px" alt="preview" ref={imageRef} />
                )}
              </Box>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Box display="flex" m={2} gridGap={8}>
              <Button
                color="primary"
                variant="text"
                onClick={() => setOpen(false)}
              >
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
};

export default UploadTenantIcon;
