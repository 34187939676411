import { Company, Tool, User, Vehicle } from "shared/types";
import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";

export type VehicleRequest = {
  id?: number | null;
  allWeight: string | null;
  axleNumber: string | null;
  bodyType: string | null;
  brand: string | null;
  carDocumentsExpirationDate: string | null;
  chassisNumber: string | null;
  colEncumbrance: string | null;
  commerceDescription: string | null;
  company: Company | null;
  cylinderCapacity: string | null;
  environmentalCategory: string | null;
  eurotaxCode: string | null;
  firstRegisteredDate: string | null;
  gearboxType: string | null;
  haulData: string | null;
  height: string | null;
  length: string | null;
  licensePlateNumber: string | null;
  maxPerformance: string | null;
  motorCode: string | null;
  productionYear: string | null;
  propellant: string | null;
  registeredDateInHungary: string | null;
  seatNumber: string | null;
  selfWeight: string | null;
  standingPlaceNumber: string | null;
  status: string | null;
  tachographExpirationDate: string | null;
  tenantId: number | null;
  tool: Tool | null;
  type: string | null;
  typeApproveNumber: string | null;
  user: User | null;
  vehicleCategory: string | null;
  vehicleColor: string | null;
  volume: number | null;
  weightCapacity: string | null;
  width: string | null;
};

const ENDPOINT = "/vehicle";

export const createVehicle = (values: VehicleRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param: values, tenantId: tenantId }); //VEHICLE_ADMIN

export const modifyVehicle = (values: VehicleRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param: values, tenantId: tenantId }); //VEHICLE_ADMIN

export const getVehicleById = (id: number, tenantId: number) =>
  axios.post<GenericResponse<Vehicle>>(`${ENDPOINT}/get-by-id`, {
    param: id,
    tenantId: tenantId,
  }); //VEHICLE_GET

export const getVehicleByOrder = (orderId: string, tenantId: number) =>
  axios.post<GenericResponse<Vehicle>>(`${ENDPOINT}/get-by-id`, {
    param: orderId,
    tenantId: tenantId,
  }); //VEHICLE_GET

export const deleteVehicle = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete`, {
    data: { param: id, tenantId: tenantId },
  }); //VEHICLE_ADMIN

export const getVehicleListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<Vehicle>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //VEHICLE_GET

export const listVehiclesByTenant = (tenantId: number) =>
  axios.post<GenericListResponse<Vehicle>>(`${ENDPOINT}/list-by-tenant`, {
    tenantId: tenantId,
  }); //VEHICLE_GET

export const getAllDeliveryByVehicleForDate = (
  page: number,
  size: number,
  tenantId: number,
  date: string,
  search: string = ""
) =>
  axios.get<GenericPageResponse<Vehicle>>(
    `${ENDPOINT}/get-all-delivery-by-vehicle-for-day?page=${page}&size=${size}&tenantId=${tenantId}&date=${date}&search=${search}`
  ); //VEHICLE_GET
