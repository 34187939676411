import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import {
  BaseCurrency,
  Item,
  RelToolEmployee,
  Tool,
  Vehicle,
} from "shared/types";

export type ToolRequest = {
  id?: number;
  tenantId: number;
  name: string;
  description: string;
  responsibleUser?: string | number;
  repairCycleType: string;
  repairCycleAmount: string | number;
  validTo: string;
  isActive: boolean;
  price: string | number;
  priceCurrency: BaseCurrency | null;
  serialNumber: string;
  endOfWarranty: string;
  vehicle?: Vehicle;
  item?: Item;
};

const ENDPOINT = "/tool";

export const toolCreate = (tenantId: number, param: ToolRequest) =>
  axios.post(`${ENDPOINT}/create`, {
    tenantId,
    param,
  }); //TOOL_ADMIN

export const listTools = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Tool>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //TOOL_GET

export const getToolById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Tool>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //TOOL_GET

export const modifyTool = (tenantId: number, param: ToolRequest) =>
  axios.put(`${ENDPOINT}/modify`, {
    param,
    tenantId,
  }); //TOOL_ADMIN

export const getToolsByEmployeeId = (
  page: number,
  size: number,
  employeeId: string,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<RelToolEmployee>>(
    `${ENDPOINT}/by-employee?page=${page}&size=${size}&tenantId=${tenantId}&search=employee.id:${employeeId};${search}&sort=${sort}`,
  ); //TOOL_GET
