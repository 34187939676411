import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import {
  hasAuthority,
  hasNoPermissionButHasCompany,
} from "shared/authorization";
import NewOrderList from "./NewOrderList";
import OrderListByCompany from "./OrderListByCompany";
import OrderListByStatus from "./OrderListByStatus";

export const ORDER_TABS = [
  "/order/new-list",
  "/order/list-by-company",
  "/order/status-list",
];

const OrderTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );

  return (
    <Container maxWidth="xl">
      {location.pathname === "/order" && <Redirect to="/order/new-list" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ORDER_GET",
        ]) && (
          <Tab
            label={t("order.newOrderTab")}
            component={Link}
            to={ORDER_TABS[0]}
            value={ORDER_TABS[0]}
          />
        )}
        <Tab
          label={t("order.orderByCompanyTab")}
          component={Link}
          to={ORDER_TABS[1]}
          value={ORDER_TABS[1]}
        />
        <Tab
          label={t("order.orderStatusTab")}
          component={Link}
          to={ORDER_TABS[2]}
          value={ORDER_TABS[2]}
        />
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={ORDER_TABS[0]}
            component={NewOrderList}
            permissions={["ORDER_GET", "ORDER_ADMIN"]}
          />
          <PrivateRoute
            path={ORDER_TABS[1]}
            component={OrderListByCompany}
            permissions={["ORDER_GET", "ORDER_ADMIN"]}
          />
          <PrivateRoute
            path={ORDER_TABS[2]}
            component={OrderListByStatus}
            permissions={["ORDER_GET", "ORDER_ADMIN"]}
          />
          {hasNoPermissionButHasCompany(account) && (
            <PrivateRoute path={ORDER_TABS[1]} component={OrderListByCompany} />
          )}
        </Switch>
      </Box>
    </Container>
  );
};

export default OrderTabs;
