import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { DeliveryNote } from "shared/types";

const ENDPOINT = "/delivery-note";

export const createDeliveryNote = (param: DeliveryNote, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_NOTE_ADMIN

export const modifyDeliveryNote = (param: DeliveryNote, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_NOTE_ADMIN

export const addSignature = (
  deliveryNoteId: string,
  tenantId: string,
  generate: string,
  contactName: string,
  file?: File
) => {
  const formData = new FormData();
  formData.set("id", deliveryNoteId);
  formData.set("tenantId", tenantId);
  formData.set("generate", generate);
  formData.set("contactName", contactName);
  file && formData.set("file", file);
  return axios.post(`${ENDPOINT}/add-signature-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getDeliveryNoteById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<DeliveryNote>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //DELIVERY_NOTE_ADMIN

export const getDeliveryNoteByDeliveryId = (
  deliveryId: number,
  tenantId: number
) =>
  axios.get<GenericResponse<DeliveryNote>>(
    `${ENDPOINT}/get-by-delivery-id?deliveryId=${deliveryId}&tenantId=${tenantId}`
  ); //DELIVERY_NOTE_ADMIN

export const listDeliveryNotePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.get<GenericPageResponse<DeliveryNote>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //DELIVERY_GET

export const updateVehicleCashStatus = (
  vehicleId: number | undefined,
  date: string,
  status: string,
  tenantId: number,
  expectedCash?: number,
  actualCash?: number
) =>
  axios.put<GenericListResponse<DeliveryNote>>(
    `${ENDPOINT}/update-cash-status`,
    {
      vehicleId,
      date,
      status,
      tenantId,
      expectedCash,
      actualCash,
    }
  ); //DELIVERY_NOTE_ADMIN
