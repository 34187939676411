import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SignatureCanvas from "components/SignatureCanvas";
import { DELIVERY_TYPE } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listContactByTenant } from "shared/network/contact.api";
import { updateDeliveryStatus } from "shared/network/delivery.api";
import { Contact, Delivery } from "shared/types";

export type DeliveryStatusDialogValues = {
  status: string;
  deliveryId: string;
  tenantId: string;
  comment?: string;
  contact: Contact | null;
  file?: FileList; //ha isCompany, akkor kötelező
};

type Props = {
  selectedDelivery: Delivery | null;
  setSelectedDelivery: Dispatch<SetStateAction<Delivery | null>>;
  refetch: () => void;
  selectedStatus?: string;
  fromStatusCell?: boolean;
};

const useStyles = makeStyles({
  formCardHeader: {
    height: "fit-content",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    width: "100%",
    fontWeight: "bold",
    background: COLORS.mainGrey,
  },
});

const DeliveryStatusDialog = ({
  selectedDelivery,
  setSelectedDelivery,
  refetch,
  selectedStatus,
  fromStatusCell,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, handleSubmit, setValue } =
    useForm<DeliveryStatusDialogValues>();
  const status = watch("status");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const [statusList, setStatusList] = useState<string[]>(DELIVERY_TYPE);

  const contactListQuery = useQuery(
    [
      "contactListForDeliveryStatus",
      tenant.id,
      selectedDelivery?.deliveryNote?.companyId,
    ],
    async () => {
      const { data } = await listContactByTenant(
        0,
        1000000,
        tenant.id,
        selectedDelivery?.deliveryNote?.companyId
          ? `companyId:${selectedDelivery.deliveryNote.companyId}`
          : "",
      );
      return data.page;
    },
  );

  const [transferorSignatureFile, setTransferorSignatureFile] = useState<File>(
    new File([], ""),
  );

  const [recipientSignatureFile, setRecipientSignatureFile] = useState<File>(
    new File([], ""),
  );

  useEffect(() => {
    if (selectedStatus) {
      setValue("status", selectedStatus);
    }
  }, [selectedStatus, setValue]);

  useEffect(() => {
    setStatusList(fromStatusCell ? DELIVERY_TYPE : ["SUSPENDED", "WITHDRAWN"]);
  }, [fromStatusCell, setStatusList]);

  const onSubmitCreate = async (values: DeliveryStatusDialogValues) => {
    try {
      if (selectedDelivery) {
        if (values.status === "IN_CARGO") {
          const transferor = document.getElementById(
            "signatureWarehouse",
          ) as HTMLCanvasElement;
          const recipient = document.getElementById(
            "signatureVehicleDriver",
          ) as HTMLCanvasElement;
          if (transferor && recipient) {
            transferor.toBlob(blob => {
              if (blob) {
                setTransferorSignatureFile(
                  new File([blob], "transferorSignature.png", {
                    type: "image/png",
                  }),
                );
              }
            });
            recipient.toBlob(blob => {
              if (blob) {
                setRecipientSignatureFile(
                  new File([blob], "recipientSignature.png", {
                    type: "image/png",
                  }),
                );
              }
            });
            await updateDeliveryStatus(
              values.status,
              selectedDelivery?.id.toString(),
              tenant.id.toString(),
              values.comment,
              values.contact ? values.contact?.name : "",
              {
                transferorSignature: transferorSignatureFile,
                recipientSignature: recipientSignatureFile,
              },
            );
          }
          refetch();
          enqueueSnackbar("Sikeres módosítás.", {
            variant: "success",
          });
        } else {
          const canvas = document.getElementById(
            "signature",
          ) as HTMLCanvasElement;
          if (canvas) {
            canvas.toBlob(async blob => {
              if (blob) {
                await updateDeliveryStatus(
                  values.status,
                  selectedDelivery?.id.toString(),
                  tenant.id.toString(),
                  values.comment,
                  values.contact ? values.contact?.name : "",
                  {
                    file: new File([blob], "signature.png", {
                      type: "image/png",
                    }),
                  },
                );
              }
              refetch();
            }, "image/png");
          } else {
            await updateDeliveryStatus(
              values.status,
              selectedDelivery?.id.toString(),
              tenant.id.toString(),
              values.comment,
              values.contact ? values.contact?.name : "",
            );
            refetch();
          }
          refetch();
          enqueueSnackbar("Sikeres módosítás.", {
            variant: "success",
          });
        }

        setSelectedDelivery(null);
      } else {
        enqueueSnackbar("Hiba történt a módosítás során.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Hiba történt a módosítás során.", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog open={!!selectedDelivery}>
      <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmitCreate)}>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 350 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="status"
                defaultValue={selectedStatus || "ACTIVE"}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    select
                    InputLabelProps={{ shrink: true, required: true }}
                    label={t("delivery.formValues.status")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={!!fromStatusCell}
                  >
                    {statusList.map(status => (
                      <MenuItem key={status} value={status}>
                        {t(`delivery.status.${status}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {status === "DELIVERED" && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="contact"
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={contactListQuery.data?.content || []}
                      getOptionLabel={(option: Contact) => option.name}
                      getOptionSelected={option =>
                        option.name === field.value?.name
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label={t("delivery.formValues.contactName")}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {status === "SUSPENDED" && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="comment"
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={2}
                      InputLabelProps={{ shrink: true }}
                      label={t("delivery.formValues.comment")}
                    />
                  )}
                />
              </Grid>
            )}
            {status === "DELIVERED" && (
              <>
                <Box className={classes.formCardHeader}>
                  <Typography variant="h3">
                    {t("common:signature.subject")}
                  </Typography>
                </Box>
                <Grid item xs={12} container justifyContent="center">
                  <SignatureCanvas id={"signature"} />
                </Grid>
              </>
            )}
            {status === "IN_CARGO" && (
              <>
                <Grid container justifyContent="center">
                  <Box className={classes.formCardHeader}>
                    <Typography variant="h3">
                      {t("common:signature.warehouse")}
                    </Typography>
                  </Box>
                  <Grid item xs={6} container justifyContent="center">
                    <SignatureCanvas id={"signatureWarehouse"} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Box className={classes.formCardHeader}>
                    <Typography variant="h3">
                      {t("common:signature.vehicleDriver")}
                    </Typography>
                  </Box>
                  <Grid item xs={6} container justifyContent="center">
                    <SignatureCanvas id={"signatureVehicleDriver"} />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" m={2} gridGap={8}>
            <Button
              color="primary"
              variant="text"
              onClick={() => setSelectedDelivery(null)}
            >
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeliveryStatusDialog;
