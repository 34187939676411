import Loading from "components/Loading";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  ListItemIcon,
  ListItemText,
  TextField,
  Box,
} from "@material-ui/core";
import {
  permissionGroupList,
  permissionGroupListByUserId,
} from "shared/network/permission-group.api";
import { useSnackbar } from "notistack";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { PermissionGroup } from "shared/types";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { relateUserPermissionGroup } from "shared/network/rel-user-permission-group";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 320,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: 8,
  },
}));

function not(a: PermissionGroup[], b: PermissionGroup[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: PermissionGroup[], b: PermissionGroup[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: PermissionGroup[], b: PermissionGroup[]) {
  return [...a, ...not(b, a)];
}

const PermissionModal = ({
  open,
  setOpen,
}: {
  open: number | null;
  setOpen: (open: number | null) => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [checked, setChecked] = useState<PermissionGroup[]>([]);
  const [left, setLeft] = useState<PermissionGroup[]>([]);
  const [right, setRight] = useState<PermissionGroup[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const getData = useCallback(
    async function getData() {
      setLoading(true);
      try {
        if (open && tenant.id) {
          const { data: dataByGroup } = await permissionGroupListByUserId(
            open,
            tenant.id
          );
          setRight(dataByGroup.items);

          const { data } = await permissionGroupList(tenant.id);
          const result = data?.items.filter(
            (permGroup) =>
              !dataByGroup.items.find(
                (permGroupByUser) => permGroup.id === permGroupByUser.id
              )
          );
          setLeft(result);
        }
      } catch {}
      setLoading(false);
    },
    [open, tenant.id]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const handleToggle = (value: PermissionGroup) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: PermissionGroup[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: PermissionGroup[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const onSubmit = async () => {
    if (open) {
      try {
        await relateUserPermissionGroup(right, open, tenant.id);
        setOpen(null);
        enqueueSnackbar("Sikeres mentés.", {
          variant: "success",
        });
      } catch {
        enqueueSnackbar("Sikertelen mentés.", {
          variant: "error",
        });
      }
    }
  };

  const customList = (title: React.ReactNode, items: PermissionGroup[]) => (
    <Card elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} kiválasztva`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items
          .filter((item) =>
            item.name.toUpperCase().includes(filter.toUpperCase())
          )
          .map((value) => {
            return (
              <ListItem key={value.id} button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    value.name +
                    (value.isDefault ? t("permissionGroup.default") : "")
                  }
                />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Dialog open={!!open} onClose={() => setOpen(null)} maxWidth="md">
      <DialogTitle>Jogosultságok megadása</DialogTitle>
      <DialogContent>
        <Loading open={loading} />
        <Box px={1.5} pb={1.5}>
          <TextField
            label="Szűrés"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList("Kiválasztható", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <IconButton
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
              >
                <ArrowForward />
              </IconButton>
              <IconButton
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>{customList("Kiválasztott", right)}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(null)}>
          Mégse
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionModal;
