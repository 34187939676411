import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateVehicleCashStatus } from "shared/network/deliveryNote.api";
import { VehicleCashData } from "./VehicleCashByVehicle";

type Props = {
  item: VehicleCashData;
  refetch: () => void;
  date: Date;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px solid ${COLORS.greyWater}`,
    alignItems: "center",
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  row: {
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
});

const VehicleCashByVehicleRow = ({ item, refetch, date }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useForm<VehicleCashData>();

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  const [refuseDialog, setRefuseDialog] = useState<VehicleCashData | null>(
    null
  );

  const acceptCashValue = async () => {
    try {
      await updateVehicleCashStatus(
        item.vehicle.id,
        format(date, "yyyy-MM-dd"),
        "ACCEPTED",
        tenant.id
      );
      enqueueSnackbar("Elfogadás sikeres!", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("Elfogadás sikertelen!", {
        variant: "error",
      });
    }
  };

  const rejectCashValue = async (values: VehicleCashData) => {
    try {
      await updateVehicleCashStatus(
        item.vehicle.id,
        format(date, "yyyy-MM-dd"),
        "DENIED",
        tenant.id,
        values.cashInHand,
        values.cashOnRefuse
      );
      enqueueSnackbar("Pénzösszeg megadása sikeres!", {
        variant: "success",
      });
      setRefuseDialog(null);
      reset();
    } catch {
      enqueueSnackbar("Pénzösszeg megadása sikertelen!", {
        variant: "error",
      });
      setRefuseDialog(null);
      reset();
    }
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.row}>
        <Grid item xs={2} className={classes.cell}>
          <Typography className={classes.text}>
            {item.vehicle.licensePlateNumber}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.cell}>
          <Typography className={classes.text}>{item.vehicle.brand}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.cell}>
          <Typography className={classes.text}>{item.vehicle.type}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.cell}>
          <Typography className={classes.text}>
            {item.vehicle.user?.name}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.cell}>
          <Typography className={classes.text}>
            {item.cashInHand}
            {" Forint"}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.cell}>
          <Button onClick={() => acceptCashValue()} variant="text">
            {t("common:button.accept")}
          </Button>
        </Grid>
        <Grid item xs={1} className={classes.cell}>
          <Button onClick={() => setRefuseDialog(item)} variant="text">
            {t("common:button.reject")}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={!!refuseDialog}
        onClose={() => setRefuseDialog(null)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <Typography variant="h2" align="center">
            Készpénz átadás
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(rejectCashValue)}>
            <Grid
              container
              className={classes.header}
              style={{ padding: "8px 0 8px 0" }}
              spacing={2}
            >
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="cashInHand"
                  defaultValue={item?.cashInHand || 0}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      disabled
                      label={"Várt Összeg: "}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="cashOnRefuse"
                  defaultValue={item?.cashOnRefuse || 0}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={"Átadott Összeg: "}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
              <Button type="submit" variant="text">
                <Typography className={classes.text}>
                  {t("common:button.ok")}
                </Typography>
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VehicleCashByVehicleRow;
