import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  AccountBoxOutlined,
  ExitToAppRounded,
  LocationCity,
} from "@material-ui/icons";
import { RootState } from "config/store";
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "shared/reducers/authentication";
import TenantChooserPopover from "./TenantChooserPopover";

type Props = {
  anchorElProfile: HTMLElement | null;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setAnchorElProfile: Dispatch<SetStateAction<HTMLElement | null>>;
};

const ProfileMenu = ({
  anchorElProfile,
  setOpen,
  setAnchorElProfile,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElTenant, setAnchorElTenant] = useState<HTMLElement | null>(
    null,
  );

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const handleClickTenantMenu = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorElTenant(event.currentTarget);
  };
  const handleCloseTenantMenu = () => {
    setAnchorElTenant(null);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
    setAnchorElTenant(null);
  };

  useEffect(() => {
    if (!account) {
      history.replace("/login");
    }
  }, [history, account]);

  return (
    <>
      <Popover
        anchorEl={anchorElProfile}
        keepMounted
        open={!!anchorElProfile}
        onClose={handleCloseProfileMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem
            component={Link}
            to="/profile/details"
            onClick={() => {
              handleCloseProfileMenu();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <AccountBoxOutlined />
            </ListItemIcon>
            <Typography variant="inherit">Profilom</Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to="/tenant-profile/details"
            onClick={() => {
              handleCloseProfileMenu();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <LocationCity />
            </ListItemIcon>
            <Typography variant="inherit">
              {t("tenantProfile.title")}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleClickTenantMenu}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faHouseUser}
                style={{ fontSize: "20px" }}
              />
            </ListItemIcon>
            <Typography variant="inherit">
              {selectedRelTenant?.tenant?.name}
            </Typography>
          </MenuItem>
          {/*<MenuItem
            component={Link}
            to="/mail-view"
            onClick={() => {
              handleCloseProfileMenu();
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <Typography variant="inherit">{t("emailView.title")}</Typography>
          </MenuItem>*/}
          <MenuItem button onClick={() => dispatch(logout())}>
            <ListItemIcon>
              <ExitToAppRounded />
            </ListItemIcon>
            <Typography variant="inherit">Kijelentkezés</Typography>
          </MenuItem>
        </MenuList>
      </Popover>
      <TenantChooserPopover
        anchorElTenant={anchorElTenant}
        setOpen={setOpen}
        handleCloseProfileMenu={handleCloseProfileMenu}
        handleCloseTenantMenu={handleCloseTenantMenu}
      />
    </>
  );
};

export default ProfileMenu;
