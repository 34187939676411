import { Box, Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Assignment, Delete } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteAbsence, listAbsenceByDate } from "shared/network/absence.api";

export type AbsenceDateFormValues = {
  dateFrom: Date;
  dateTo: Date;
};

const AbsenceList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const [start, setStart] = useState(startOfMonth(new Date()));
  const [end, setEnd] = useState(endOfMonth(new Date()));

  const listAbsenceByTenantQuery = useQuery(
    ["listAbsenceByTenantQuery", tenant.id, start, end],
    async () => {
      const { data } = await listAbsenceByDate(
        tenant.id,
        format(start, "yyyy-MM-dd"),
        format(end, "yyyy-MM-dd"),
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "employee.name",
      headerName: t("absence.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.employee ? row.employee.name : "-",
    },
    {
      field: "type",
      headerName: t("absence.formValues.absenceType"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.type ? t(`common:absenceTypes.${row.type}`) : "-",
    },
    {
      field: "dateFrom",
      headerName: t("absence.formValues.date"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.dateFrom), "P", { locale: hu }),
    },
    {
      field: "isOrdered",
      headerName: t("absence.formValues.isOrdered"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isOrdered ? t("common:YES") : t("common:NO"),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("absence.delete").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  if (row.id) {
                    removeAbsence(row.id);
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  async function removeAbsence(id: number) {
    try {
      await deleteAbsence(id, selectedRelTenant.tenant.id);
      listAbsenceByTenantQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("absence.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("absence.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ABSENCE_ADMIN",
          "ABSENCE_GET",
          "EMPLOYEE_GET",
        ]) && (
          <Button
            component={Link}
            to="/absence-create"
            startIcon={<Assignment />}
          >
            {t("absence.absenceAdd")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item xs style={{ marginBottom: 20 }}>
          <Grid item xs style={{ marginRight: 8 }}>
            <KeyboardDatePicker
              size="small"
              value={start}
              onChange={date => {
                date && setStart(date);
              }}
              label={t("absence.formValues.dateFrom")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
            />
          </Grid>
          <Grid item xs>
            <KeyboardDatePicker
              value={end}
              onChange={date => {
                date && setEnd(date);
              }}
              label={t("absence.formValues.dateTo")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
            />
          </Grid>
        </Grid>
      </Grid>
      <SingleQueryTable
        query={listAbsenceByTenantQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
export default AbsenceList;
