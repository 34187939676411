import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { getFileByPath } from "shared/network/file.api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Comment } from "shared/types";
import { fileDownload } from "shared/util/fileDownload";
import { format as dateFormat } from "date-fns";
import { COMMENT_TYPE } from "config/constants";

type Props = {
  comment: Comment;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    paddingBottom: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  secondaryNote: {
    color: COLORS.lightGrey,
    fontSize: 15,
  },
  primaryNote: {
    paddingLeft: 10,
  },
});

const CommentRow = ({ comment }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );
  const tenant = selectedRelTenant.tenant;

  return (
    <Grid container>
      <Grid item xs={10} className={classes.cell}>
        <Typography className={classes.secondaryNote}>{`${t(
          "comment.updatedBy"
        )} ${comment.uploaderName} ${t("comment.updatedByExtendHun")} ${t(
          "comment.on"
        )} ${
          comment.createdOn &&
          " (" +
            dateFormat(new Date(comment.createdOn), "yyyy. MM. dd. HH:mm") +
            ")"
        }`}</Typography>
        <Typography className={classes.primaryNote}>{comment.note}</Typography>
      </Grid>
      {comment.type === COMMENT_TYPE[0] && (
        <Grid
          item
          container
          xs={2}
          className={classes.cell}
          justifyContent="flex-end"
        >
          <Tooltip title={t("comment.download").toString()}>
            <IconButton
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
              onClick={async () => {
                if (comment.id) {
                  try {
                    const { data } = await getFileByPath(
                      comment.filePath,
                      tenant.id
                    );
                    fileDownload(data, comment.originalFileName);
                    enqueueSnackbar(
                      t("common:notification.download.success", {
                        subject: t("comment.subject"),
                      }),
                      {
                        variant: "success",
                      }
                    );
                  } catch {
                    enqueueSnackbar(
                      t("common:notification.download.failure", {
                        subject: t("comment.subject"),
                      }),
                      {
                        variant: "error",
                      }
                    );
                  }
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default CommentRow;
