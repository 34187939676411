import { Box } from "@material-ui/core";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { huHU } from "components/PageableTable/huHU";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listPermissionGroupByEmployee } from "shared/network/employee.api";

type Props = {
  employeeId: string | null;
};

const EmployeePermissionList = ({ employeeId }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant
  );

  const permissionQuery = useQuery(
    ["employeePermission", tenant.id],
    async () => {
      if (employeeId) {
        const { data } = await listPermissionGroupByEmployee(
          employeeId,
          tenant.id
        );
        return data.items;
      }
      Promise.reject();
    }
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("permissionGroup.name"),
      flex: 1,
    },
  ];

  return (
    <Box mb={2.5}>
      <DataGrid
        autoHeight
        localeText={huHU}
        loading={permissionQuery.isFetching}
        error={permissionQuery.isError ? true : undefined}
        rows={permissionQuery.data || []}
        columns={columns}
        page={page}
        pageSize={10}
        rowCount={permissionQuery.data?.length}
        rowsPerPageOptions={[]}
        pagination
        paginationMode="client"
        onPageChange={setPage}
        disableColumnMenu
        disableSelectionOnClick
      />
    </Box>
  );
};

export default EmployeePermissionList;
