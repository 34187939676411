import { Box, Button, Container } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listEmployeePersonalData } from "shared/network/personaldata.api";

const EmployeePersonalData = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [page, setPage] = useState(0);
  const { setHeaderButtons } = useHeader();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const listPersonalDataQuery = useQuery(
    ["personalDataListQuery", id],
    async () => {
      if (id) {
        const { data } = await listEmployeePersonalData(
          id,
          selectedRelTenant.tenant.id,
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: t("personalData.formValues.type"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`common:documentTypes.${row.type}`),
    },
    {
      field: "validTo",
      headerName: t("personalData.formValues.validTo"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.validTo), "P", { locale: hu }),
    },
    {
      field: "comment",
      headerName: t("personalData.formValues.comment"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.comment || "-",
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to={`/personal-data-create/${id}`}
            startIcon={<AddBox />}
          >
            {t("personalData.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={listPersonalDataQuery}
        columns={columns}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};

export default EmployeePersonalData;
