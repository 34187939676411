import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import PageLoading from "components/PageLoading";
import ScrollToTop from "components/ScrollToTop";
import queryClient from "config/query";
import store from "config/store";
import theme from "config/theme";
import { SnackbarProvider } from "notistack";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import "./i18n";

ReactDOM.render(
  <StrictMode>
    <DndProvider
      backend={navigator.maxTouchPoints > 0 ? TouchBackend : HTML5Backend}
    >
      <ReduxProvider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider>
                <Suspense fallback={<PageLoading />}>
                  <ScrollToTop />
                  <App />
                </Suspense>
              </SnackbarProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ReduxProvider>
    </DndProvider>
  </StrictMode>,
  document.getElementById("root"),
);
