//export const functionSearchList: string[] = ["", "/contract-create"];

export type FunctionSearchEntry = {
  target: string;
  needTenantAdmin: boolean;
  needSuperAdmin: boolean;
  permissions: string[];
};

// prettier-ignore
export const functionSearchList: FunctionSearchEntry[] = [
  // FROM App.tsx
  { target: "", needTenantAdmin: false, needSuperAdmin: false, permissions: [] },
  { target: "/currency-create", needTenantAdmin: true, needSuperAdmin: false, permissions: [] },
  { target: "/salary", needTenantAdmin: false, needSuperAdmin: false, permissions: ["SALARY_GET"] },
  { target: "/department-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DEPARTRMENT_ADMIN"]},
  { target: "/my-company", needTenantAdmin: false, needSuperAdmin: false, permissions: ["USER_ADMIN", "WAREHOUSE_ADMIN"]},
  { target: "/company-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_ADMIN"]},
  { target: "/company-category-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_ADMIN"]},
  { target: "/contact-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_ADMIN"]},
  { target: "/user-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["USER_ADMIN"]},
  { target: "/absence-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ABSENCE_ADMIN"]},
  { target: "/employee-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["EMPLOYEE_ADMIN"]},
  { target: "/warehouse-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN"]},
  { target: "/warehouse-scrap-create/", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN"]},
  { target: "/issue-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_ADMIN"]},
  { target: "/issue-template-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TEMPLATE_ADMIN"]},
  { target: "/project", needTenantAdmin: false, needSuperAdmin: false, permissions: ["PROJECT_ADMIN"]},
  { target: "/project-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["PROJECT_ADMIN"]},
  { target: "/bundle-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/tenant-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/subscription-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/tool-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["TOOL_ADMIN"]},
  { target: "/item-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_ADMIN"]},
  { target: "/serial-item-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_ADMIN"]},
  { target: "/bank-account-create/", needTenantAdmin: false, needSuperAdmin: false, permissions: ["BANK_ACCOUNT_ADMIN"]},
  { target: "/statistics", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
  { target: "/planning", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"]},
  { target: "/vehicle-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN"]},
  { target: "/offer-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"]},
  { target: "/contracts", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CONTRACT_GET"]},
  { target: "/contract-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CONTRACT_GET"]},
  { target: "/invoice-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["INVOICE_ADMIN"]},
  { target: "/order", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_GET"]},
  { target: "/order-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_ADMIN"]},
  { target: "/issue-type-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TYPE_ADMIN"]},
  { target: "/incoming-invoice-create/", needTenantAdmin: false, needSuperAdmin: false, permissions: ["INCOMING_INVOICE_ADMIN"]},
  { target: "/custom-fields-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CUSTOM_FIELDS_ADMIN"]},
  { target: "/article-create", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
  { target: "/warehouse/item-transfer", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN"]},
  { target: "/inside-delivery-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_ADMIN"]},
  
  //FROM MyCompanyTabs.tsx
  { target: "/my-company/users", needTenantAdmin: false, needSuperAdmin: false, permissions: ["USER_ADMIN"]},
  { target: "/my-company/employees", needTenantAdmin: false, needSuperAdmin: false, permissions: ["EMPLOYEE_ADMIN"]},
  { target: "/my-company/departments", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DEPARTMENT_ADMIN"]},
  { target: "/my-company/absences", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ABSENCE_GET"]},
  { target: "/my-company/vehicles", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_GET"]},
  { target: "/my-company/tools", needTenantAdmin: false, needSuperAdmin: false, permissions: ["TOOL_GET"]},  

  //FROM WarehouseTabs.tsx
  { target: "/warehouse/list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_GET"]},
  { target: "/warehouse/cash-by-vehicle", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_GET", "DELIVERY_NOTE_ADMIN"]},

  //FROM Profile.tsx
  { target: "/profile/details", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
  { target: "/profile/modify", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
  { target: "/profile/password-modify", needTenantAdmin: false, needSuperAdmin: false, permissions: []},

  //FROM ItemsTabs.tsx
  { target: "/items/list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_GET", "ITEM_ADMIN"]},
  { target: "/items/categories", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CATEGORY_ADMIN"]},
  { target: "/items/serial-item-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_GET"]},

  //FROM CompanyTabs.tsx
  { target: "/companies/list-all", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET"]},
  { target: "/companies/list-customer", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET"]},
  { target: "/companies/list-supplier", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET"]},

  //FROM IssuesTabs.tsx
  { target: "/issues/all", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/personal", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/templates", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TEMPLATE_GET"]},
  { target: "/issues/issue-type", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TYPE_GET"]},
  { target: "/issues/custom-fields", needTenantAdmin: false, needSuperAdmin: false, permissions: []},

  //FROM TenantProfile.tsx
  { target: `/tenant-profile/details`, needTenantAdmin: false, needSuperAdmin: false, permissions: []},
  { target: `/tenant-profile/delivery-note`, needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_NOTE_ADMIN"]},
  { target: `/tenant-profile/delivery-all`, needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_GET"]},
  { target: `/tenant-profile/invoice-all`, needTenantAdmin: false, needSuperAdmin: false, permissions: ["INVOICE_ADMIN"]},
  { target: `/tenant-profile/incoming-invoice`, needTenantAdmin: false, needSuperAdmin: false, permissions: ["INCOMING_INVOICE_ADMIN"]},
  { target: `/tenant-profile/scrap-list`, needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: `/tenant-profile/settings`, needTenantAdmin: true, needSuperAdmin: false, permissions: []},

  //FROM AdminTabs.tsx
  { target: "/admin/bundles", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/tenants", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/currencies", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/permission-groups", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/company-category", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/audit-log", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/properties", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/articles", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
];

/*

*/
