import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import VehicleCashByVehicle from "views/Vehicle/VehicleCashByVehicle";
import WarehouseList from "./WarehouseList";
import { Redirect } from "react-router-dom";
import ItemTransfer from "./ItemTransfer";

export const WAREHOUSE_TABS = [
  "/warehouse/list",
  "/warehouse/cash-by-vehicle",
  "/warehouse/item-transfer",
];

const WarehouseTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  

  return (
    <Container maxWidth="xl">
      {location.pathname === "/warehouse" && <Redirect to="/warehouse/list" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_GET",
        ]) && (
          <Tab
            label={t("drawer.warehouse")}
            component={Link}
            to={WAREHOUSE_TABS[0]}
            value={WAREHOUSE_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_GET",
          "DELIVERY_NOTE_ADMIN",
        ]) &&
          selectedRelTenant.tenant.cashCounting && (
            <Tab
              label={t("drawer.cash-by-delivery")}
              component={Link}
              to={WAREHOUSE_TABS[1]}
              value={WAREHOUSE_TABS[1]}
            />
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
        ]) && (
          <Tab
            label={t("drawer.item-transfer")}
            component={Link}
            to={WAREHOUSE_TABS[2]}
            value={WAREHOUSE_TABS[2]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={WAREHOUSE_TABS[0]}
            component={WarehouseList}
            permissions={["WAREHOUSE_GET"]}
          />
          <PrivateRoute
            path={WAREHOUSE_TABS[1]}
            component={VehicleCashByVehicle}
            permissions={["DELIVERY_GET", "DELIVERY_NOTE_ADMIN"]}
          />
          <PrivateRoute
            path={WAREHOUSE_TABS[2]}
            component={ItemTransfer}
            permissions={["WAREHOUSE_GET"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default WarehouseTabs;
