import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { CompanyCategory } from "shared/types";

const ENDPOINT = "/company-category";

export const createCompanyCategory = (
  values: CompanyCategory,
  tenantId: number
) => axios.post(`${ENDPOINT}/save`, { param: values, tenantId: tenantId }); //COMPANY_ADMIN

export const deleteCompanyCategory = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}/delete`, {
    data: { param: param, tenantId: tenantId },
  }); //COMPANY_ADMIN

export const listCompanyCategoryByCompany = (tenantId: number, id: string) =>
  axios.post<GenericListResponse<CompanyCategory>>(
    `${ENDPOINT}/list-by-company`,
    { tenantId: tenantId, param: id }
  ); //COMPANY_GET

export const getCompanyCategoryById = (id: number) =>
  axios.post<GenericResponse<CompanyCategory>>(`${ENDPOINT}/get-by-id`, {
    param: id,
  }); //COMPANY_GET

export const listCompanyCategoryByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string
) =>
  axios.get<GenericPageResponse<CompanyCategory>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}`
  ); //COMPANY_GET
