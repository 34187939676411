import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteDepartment,
  getDepartmentList,
} from "shared/network/department.api";
import displayAddress from "shared/util/displayAddress";
import { TableState } from "views/Company/CompanyList/CompanyList";

const DepartmentList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem("rap-companysite-page-number") ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem("rap-companysite-page-size") ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const departmentListQuery = useQuery(
    ["tenantDepartmentList", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getDepartmentList(
        page,
        pageSize,
        tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("department.formValues.name"),
      flex: 1,
    },
    {
      field: "address",
      headerName: t("department.formValues.address.title"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        displayAddress(row.address),
    },
    {
      field: "description",
      headerName: t("department.formValues.description"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.description || "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DEPARTRMENT_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("department.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={`/department-modify/${row.tenantId}/${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("department.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    if (row.id) {
                      removeDepartment(row.id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeDepartment(id: number) {
    try {
      await deleteDepartment(id, selectedRelTenant.tenant.id);
      departmentListQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("department.subject"),
        }),
        { variant: "success" },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("department.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DEPARTRMENT_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/department-create"
            variant="contained"
            startIcon={<AddBox />}
            style={{ margin: "auto" }}
          >
            {t("department.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="companysite"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={departmentListQuery}
    />
  );
};

export default DepartmentList;
