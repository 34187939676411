const urlCreator = window.URL || window.webkitURL;

function b64toBlob(data: string, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const fileDownload = (data: string, filename?: string) => {
  const blob = new Blob([data], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(blob);
  link.setAttribute("download", filename ?? "");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const csvDownload = (data: string, filename?: string) => {
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(b64toBlob(data, "application/csv"));
  link.setAttribute("download", filename ?? "");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const deliveryNoteDownload = (
  data: Blob | MediaSource,
  filename?: string
) => {
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(data);
  link.setAttribute("download", filename ?? "");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const deliveryNoteLinkOpen = (dataLink: string) => {
  const link = document.createElement("a");
  link.href = dataLink;
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
};

export const deliveryNoteOpen = (data: Blob | MediaSource) => {
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(data);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const invoiceDownload = (
  data: Blob | MediaSource,
  filename?: string
) => {
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(data);
  link.setAttribute("download", filename ?? "");
  document.body.appendChild(link);
  link.click();
  link.remove();
};
