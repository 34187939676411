import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
} from "shared/common";
import { Item, Vehicle, Warehouse } from "shared/types";

type TransferRequestType = {
  fromWarehouseId: string | number;
  toWarehouseId: string | number;
  itemWithQuantityEntries: {
    quantity: string;
    itemEntry: Item | null;
  }[];
};

type GetItemResponse = {
  item: Item;
  quantity: string | number;
};

const ENDPOINT = "/warehouse";

export const createWarehouse = (values: Warehouse, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param: values, tenantId: tenantId }); //WAREHOUSE_ADMIN

export const modifyWarehouse = (values: Warehouse, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param: values, tenantId: tenantId }); //WAREHOUSE_ADMIN

export const listWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Warehouse>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //WAREHOUSE_GET

export const getWarehouseById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<Warehouse>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //WAREHOUSE_GET

export const listItemsInWarehouse = (id: string, tenantId: number) =>
  axios.post<GenericListResponse<{ item: Item; quantity: number }>>(
    `${ENDPOINT}/list-items-in-warehouse`,
    {
      param: id,
      tenantId: tenantId,
    },
  ); //WAREHOUSE_ADMIN

export const getItemInWarehouse = (
  warehouseId: number,
  itemId: number,
  tenantId: number,
) =>
  axios.post<GenericResponse<GetItemResponse>>(
    `${ENDPOINT}/get-item-in-warehouse`,
    {
      warehouseId: warehouseId,
      itemId: itemId,
      tenantId: tenantId,
    },
  ); //WAREHOUSE_ADMIN

export const wrappingToWarehouse = (
  tenantId: number,
  vehicle: Vehicle,
  warehouse: Warehouse,
) =>
  axios.post(`${ENDPOINT}/wrapping-to-warehouse`, {
    tenantId: tenantId,
    vehicle: vehicle,
    warehouse: warehouse,
  }); //WAREHOUSE_ADMIN

export const transferItems = (param: TransferRequestType, tenantId: number) =>
  axios.post(`${ENDPOINT}/instant-transfer`, {
    fromWarehouseId: param.fromWarehouseId,
    toWarehouseId: param.toWarehouseId,
    itemWithQuantityEntries: param.itemWithQuantityEntries,
    tenantId,
  }); //WAREHOUSE_ADMIN
