import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { PermissionGroup } from "shared/types";

const ENDPOINT = "/permission-group";

export const permissionGroupList = (tenantId: number) =>
  axios.get<GenericListResponse<PermissionGroup>>(
    `${ENDPOINT}?tenantId=${tenantId}`
  ); //PERMISSION_GROUP_GET

export const permissionGroupListByUserId = (param: number, tenantId: number) =>
  axios.post<GenericListResponse<PermissionGroup>>(`${ENDPOINT}/list-by-user`, {
    param,
    tenantId,
  }); //PERMISSION_GROUP_GET

export const permissionGroupCreate = (
  param: Partial<PermissionGroup>,
  tenantId: number
) =>
  axios.post<GenericResponse<PermissionGroup>>(ENDPOINT, {
    param: { ...param, tenantId },
    tenantId,
  }); //PERMISSION_ADMIN

export const permissionGroupModify = (
  param: Partial<PermissionGroup>,
  tenantId: number
) =>
  axios.put(ENDPOINT, {
    param: { ...param, tenantId },
    tenantId,
  }); //PERMISSION_ADMIN

export const deletePermissionGroup = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?permissionGroupId=${id}&tenantId=${tenantId}`); //PERMISSION_ADMIN
