import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getEmployeeById } from "shared/network/employee.api";
import RelDepartmentEmployeeList from "views/Department/RelDepartmentEmployeeList";
import QualificationList from "views/Qualifications/QualificationList";
import SalaryList from "views/Salary/SalaryList";
import EmployeeAbsenceList from "./EmployeeAbsenceList";
import EmployeeData from "./EmployeeData";
import EmployeePersonalData from "./EmployeePersonalData";
import EmployeeTool from "./EmployeeTool/EmployeeTool";
import SubordinatesOfSuperiors from "./SubordinatesofSuperiors";
import SuperiorsOfSubordinates from "./SuperiorsofSubordinates";

export const EMPLOYEE_TABS = [
  `/employee/details`,
  `/employee/documents`,
  `/employee/qualifications`,
  `/employee/superiors`,
  `/employee/subordinates`,
  `/employee/salaries`,
  `/employee/department`,
  `/employee/absence`,
  `/employee/tool`,
];

const EmployeeDetailsTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderName } = useHeader();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication
  );

  const employeeQuery = useQuery(["employeeDetailsQuery", id], async () => {
    if (id) {
      const { data } = await getEmployeeById(id, selectedRelTenant.tenant.id);
      return data;
    }
    return Promise.reject();
  });

  useEffect(() => {
    if (employeeQuery.isFetching) {
      setHeaderName(<Skeleton width={200} />);
    } else if (employeeQuery.data?.employee?.name) {
      setHeaderName(
        `${employeeQuery.data?.employee?.name} ${t("common:details.details")}`
      );
    }
    return () => {
      setHeaderName(null);
    };
  }, [
    employeeQuery.data?.employee?.name,
    employeeQuery.isFetching,
    setHeaderName,
    t,
  ]);

  return (
    <Container maxWidth="xl">
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_GET",
        ]) && (
          <Tab
            label={t("employee.data")}
            component={Link}
            to={`${EMPLOYEE_TABS[0]}?id=${id}`}
            value={EMPLOYEE_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Tab
            label={t("employee.documents")}
            component={Link}
            to={`${EMPLOYEE_TABS[1]}?id=${id}`}
            value={EMPLOYEE_TABS[1]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Tab
            label={t("drawer.qualification")}
            component={Link}
            to={`${EMPLOYEE_TABS[2]}?id=${id}`}
            value={EMPLOYEE_TABS[2]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_GET",
        ]) && (
          <Tab
            label={t("relEmployeeEmployee.superior")}
            component={Link}
            to={`${EMPLOYEE_TABS[3]}?id=${id}`}
            value={EMPLOYEE_TABS[3]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_GET",
        ]) && (
          <Tab
            label={t("relEmployeeEmployee.subordinate")}
            component={Link}
            to={`${EMPLOYEE_TABS[4]}?id=${id}`}
            value={EMPLOYEE_TABS[4]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "SALARY_GET",
        ]) && (
          <Tab
            label={t("salary.title")}
            component={Link}
            to={`${EMPLOYEE_TABS[5]}?id=${id}`}
            value={EMPLOYEE_TABS[5]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DEPARTRMENT_GET",
        ]) && (
          <Tab
            label={t("department.title")}
            component={Link}
            to={`${EMPLOYEE_TABS[6]}?id=${id}`}
            value={EMPLOYEE_TABS[6]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ABSENCE_GET",
        ]) && (
          <Tab
            label={t("absence.title")}
            component={Link}
            to={`${EMPLOYEE_TABS[7]}?id=${id}`}
            value={EMPLOYEE_TABS[7]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "EMPLOYEE_ADMIN",
        ]) && (
          <Tab
            label="Eszközök"
            component={Link}
            to={`${EMPLOYEE_TABS[8]}?id=${id}`}
            value={EMPLOYEE_TABS[8]}
          />
        )}
      </Tabs>
      <Box pt={2}>
        <Switch location={location}>
          <PrivateRoute
            path={EMPLOYEE_TABS[0]}
            component={() => (
              <EmployeeData
                employee={employeeQuery.data?.employee || null}
                loading={employeeQuery.isFetching}
              />
            )}
            permissions={["EMPLOYEE_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[1]}
            component={EmployeePersonalData}
            permissions={["EMPLOYEE_ADMIN"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[2]}
            component={QualificationList}
            permissions={["EMPLOYEE_ADMIN"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[3]}
            component={SuperiorsOfSubordinates}
            permissions={["EMPLOYEE_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[4]}
            component={SubordinatesOfSuperiors}
            permissions={["EMPLOYEE_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[5]}
            component={SalaryList}
            permissions={["SALARY_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[6]}
            component={RelDepartmentEmployeeList}
            permissions={["DEPARTRMENT_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[7]}
            component={EmployeeAbsenceList}
            permissions={["ABSENCE_GET"]}
          />
          <PrivateRoute
            path={EMPLOYEE_TABS[8]}
            component={EmployeeTool}
            permissions={["EMPLOYEE_ADMIN"]}
          />
        </Switch>
      </Box>
    </Container>
  );
};

export default EmployeeDetailsTabs;
