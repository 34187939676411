import axios from "config/axios";

const ENDPOINT = "/file";

export const getFileByPath = (param: string, tenantId: number) =>
  axios.post(
    ENDPOINT,
    { param, tenantId },
    {
      responseType: "blob",
    }
  );
